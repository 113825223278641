import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetCategoryCodeLabel, GetStatusLabels } from '../../../../functions/Labels';
import { GetCategories } from '../../../../services/categories';
import usePageLoader from '../../../../hooks/usePageLoader';

export default function CategoriesList({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [categories, setCategories] = useState(null);
    const history = useHistory();
    
    const getCategories = async() => {
        showLoader();
        const result = await GetCategories(currentUser.other_details.merchant_uuid);
        console.log(result.data.data);
        setCategories(result.data.data);
        hideLoader();
    }

    const columns = [
        {
            title: 'Category',
            dataIndex: 'category_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/merchants/categories/view/${row.category_uuid}`}>{text}</Link>,
        },
        {
            title: 'Category Type',
            dataIndex: 'category_type_code',
            render: (text, row) => <span>{GetCategoryCodeLabel(text)}</span>,
        },
        {
            title: 'Status',
            width: 110,
            dataIndex: 'category_status',
            render: text => GetStatusLabels('branches', parseInt(text))
        }
    ];

    useEffect(() => {
        getCategories();
        return function cleanup() {
            getCategories();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='categories'>
            { loader }
            <div className="d-flex w-100">
                <h3 className="mb-4">Categories List</h3>
                <div className="ms-auto buttons">
                    <Button type="primary" className="bm-btn" onClick={() => history.push('/app/merchants/categories/add')}>Add Category</Button>
                </div>
            </div>
            {
                categories &&
                <Table rowKey="category_uuid" dataSource={categories} columns={columns} size={'middle'} className="bm-table" pagination={false} />
            }
        </PageLayout>
    )
}
