import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetPortalPersonnels } from '../../../../services/portalpersonnels';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function AdminList({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [adminUsers, setAdminUsers] = useState(null);
    const history = useHistory();
    
    const getAdminUsers = async() => {
        showLoader();
        const result = await GetPortalPersonnels();
        setAdminUsers(result.data.data);
        hideLoader();
    }

    const columns = [
        {
            title: 'Admin Name',
            dataIndex: 'first_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={{ pathname: `/app/admin/administrators/view/${row.user_uuid}`, state: {adminDetails: row} }}>{row.first_name} {row.last_name}</Link>,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_number',
        },
        {
            title: 'Email',
            dataIndex: 'email_address',
            render: text => <a className="text-link-primary-x3" href={`mailto:${text}`}>{text}</a>,
        },
        {
            title: 'Service Area',
            dataIndex: 'service_area_name',
        },
        {
            title: 'Status',
            width: 110,
            dataIndex: 'user_status',
            render: text => GetStatusLabels('users', parseInt(text)),
        }
    ];

    useEffect(() => {
        getAdminUsers();
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='administrators'>
            { loader }
            <div className="d-flex w-100">
                <h3 className="mb-4">Admin Users List</h3>
                <div className="ms-auto buttons">
                    <Button type="primary" className="bm-btn" onClick={() => history.push('/app/admin/administrators/add')}>Add Admin Users</Button>
                </div>
            </div>
            {
                adminUsers &&
                <Table rowKey="user_uuid" dataSource={adminUsers} columns={columns} size={'middle'} className="bm-table" />
            }
        </PageLayout>
    )
}
