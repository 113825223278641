import React, { useState } from 'react';
import { Layout } from 'antd';
import PageHeader from './PageHeader';
import PageSider from './PageSider';
import PageFooter from './PageFooter';
import { NotificationContainer } from 'react-notifications';

export default function PageLayout({children, activeLink}) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [sideCollapsed, setSideCollapsed] = useState(false);
    const { Content } = Layout;
    return (
        <Layout className="bm-layout-wrapper">
            <PageSider currentUser={currentUser} activeLink={activeLink} sideCollapsed={sideCollapsed} />
            <Layout>
                <PageHeader currentUser={currentUser} sideCollapsed={sideCollapsed} setSideCollapsed={setSideCollapsed} />
                <Content>{children}</Content>
                <PageFooter />
                <NotificationContainer />
            </Layout>
        </Layout>
    )
}
