import React, { useState } from 'react';
import PageLoader from '../components/loader';
import { CSSTransition } from 'react-transition-group';

const usePageLoader = () => {
    const [loading, setLoading] = useState(false);
    return [
        <CSSTransition in={loading} timeout={200} unmountOnExit classNames="bm-loading"><PageLoader /></CSSTransition>,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
};

export default usePageLoader;