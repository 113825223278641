import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Table, Row, Col, Card, Alert, Button, Radio, Space, Modal, Form, Select } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import { UpdateOrder }  from '../../../../services/orders';
import { GetOrderItems } from '../../../../services/orderitems';
import { GetItemDetails } from '../../../../services/items';
import { GetVariationDetails } from '../../../../services/itemsvariations';
import { GetAddOnDetails } from '../../../../services/addons';
import { GetUserDetails } from '../../../../services/users';
import { GetOrderTrackings } from '../../../../services/trackings';
import { GetBranchDetails } from '../../../../services/branches';
import { GetAddressDetails } from '../../../../services/addresses';
import { GetVoucherDetails } from '../../../../services/vouchers';
import { GetDeliveryPartners } from '../../../../services/deliverypartners';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';
import { ConvertToDecimal } from '../../../../functions/Math';
import { DisplayDateTime } from '../../../../functions/Utilities';
import { Debounce } from '../../../../functions/Utilities';
import { NotificationManager } from 'react-notifications';

export default function OrdersLatestView() {
    const history = useHistory();
    const { Option } = Select;
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const orderDetails = history.location.state.orderDetails;
    const [orderItems, setOrderItems] = useState(null);
    const [orderTracking, setOrderTracking] = useState(null);
    const [deliveryPartner, setDeliveryPartner] = useState(null);
    const [branchDetails, setBranchDetails] = useState(null);
    const [customerDetails, setCustomerDetails] = useState(null);
    const [addressDetails, setAddressDetails] = useState(null);
    const [voucherDetails, setVoucherDetails] = useState(null);
    const [cancelReason, setCancelReason] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activeRiders, setActiveRiders] = useState(null);
    const [reassignTo, setReassignTo] = useState(null);
    
    const getOrderDetails = async() => {
        showLoader();
        Promise.all([
            GetUserDetails(orderDetails.delivery_partner_uuid),
            GetOrderTrackings(orderDetails.order_uuid),
            GetBranchDetails(orderDetails.branch_uuid),
            GetUserDetails(orderDetails.customer_uuid),
            GetAddressDetails(orderDetails.address_uuid),
            GetOrderItems(orderDetails.order_uuid)
        ]).then(result => {
            setDeliveryPartner(result[0].data.data);
            let trackingResultKey = result[1].data.data.length - 1;
            setOrderTracking(result[1].data.data[trackingResultKey].tracking_code);
            setBranchDetails(result[2].data.data);
            getActiveRiders(result[2].data.data.service_area_uuid);
            if (result[3].data.status === 'success') {
                setCustomerDetails(result[3].data.data);
            } else {
                setCustomerDetails(null);
            }
            setAddressDetails(result[4].data.data);
            let counter = 0;
            let orderItemsResult = result[5];
            orderItemsResult.data.data.forEach((item, key) => {
                Promise.all([
                    GetItemDetails(item.item_uuid),
                    GetVariationDetails(item.variation_uuid)
                ]).then(result => {
                    orderItemsResult.data.data[key].index = key + 1;
                    orderItemsResult.data.data[key].itemDetails = result[0].data.data;
                    orderItemsResult.data.data[key].variationDetails = result[1].data.data;
                    orderItemsResult.data.data[key].addOnsRequired = [];
                    orderItemsResult.data.data[key].addOnsOptional = [];

                    if (item.add_on_required !== '[]') {
                        let itemAddOnRequired = JSON.parse(item.add_on_required);
                        itemAddOnRequired.forEach(function(itemRequiredItem, itemRequiredIndex) {
                            Promise.all([
                                GetAddOnDetails(itemRequiredItem.add_on_uuid)
                            ]).then(result => {
                                orderItemsResult.data.data[key].addOnsRequired.push(result[0].data.data);
                            });
                        });
                    }

                    if (item.add_on_optional !== '[]') {
                        let itemAddOnOptional = JSON.parse(item.add_on_optional);
                        itemAddOnOptional.forEach(function(itemOptionalItem, itemOptionalIndex) {
                            Promise.all([
                                GetAddOnDetails(itemOptionalItem.add_on_uuid)
                            ]).then(result => {
                                orderItemsResult.data.data[key].addOnsOptional.push(result[0].data.data);
                            });
                        });
                    }
                    counter++;

                    if (counter === orderItemsResult.data.data.length) {
                        setTimeout(() => {
                            setOrderItems(orderItemsResult.data.data);
                            setDisplay(true);
                            hideLoader();
                        }, 1000);
                    }
                });
            });
        }).catch(e => {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        });

        if (orderDetails.voucher_uuid !== null) {
            const voucherResult = await GetVoucherDetails(orderDetails.voucher_uuid);
            setVoucherDetails(voucherResult.data.data);
        }
    }

    const getActiveRiders = async(service_area_uuid) => {
        const activeRidersResult = await GetDeliveryPartners(service_area_uuid);
        setActiveRiders(activeRidersResult.data.data);
    }

    const renderCellItemName = (text, row, key) => {
        return row.itemDetails.item_name;
    }

    const renderCellVariationName = (text, row, key) => {
        return row.variationDetails.variation_name;
    }

    const renderRequiredAddOns = (text, row, key) => {
        if (row.addOnsRequired.length === 0) {
            return 'none';
        } else {
            let labelsArray = [];
            row.addOnsRequired.forEach((item, key) => {
                labelsArray.push(item.add_on_name);
            });
            return labelsArray.join(', ');
        }
    }

    const renderOptionalAddOns = (text, row, key) => {
        if (row.addOnsOptional.length === 0) {
            return 'none';
        } else {
            let labelsArray = [];
            row.addOnsOptional.forEach((item, key) => {
                labelsArray.push(item.add_on_name);
            });
            return labelsArray.join(', ');
        }
    }

    const goCancelOrder = () => {
        if (cancelReason === null) {
            Debounce(NotificationManager.warning('You need to select a reason for cancelling an order.', 'Sorry', 2000), 2000);
        } else {
            setIsModalVisible(true);
        }
    }

    const goReassignOrder = () => {
        if (reassignTo === null || reassignTo === '') {
            Debounce(NotificationManager.warning('You need to select an availble Delivery Partner to reassign this order.', 'Sorry', 2000), 2000);
            return false;
        } else {
            handleReassignOrder();
        }
    }

    const onReasonChange = (value) => {
        setCancelReason(value.target.value);
    }

    const handleCancelOrder = async() => {
        showLoader(); 
        const values = {
            'order_status': 3,
            'cancel_reason': cancelReason
        }
        const cancelResult = await UpdateOrder(values, orderDetails.order_uuid);
        if (cancelResult.data.status === 'success') {
            hideLoader();
            setIsModalVisible(false);
            Debounce(NotificationManager.success('Order is now cancelled.', 'Success', 2000), 2000);
            setTimeout(() => {
                history.push('/app/admin/orders/latest');
            }, 3000)
        } else {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            hideLoader();
        }
    }
    
    const handleReassignOrder = async() => {
        showLoader();
        if (orderDetails.delivery_partner_uuid === reassignTo) {
            Debounce(NotificationManager.warning('Please select a different delivery partner.', 'Sorry', 2000), 2000);
            hideLoader();
            return false;
        }
        const values = {
            delivery_partner_uuid: reassignTo
        }
        const reassignResult = await UpdateOrder(values, orderDetails.order_uuid);
        if (reassignResult.data.status === 'success') {
            hideLoader();
            Debounce(NotificationManager.success('Order is now reassigned.', 'Success', 2000), 2000);
            setTimeout(() => {
                history.push('/app/admin/orders/latest');
            }, 3000)
        } else {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            hideLoader();
        }
    }

    const handleClose = () => {
        setIsModalVisible(false);
    }

    const columns = [
        {
            title: 'Count',
            width: 50,
            dataIndex: 'index',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity'
        },
        {
            title: 'Item Name',
            render: renderCellItemName
        },
        {
            title: 'Variation',
            render: renderCellVariationName
        },
        {
            title: 'Required Add-Ons',
            render: renderRequiredAddOns
        },
        {
            title: 'Optional Add-Ons',
            render: renderOptionalAddOns
        }
    ];

    const formLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    useEffect(() => {
        getOrderDetails();
        return function cleanup() {
            getOrderDetails();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='orders'>
            { loader }
            {
                display && 
                <div>
                    <Row gutter={24}>
                        <Col xs={24} md={16}>
                            <h3 className="mb-4">Order Items</h3>
                            {
                                orderItems &&
                                <Table rowKey="index" dataSource={orderItems} columns={columns} size={'middle'} className="bm-table mb-4" pagination={false} />
                            }
                            <Row gutter={24}>
                                <Col xs={24} md={12}>
                                    <Card title="Pricing Details" className="bm-card mb-4">
                                        <div className="bm-static-container">
                                            <div className="title">Merchant Due</div>
                                            <div className="display">P{ConvertToDecimal(orderDetails.order_merchant_due_amount)}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Delivery Fee</div>
                                            <div className="display">P{ConvertToDecimal(orderDetails.order_delivery_due_amount)}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Platform</div>
                                            <div className="display">P{ConvertToDecimal(orderDetails.order_admin_due_amount)}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Order Amount</div>
                                            <div className="display"><strong>P{ConvertToDecimal(orderDetails.order_total_amount)}</strong></div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Card title="Delivery Details" className="bm-card mb-4">
                                        <div className="bm-static-container">
                                            <div className="title">Distance</div>
                                            <div className="display">{ConvertToDecimal(orderDetails.charge_distance_meters / 10) + 'km'}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Base Fee</div>
                                            <div className="display">P{ConvertToDecimal(orderDetails.charge_delivery_base_amount)}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Excess Fee</div>
                                            <div className="display">P{ConvertToDecimal(orderDetails.charge_delivery_excess_amount)}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Delivery Fee</div>
                                            <div className="display"><strong>P{ConvertToDecimal(orderDetails.order_delivery_due_amount)}</strong></div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={24} md={12}>
                                    {
                                        voucherDetails === null &&
                                        <Alert
                                            className="bm-alert mt-1 mb-4"
                                            message={'No Vouchers Used'}
                                            description={'Customer did not use any active vouchers on this order.'}
                                            type={'warning'}
                                        />
                                    }
                                    {
                                        (voucherDetails !== null) &&
                                        <Card title="Voucher Details" className="bm-card mb-4">
                                            <div className="bm-static-container">
                                                <div className="title">Voucher Code</div>
                                                <div className="display"><Link className="text-link-primary-x3" to={`/app/admin/vouchers/view/${voucherDetails.voucher_uuid}`}>{voucherDetails.voucher_code}</Link></div>
                                            </div>
                                            <div className="bm-static-container">
                                                <div className="title">Voucher Name</div>
                                                <div className="display">{voucherDetails.voucher_name}</div>
                                            </div>
                                            <div className="bm-static-container">
                                                <div className="title">Discount Type</div>
                                                <div className="display">{voucherDetails.discount_type}</div>
                                            </div>
                                        </Card>
                                    }
                                </Col>
                                <Col xs={24} md={12}>
                                    {
                                        (orderDetails.charge_is_free_delivery === 1) &&
                                        <Card title="Free Delivery" className="bm-card mb-4">
                                            <div className="bm-static-container">
                                                <div className="title">Base Fee</div>
                                                <div className="display">P{ConvertToDecimal(orderDetails.charge_delivery_base_amount)}</div>
                                            </div>
                                        </Card>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} md={8}>
                            <Card title="Order Details" className="bm-card mb-4">
                                {
                                    orderTracking && orderDetails.order_status !== 3 &&
                                    <Alert
                                        className="bm-alert mt-1 mb-4"
                                        message={'Status'}
                                        description={GetStatusLabels('tracking-admin', orderTracking)}
                                        type={(orderTracking === 'G004') ? 'success' : 'warning'}
                                    />
                                }
                                <div className="bm-static-container">
                                    <div className="title">Order Tracking No</div>
                                    <div className="display"><h3>{orderDetails.order_tracking_no}</h3></div>
                                </div>
                                <div className="bm-static-container">
                                    <div className="title">Delivery Partner</div>
                                    <div className="display"><h3><Link className="text-link-primary-x3" to={`/app/admin/delivery-partners/view/${orderDetails.delivery_partner_uuid}`}>{deliveryPartner.first_name} {deliveryPartner.last_name}</Link></h3></div>
                                </div>
                                <div className="bm-static-container">
                                    <div className="title">Merchant</div>
                                    <div className="display"><h3>{branchDetails.branch_name}</h3></div>
                                </div>
                                <div className="bm-static-container">
                                    <div className="title">Order Amount</div>
                                    <div className="display">P{ConvertToDecimal(orderDetails.order_total_amount)}</div>
                                </div>
                                <div className="bm-static-container">
                                    <div className="title">Order Date & Time</div>
                                    <div className="display">{DisplayDateTime(orderDetails.created_at)}</div>
                                </div>
                                <div className="bm-static-container">
                                    <div className="title">Status</div>
                                    <div className="display">{GetStatusLabels('orders', parseInt(orderDetails.order_status))}</div>
                                </div>
                                {
                                    orderDetails.order_status === 3 &&
                                    <div className="bm-static-container">
                                        <div className="title">Cancel Reason</div>
                                        <div className="display">{orderDetails.cancel_reason}</div>
                                    </div>
                                }
                            </Card>
                            <Card title="Customer Details" className="bm-card mb-4 mb-md-0">
                                {
                                    customerDetails !== null &&
                                    <div>
                                        <div className="bm-static-container">
                                            <div className="title">Customer Name</div>
                                            <div className="display"><h3><Link className="text-link-primary-x3" to={`/app/admin/customers/view/${orderDetails.customer_uuid}`}>{customerDetails.first_name} {customerDetails.last_name}</Link></h3></div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Address</div>
                                            <div className="display"><Link className="text-link-primary-x3" to={{ pathname: `https://maps.google.com/?q=${addressDetails.map_lat},${addressDetails.map_lng}` }} target="_blank" rel="noopener noreferrer">{addressDetails.street} {addressDetails.city_mun} {addressDetails.prov_region} {addressDetails.zip_code}</Link></div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Mobile Number</div>
                                            <div className="display">{customerDetails.mobile_number}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Email Address</div>
                                            <div className="display">{customerDetails.email_address}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Status</div>
                                            <div className="display">{GetStatusLabels('users', parseInt(customerDetails.user_status))}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    customerDetails === null &&
                                    <Alert
                                        className="bm-alert mt-1 mb-4"
                                        message={'Error'}
                                        description={'No customer details found.'}
                                        type={'warning'}
                                    />
                                }
                            </Card>
                        </Col>
                    </Row>
                    {
                        orderDetails.order_status === 1 &&
                        <div className="py-5">
                            <h3 className="mb-4">Order Options</h3>
                            <Row gutter={24}>
                                <Col xs={24} md={12}>
                                    <Card title="Cancel Order" className="bm-card mb-4"
                                        actions={[
                                            <div className="text-end"><Button type="danger" className="bm-btn bm-btn-block" onClick={() => goCancelOrder()}>Cancel Order</Button></div>
                                        ]}>
                                        <Radio.Group onChange={onReasonChange} value={cancelReason}>
                                            <Space direction="vertical">
                                                <Radio value={'Merchant is closed.'}>Merchant is closed.</Radio>
                                                <Radio value={'Order item unavailable.'}>Order item unavailable.</Radio>
                                                <Radio value={'Merchant too busy.'}>Merchant too busy.</Radio>
                                                <Radio value={'Customer unreachable.'}>Customer unreachable.</Radio>
                                                <Radio value={'Others'}>Others</Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Card>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Card title="Reassign Order" className="bm-card mb-4"
                                        actions={[
                                            <div className="text-end"><Button type="primary" className="bm-btn bm-btn-block" onClick={() => goReassignOrder()}>Reassign Order</Button></div>
                                        ]}>
                                        <Form layout={"vertical"} {...formLayout} className="bm-form">
                                            <Form.Item label="Delivery Partner">
                                                <Select defaultValue={orderDetails.delivery_partner_uuid} className="bm-select" onChange={(value) => setReassignTo(value)}>
                                                    <option value="">select one</option>
                                                    {
                                                        activeRiders &&
                                                        activeRiders.map(item => {
                                                            return <Option value={item.user_uuid}>{item.first_name} {item.last_name}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>                                 
                                        </Form>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    }
                </div>
            }
            <Modal className="bm-modal" title="Cancel Order Confirmation" visible={isModalVisible} onCancel={handleClose} okText='Confirm Cancel' onOk={handleCancelOrder}>
                <Alert
                    className="bm-alert mt-1 mb-4"
                    message="Note"
                    description="The platform cannot reverse this action. Do you really want to CANCEL this order?"
                    type="danger"
                />
                <div className="bm-static-container">
                    <div className="title">Tracking Number</div>
                    <div className="display"><h3>{orderDetails.order_tracking_no}</h3></div>
                </div>
                <div className="bm-static-container">
                    <div className="title">Reason</div>
                    <div className="display">{cancelReason}</div>
                </div>
            </Modal>
        </PageLayout>
    )
}
