import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Form, Select, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMerchantBranches } from '../../../../services/merchants';
import { GetCurrentOrders } from '../../../../services/orders';
import { GetOrderTrackings } from '../../../../services/trackings';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';
import { ConvertToDecimal } from '../../../../functions/Math';
import { DisplayDateTime } from '../../../../functions/Utilities';

export default function OrdersList({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [branches, setBranches] = useState(null);
    const [currentOrders, setCurrentOrders] = useState(null);
    const history = useHistory();
    
    const { Option } = Select;

    const getBranches = async() => {
        showLoader();
        const branchesResult = await GetMerchantBranches(currentUser.other_details.merchant_uuid, null);
        setBranches(branchesResult.data.data);
        hideLoader();
    }
    
    const getCurrentOrders = async(branch_uuid) => {
        if (branch_uuid !== '' || branch_uuid !== null) {
            showLoader();
            const currentOrdersResult = await GetCurrentOrders(branch_uuid);
            if (currentOrdersResult.data.data.length > 0) {
                let count = 0;
                currentOrdersResult.data.data.forEach((item, key) => {
                    Promise.all([
                        GetOrderTrackings(item.order_uuid)
                    ]).then(result => {
                        currentOrdersResult.data.data[key].tracking = result[0].data.data;
                    })
                    count++;
                    if (count === currentOrdersResult.data.data.length) {
                        setTimeout(() => {
                            setCurrentOrders(currentOrdersResult.data.data);
                            hideLoader();
                        }, 1000);
                    }
                });
            } else {
                setCurrentOrders(currentOrdersResult.data.data);
                hideLoader();
            }
        }
    }

    const formLayout = {
        labelCol: { span: 0 },
        wrapperCol: { span: 24 }
    };

    const renderCellTracking = (text, row, key) => {
        let trackingLatestKey = row.tracking.length - 1;
        return <strong>{GetStatusLabels('tracking', row.tracking[trackingLatestKey].tracking_code)}</strong>;
    }

    const columns = [
        {
            title: 'Tracking No',
            dataIndex: 'order_tracking_no',
            render: (text, row) => <Link className="text-link-primary-x3" to={{ pathname: `/app/merchants/orders/view/${row.order_uuid}`, state: {orderDetails: row} }}>{text}</Link>,
        },
        {
            title: 'Order Date & Time',
            render: (text, row) => <span>{DisplayDateTime(row.created_at)}</span>,
        },
        {
            title: 'Tracking Status',
            render: renderCellTracking
        },
        {
            title: 'Amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_merchant_due_amount),
        },
        {
            title: 'Order Status',
            width: 150,
            dataIndex: 'order_status',
            render: text => GetStatusLabels('orders', parseInt(text)),
        }
    ];

    

    useEffect(() => {
        getBranches();
        return function cleanup() {
            getBranches();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='orders'>
            { loader }
            <div className="d-flex w-100">
                <h3 className="mb-4">Active Orders List</h3>
                <div className="ms-auto buttons">
                    <Button type="primary" className="bm-btn" onClick={() => history.push('/app/merchants/orders/past')}>Past Orders</Button>
                </div>
            </div>
            {
                branches &&
                <Row>
                    <Col xs={24} md={{span: 8}}>
                        <Form layout={"vertical"} {...formLayout} className="bm-form">
                            <Form.Item label="Select Branch"
                                name="branch_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a branch.',
                                    },
                                ]}>
                                <Select defaultValue={null} className="bm-select" onChange={(value) => {
                                        getCurrentOrders(value)
                                    }}>
                                    <Option value={null}>select one</Option>
                                    {
                                        branches &&
                                        branches.map(item => {
                                            return <Option value={item.branch_uuid} key={item.branch_uuid}>{item.branch_name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            }
            {
                currentOrders &&
                <div>
                    <Table rowKey="order_uuid" dataSource={currentOrders} columns={columns} size={'middle'} className="bm-table" />
                </div>
            }
        </PageLayout>
    )
}
