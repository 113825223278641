import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Card, Button } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetVoucherDetails } from '../../../../services/vouchers';
import { ConvertToDecimal } from '../../../../functions/Math';
import usePageLoader from '../../../../hooks/usePageLoader';
import moment from 'moment';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function VouchersView() {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [voucherDetails, setVoucherDetails] = useState(null);
    const history = useHistory();
    const { uuid }  = useParams();
    
    const getVoucherDetails = async() => {
        showLoader();
        const voucherResult = await GetVoucherDetails(uuid);
        setVoucherDetails(voucherResult.data.data);
        setDisplay(true);
        hideLoader();
    }

    useEffect(() => {
        getVoucherDetails();
        return function cleanup() {
            getVoucherDetails();
        }
        // eslint-disable-next-line
    }, []);

    const ShowValue = (value, type) => {
        let discountType = type.split('-');
        switch (discountType[1]) {
            case 'percentage':
                return value / 100 + '%';
            case 'fixed':
                return 'P' + ConvertToDecimal(value);
            default:
                return false;
        }
    }

    return (
        <PageLayout activeLink='vouchers'>
            { loader }
            {
                display && 
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <Card title="Stats" className="bm-card mb-4">
                            Stats soon
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card title="Voucher Details"
                                className="bm-card mb-4 mb-md-0"
                                actions={[
                                    <div className="text-end"><Button type="outline-primary" className="bm-btn" onClick={() => history.push({pathname: '/app/admin/vouchers/update/' + uuid, state: {voucherDetails}})}>Update</Button></div>
                                ]}>
                            <div className="bm-static-container">
                                <div className="title">Service Area</div>
                                <div className="display">{voucherDetails.service_area_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Voucher Code</div>
                                <div className="display">{voucherDetails.voucher_code}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Voucher Name</div>
                                <div className="display">{voucherDetails.voucher_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Description</div>
                                <div className="display">{voucherDetails.description}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">No of Uses (max)</div>
                                <div className="display">{ConvertToDecimal(voucherDetails.uses_max)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">No of Uses (customer)</div>
                                <div className="display">{ConvertToDecimal(voucherDetails.uses_max_user)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Merchant</div>
                                <div className="display">{(voucherDetails.merchant_name === null ? 'Not Defined' : voucherDetails.merchant_name + ' (' + voucherDetails.company_name + ')')}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Branch</div>
                                <div className="display">{(voucherDetails.branch_name === null ? 'Not Defined' : voucherDetails.branch_name)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Item</div>
                                <div className="display">{(voucherDetails.item_name === null ? 'Not Defined' : voucherDetails.item_name)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Order Amount (min)</div>
                                <div className="display">{'P' + ConvertToDecimal(voucherDetails.min_order_amount)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Discount Type</div>
                                <div className="display">{voucherDetails.discount_type}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Discount Type Value</div>
                                <div className="display">{ShowValue(voucherDetails.discount_type_value, voucherDetails.discount_type)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Merchant's Share</div>
                                <div className="display">{ShowValue(voucherDetails.share_merchant_value, voucherDetails.discount_type)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Platform's Share</div>
                                <div className="display">{ShowValue(voucherDetails.share_platform_value, voucherDetails.discount_type)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Date Start</div>
                                <div className="display">{moment(voucherDetails.voucher_start).format('MMM DD, YYYY')}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Date End</div>
                                <div className="display">{moment(voucherDetails.voucher_end).format('MMM DD, YYYY')}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Status</div>
                                <div className="display">{GetStatusLabels('vouchers', voucherDetails.voucher_status)}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
