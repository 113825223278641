import React, { useState } from 'react';
import { Row, Col, Card, Form, Input, Button, Alert, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { UpdateCategories } from '../../../../services/categories';
import { CategoryTypes } from '../../../../data/CategoryTypes';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';

export default function CategoriesUpdate({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const { Option } = Select;
    const { TextArea } = Input;
    const history = useHistory();
    const categoryDetails = history.location.state.categoryDetails;
    
    const submitHandler = async (values) => {
        showLoader();
        try {
            // textarea undefined when user skips field
            if (values.category_description === undefined) {
                values.category_description = "";
            }
            const formattedValues = {
                ...values,
                category_uuid: categoryDetails.category_uuid,
                category_status: parseInt(values.category_status)
            }
            const result = await UpdateCategories(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Category has been saved.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                case 'error':
                    Debounce(NotificationManager.error('Category already exists. Please use a category name.', 'Category Exists', 2000), 2000);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    return (
        <PageLayout activeLink='categories'>
            { loader }
            {
                <Row>
                    <Col xs={24} md={{span: 12, offset: 6}}>
                        <Card title="Update Category" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Category Updated"
                                    description="Category has been updated successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.goBack()}>Back</Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}
                                initialValues={{
                                    'category_name': categoryDetails.category_name,
                                    'category_description': categoryDetails.category_description,
                                    'category_type_code': categoryDetails.category_type_code,
                                    'category_status': String(categoryDetails.category_status)
                                }}>
                                <Form.Item label="Category Name"
                                    name="category_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the category name.',
                                        },
                                    ]}>
                                    <Input placeholder="category name" />
                                </Form.Item>
                                <Form.Item label="Description"
                                    name="category_description">
                                    <TextArea rows={4} placeholder="description" />
                                </Form.Item>
                                <Form.Item label="Category Type"
                                    name="category_type_code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a branch.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select">
                                        {
                                            CategoryTypes.map(item => {
                                                return <Option value={item.code}>{item.categoryType}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Status"
                                    name="category_status"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select status.',
                                        },
                                    ]}>
                                    <Select>
                                        <Option value="1">Active</Option>
                                        <Option value="0">Inactive</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
