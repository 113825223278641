import axios from 'axios';

export const GetBranchCategories = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/categories-branch/branch/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetBranchCategoriesActive = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/categories-branch/branch/' + uuid + '/active');
        return result;
    } catch (error) {
        throw error;
    }
}

export const AddBranchCategories = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/categories-branch', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const RemoveBranchCategories = async(values) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/categories-branch', values);
        return result;
    } catch (error) {
        throw error;
    }
}
