import React, { useState } from 'react';
import { Row, Col, Card, Form, Input, InputNumber, Switch, Button, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { AddAddOn } from '../../../../services/addons';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';

export default function AddOnsAdd({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const { TextArea } = Input;
    const history = useHistory();
    const groupDetails = history.location.state.group;
    
    const submitHandler = async (values) => {
        showLoader();
        try {
            // textarea undefined when user skips field
            if (values.add_on_description === undefined) {
                values.add_on_description = "";
            }
            (values.is_bestseller === true) ? values.is_bestseller = 1: values.is_bestseller = 0;
            const formattedValues = {
                ...values,
                add_on_group_uuid: groupDetails.add_on_group_uuid,
                add_on_price: values.add_on_price * 100,
                add_on_status: 1
            }
            const result = await AddAddOn(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Add-on has been saved.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                case 'error':
                    Debounce(NotificationManager.error('Add-on already exists. Please use a different add-on name.', 'Add-on Exists', 2000), 2000);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    return (
        <PageLayout activeLink='add-ons'>
            { loader }
            {
                <Row>
                    <Col xs={24} md={{span: 12, offset: 6}}>
                        <Card title={`Add Add-On to ${groupDetails.add_on_group_name}`} className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="New Add-On Added"
                                    description="Add-on has been added successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.push('/app/merchants/add-ons/list')}>
                                            Back to List
                                        </Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}>
                                <Form.Item label="Add-On Name"
                                    name="add_on_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the add-on name.',
                                        },
                                    ]}>
                                    <Input placeholder="variation name" />
                                </Form.Item>
                                <Form.Item label="Add-On Description"
                                    name="add_on_description">
                                    <TextArea rows={4} placeholder="add-on description" />
                                </Form.Item>
                                <Form.Item label="Price"
                                    name="add_on_price"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the add-on price.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0.00" precision={2} step={.01} />
                                </Form.Item>
                                <Form.Item label="Best Seller"
                                    name="is_bestseller">
                                    <Switch />
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
