import React, { useState } from 'react';
import { Row, Col, Card, Form, Input, Button, Alert, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../components/layout/PageLayout';
import { UpdateUserDetails } from '../../../services/users';
import usePageLoader from '../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../functions/Utilities';

export default function ProfilePassword({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const history = useHistory();
    
    const submitHandler = async (values) => {
        showLoader();
        try {
            const formattedValues = {
                ...values,
                user_uuid: currentUser.user_uuid
            }
            const result = await UpdateUserDetails(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Password has been updated.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    return (
        <PageLayout activeLink=''>
            { loader }
            {
                <Row>
                    <Col xs={24} md={{span: 12, offset: 6}}>
                        <Card title="Update Password" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Password Updated"
                                    description="Password has been updated successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.push('/app/profile/view')}>Back</Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}>
                                <Form.Item label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your preferred password.',
                                        },
                                    ]}>
                                    <Input placeholder="password" type="password" />
                                </Form.Item>
                                <Form.Item label="Confirm Password"
                                    name="confirm_password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please retype your password.',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                    ]}>
                                    <Input placeholder="confirm password" type="password" />
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
