import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Row, Col, Card } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMerchantPartnerDetails } from '../../../../services/merchantpartners';
import { GetUserDetails } from '../../../../services/users';
import { GetMerchantDetails } from '../../../../services/merchants';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function MerchantPartnersView() {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [userDetails, setUserDetails] = useState(null);
    const [merchantDetails, setMerchantDetails] = useState(null);
    const { uuid }  = useParams();
    
    const getMerchantPartnerDetails = async() => {
        showLoader();
        const resultMerchantPartnerDetails = await GetMerchantPartnerDetails(uuid);
        const resultUserDetails = await GetUserDetails(uuid);
        const resultMerchantDetails = await GetMerchantDetails(resultMerchantPartnerDetails.data.data.merchant_uuid);
        setUserDetails(resultUserDetails.data.data);
        setMerchantDetails(resultMerchantDetails.data.data);
        setDisplay(true);
        hideLoader();
    }

    useEffect(() => {
        getMerchantPartnerDetails();
        return function cleanup() {
            getMerchantPartnerDetails();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='merchant-partners'>
            { loader }
            {
                display &&
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Card title="Merchant Partner Details" className="bm-card mb-4 mb-md-0">
                            <div className="bm-static-container">
                                <div className="title">First Name</div>
                                <div className="display">{userDetails.first_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Last Name</div>
                                <div className="display">{userDetails.last_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Email Address</div>
                                <div className="display"><a href={`mailto:${userDetails.email_address}`} className="text-link-primary-x3">{userDetails.email_address}</a></div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Mobile Number</div>
                                <div className="display">{userDetails.mobile_number}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">User Type Code</div>
                                <div className="display">{GetStatusLabels('user-type', userDetails.user_type_code)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">User Status</div>
                                <div className="display">{GetStatusLabels('users', userDetails.user_status)}</div>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} md={12}>
                        <Card title="Merchant Details" className="bm-card mb-4 mb-md-0">
                            <div className="bm-static-container">
                                <div className="title">Merchant Name</div>
                                <div className="display">{merchantDetails.merchant_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Contact Person</div>
                                <div className="display">{merchantDetails.contact_person}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Mobile Number</div>
                                <div className="display">{merchantDetails.mobile_number}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Email</div>
                                <div className="display"><a href={`mailto:${merchantDetails.email_address}`} className="text-link-primary-x3">{merchantDetails.email_address}</a></div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Compliance Code</div>
                                <div className="display">{merchantDetails.compliance_code}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Service Area</div>
                                <div className="display">{merchantDetails.service_area_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Status</div>
                                <div className="display">{GetStatusLabels('merchants', merchantDetails.merchant_status)}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
