import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Transfer } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetCategories } from '../../../../services/categories';
import { GetBranchCategoriesActive, AddBranchCategories, RemoveBranchCategories } from '../../../../services/branchcategories';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';

export default function BranchCategoriesView({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [merchantCategories, setMerchantCategories] = useState(null);
    const [branchCategories, setBranchCategories] = useState(null);
    const { uuid }  = useParams();

    const handleOnChange = (nextTargetKeys, direction, moveKeys) => {
        setBranchCategories(nextTargetKeys);
        submitHandler({direction, moveKeys});
    };

    const submitHandler = async ({direction, moveKeys}) => {
        showLoader();
        try {
            switch (direction) {
                case 'right':
                    Promise.all(moveKeys.map(async (item) => {
                        const formattedValues = {
                            category_uuid: item,
                            branch_uuid: uuid,
                            branch_category_status: 1
                        }
                        const result = await AddBranchCategories(formattedValues);
                        switch(result.data.status) {
                            case 'success':
                                Debounce(NotificationManager.success('Branch category has been added.', 'Success', 2000), 2000);
                                break;
                            default:
                                Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                                break;
                        }
                    }));
                    break;
                case 'left':                    
                    Promise.all(moveKeys.map(async (item) => {
                        const formattedValues = {
                            category_uuid: item,
                            branch_uuid: uuid,
                            branch_category_status: 0
                        }
                        const result = await RemoveBranchCategories(formattedValues);
                        switch(result.data.status) {
                            case 'success':
                                Debounce(NotificationManager.success('Branch category has been removed.', 'Success', 2000), 2000);
                                break;
                            default:
                                Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                                break;
                        }
                    }));
                    break;
                default:
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    useEffect(() => {
        const getCategories = async() => {
            showLoader();
            const merchantCategoriesResult = await GetCategories(currentUser.other_details.merchant_uuid);
            const branchCategoriesResult = await GetBranchCategoriesActive(uuid);
            const merchantCategoriesFormatted = [];
            merchantCategoriesResult.data.data.forEach(function(item, index) {
                merchantCategoriesFormatted.push({
                    'key': item.category_uuid,
                    'category_name': item.category_name
                });
            });
            const branchCategoriesFormatted = [];
            branchCategoriesResult.data.data.forEach(function(item, index) {
                branchCategoriesFormatted.push(item.category_uuid);
            });
            setMerchantCategories(merchantCategoriesFormatted);
            setBranchCategories(branchCategoriesFormatted);
            hideLoader();
        }
        getCategories();
        return function cleanup() {
            getCategories();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='branch-categories'>
            { loader }
            <h3 className="mb-4">Branch Categories</h3>
            {
                branchCategories &&
                <Transfer
                    className="bm-transfer"
                    oneWay
                    dataSource={merchantCategories}
                    titles={['Merchant Categories', 'Branch Categories']}
                    targetKeys={branchCategories}
                    onChange={handleOnChange}
                    render={item => item.category_name}
                />
            }
        </PageLayout>
    )
}
