import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Card, Form, Input, Button, Alert, Select, Upload, Image } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { BranchUpdate } from '../../../../services/branches';
import usePageLoader from '../../../../hooks/usePageLoader';
import GoogleMapReact from 'google-map-react';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';
import { MobileNumberFormat } from '../../../../functions/Labels';
import { ConfigKeys } from '../../../../data/ConfigKeys';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';
import { UploadFiles } from '../../../../services/files';
import { FoodCategoryTypes } from '../../../../data/FoodCategoryTypes';
import MarkerImage from '../../../../assets/img/marker-secondary.png';

export default function BranchesUpdate({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const history = useHistory();
    const { Option } = Select;
    const branchDetails = history.location.state.branchDetails;
    const branchListingDetails = history.location.state.branchListingDetails;
    const serviceAreas = history.location.state.serviceAreas;
    const [center, setCenter] = useState({ lat: branchDetails.map_lat, lng: branchDetails.map_lng });
    const [selectedLat, setSelectedLat] = useState(branchDetails.map_lat);
    const [selectedLng, setSelectedLng] = useState(branchDetails.map_lng);
    const [zoom, setZoom] = useState(16);
    const [marker, setMarker] = useState(MarkerImage);
    const [fileListListing, setFileListListing] = useState([]);
    const [newListingPhoto, setNewListingPhoto] = useState(null);
    const [newListingNotice, setNewListingNotice] = useState(null);
    const [isMaxValue, setIsMaxValue] = useState(false);

    const submitHandler = async (values) => {
        showLoader();
        try {
            const formattedValues = {
                ...values,
                food_category_types: values.food_category_types.join(','),
                merchant_uuid: currentUser.other_details.merchant_uuid,
                branch_uuid: branchDetails.branch_uuid,
                mobile_number: MobileNumberFormat('save', values.mobile_number),
                map_lat: selectedLat,
                map_lng: selectedLng,
                branch_status: parseInt(values.branch_status)
            }
            if (newListingPhoto !== null) {
                formattedValues.listing_photo_file_uuid = newListingPhoto
            }
            const result = await BranchUpdate(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Branch details has been updated.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                case 'duplicate':
                    Debounce(NotificationManager.error('Branch name already exists. Please use a different one.', 'Branch Exists', 2000), 2000);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    useEffect(() => {
        // eslint-disable-next-line
    }, []);

    const mapOptions = (maps) => {
        return {
            mapTypeControl: true
        }
    }

    const onDragEndMap = (map) => {
        setSelectedLat(map.getCenter().lat());
        setSelectedLng(map.getCenter().lng());
    }

    const uploadFile = async(file) => {
        try {
            const formattedValues = {
                file_url: file.secure_url,
                file_type: file.resource_type,
                file_name: file.asset_id,
                file_description: '',
                file_status: 1
            }
            const result = await UploadFiles(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Listing photo is now uploaded.', 'Success', 2000), 2000);
                    const bannerItem = {
                        uid: file.asset_id,
                        name: file.asset_id,
                        url: file.secure_url
                    }
                    setFileListListing([bannerItem]);
                    setNewListingPhoto(result.data.file_uuid);
                    setNewListingNotice(true);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleOnChangeListing = async({file, fileList: newFileList }) => {
        setFileListListing(newFileList);
        showLoader();
        if (file.status !== 'uploading') {
            await getBase64(file.originFileObj, result => {
                let formData = new FormData();
                formData.append('file', result);
                formData.append('upload_preset', 'nspmba8t');
                axios.post('https://api.cloudinary.com/v1_1/falcomtech/image/upload', formData)
                    .then(response => {
                        if (response.status === 200) {
                            uploadFile(response.data);
                            hideLoader();
                        }
                    })
            });
        }
    }

    const handleFoodCategory = (values) => {
        if (values.length >= 3) {
            Debounce(NotificationManager.info('You are only allowed to select a maximum of three (3) food categories.', 'Limit', 2000), 2000);
            setIsMaxValue(true);
        } else {
            setIsMaxValue(false);
        }
    }

    useEffect(() => {
        if (branchListingDetails !== null) {
            const listingItem = {
                uid: branchListingDetails.file_uuid,
                name: branchListingDetails.file_name,
                url: branchListingDetails.file_url
            }
            setFileListListing([listingItem]);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='branches'>
            { loader }
            <Row>
                <Col xs={24} md={{span: 12, offset: 6}}>
                    <Card title="Add Branch" className="bm-card">
                        {
                            success &&
                            <Alert
                                className="bm-alert mt-1 mb-4"
                                message="Branch Updated"
                                description="Branch has been updated successfully."
                                type="success"
                                action={
                                    <Button type="white" className="bm-btn" onClick={() => history.push('/app/merchants/branches/list')}>
                                        Back
                                    </Button>
                                }
                            />
                        }
                        <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}
                            initialValues={{
                                'service_area_uuid': branchDetails.service_area_uuid,
                                'food_category_types': branchDetails.food_category_types.split(','),
                                'branch_name': branchDetails.branch_name,
                                'branch_code': branchDetails.branch_code,
                                'address_street': branchDetails.address_street,
                                'address_city_mun': branchDetails.address_city_mun,
                                'address_prov_region': branchDetails.address_prov_region,
                                'address_zip_code': branchDetails.address_zip_code,
                                'contact_person': branchDetails.contact_person,
                                'email_address': branchDetails.email_address,
                                'tel_number': branchDetails.tel_number,
                                'mobile_number': MobileNumberFormat('view', branchDetails.mobile_number),
                                'branch_status': String(branchDetails.branch_status)
                            }}>
                            <Form.Item label="Service Area"
                                name="service_area_uuid"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please please select service area.',
                                    },
                                ]}>
                                <Select defaultValue="" className="bm-select">
                                        <option value="">select one</option>
                                        {
                                            serviceAreas.map(item => {
                                                return <Option value={item.service_area_uuid}>{item.service_area_name}</Option>
                                            })
                                        }
                                    </Select>
                            </Form.Item>
                            <Form.Item label="Food Category Types"
                                name="food_category_types"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please please select at least one food category type.',
                                    },
                                ]}>
                                <Select className="bm-select" mode="multiple" allowClear placeholder="select at least one" onChange={(values) => handleFoodCategory(values)} {...(isMaxValue && { open: false })}>
                                    {
                                        FoodCategoryTypes.map(item => {
                                            return <Option value={item.code}>{item.categoryName}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Branch Name"
                                name="branch_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter the branch name.',
                                    },
                                ]}>
                                <Input placeholder="branch name" />
                            </Form.Item>
                            <Form.Item label="Branch Code"
                                name="branch_code"
                                >
                                <Input placeholder="branch code" />
                            </Form.Item>
                            <Form.Item label="Street Address"
                                name="address_street"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter street address.',
                                    },
                                ]}>
                                <Input placeholder="street address" />
                            </Form.Item>
                            <Form.Item label="City / Municipality"
                                name="address_city_mun"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter city or municipality.',
                                    },
                                ]}>
                                <Input placeholder="city or municipality" />
                            </Form.Item>
                            <Form.Item label="Province / Region"
                                name="address_prov_region"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter province or region.',
                                    },
                                ]}>
                                <Input placeholder="province or region" />
                            </Form.Item>
                            <Form.Item label="ZIP Code"
                                name="address_zip_code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter zip code.',
                                    },
                                ]}>
                                <Input placeholder="zip code" />
                            </Form.Item>
                            <Form.Item label="Contact Person"
                                name="contact_person"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter contact person.',
                                    },
                                ]}>
                                <Input placeholder="contact person" />
                            </Form.Item>
                            <Form.Item label="Email"
                                name="email_address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please email address.',
                                    },
                                ]}>
                                <Input placeholder="email" />
                            </Form.Item>
                            <Form.Item label="Telephone Number"
                                name="tel_number"
                                rules={[
                                    {
                                        message: 'Please enter a valid telephone number.',
                                    },
                                ]}
                                >
                                <Input placeholder="" />
                            </Form.Item>
                            <Form.Item label="Mobile Number"
                                name="mobile_number"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a valid mobile number.',
                                    },
                                ]}
                                >
                                <Input placeholder="09" />
                            </Form.Item>
                            <Form.Item label="Branch Location">
                                <div className="map-container-form mb-3">
                                    {
                                        center &&
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: ConfigKeys.Google.key }}
                                            defaultCenter={center}
                                            defaultZoom={zoom}
                                            options={mapOptions}
                                            onDragEnd ={onDragEndMap}>
                                            <Image width={50} height={50} src={marker} preview={false} className="map-container-marker" />
                                        </GoogleMapReact>
                                    }
                                </div>
                                {
                                    selectedLat && selectedLng &&
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}><Input placeholder="location latitude" value={selectedLat} disabled /></Col>
                                        <Col span={12}><Input placeholder="location longitude" value={selectedLng} disabled /></Col>
                                    </Row>
                                }
                            </Form.Item>
                            <Form.Item label="Listing Photo"
                                name="listing_photo">
                                <ImgCrop modalTitle="Crop Image" aspect={1.77} quality={.8}>
                                    <Upload name="image"
                                        listType="picture-card"
                                        accept="image/png, image/jpeg"
                                        fileList={fileListListing}
                                        onChange={handleOnChangeListing}
                                        beforeUpload={false}
                                        onRemove={false}>
                                        {fileListListing.length < 2 && 'Update'}
                                    </Upload>
                                </ImgCrop>
                                <Input className="d-none" />
                                {
                                    newListingNotice &&
                                    <Alert
                                        className="bm-alert mt-2"
                                        message="Notice"
                                        description="Branch listing photo has not been saved to the database yet. You need to submit the form in order to save the image."
                                        type="default"
                                    />
                                }
                            </Form.Item>
                            <Form.Item label="Status"
                                name="branch_status"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select status.',
                                    },
                                ]}>
                                <Select>
                                    <Option value="1">Active</Option>
                                    <Option value="0">Inactive</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item {...tailFormLayout}>
                                <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </PageLayout>
    )
}
