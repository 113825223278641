import React from 'react'
import { Route, Redirect } from 'react-router-dom';

export default function PrivateRoute({ component: Component, ...rest }) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return (
        <Route {...rest} render={props => {
            const updatedProps = {
                ...props,
                currentUser
            };
            return currentUser ? <Component {...updatedProps} /> : <Redirect to="/login" />
        }}></Route>
    )
}