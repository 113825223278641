import axios from 'axios';

export const GetAddressDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/addresses/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}
