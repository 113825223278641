import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import Checked from '../../../../components/icons/Checked';
import { GetAddOnGroups } from '../../../../services/addongroups';
import { GetAddOns } from '../../../../services/addons';
import { ConvertToDecimal } from '../../../../functions/Math';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function AddOnsList({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [addOnGroups, setAddOnGroups] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedAddOnGroupUuid, setSelectedAddOnGroupUuid] = useState(null);
    const [addOns, setAddOns] = useState(null);
    const history = useHistory();
    
    const getAddOnGroups = async() => {
        showLoader();
        setAddOnGroups(null);
        const addOnGroupsResult = await GetAddOnGroups(currentUser.other_details.merchant_uuid);
        setAddOnGroups(addOnGroupsResult.data.data);
        hideLoader();
    }

    const getAddOns = async(group) => {
        showLoader();
        const addOnsResult = await GetAddOns(group.add_on_group_uuid);
        setAddOns(addOnsResult.data.data);
        setSelectedGroup(group);
        hideLoader();
    }

    const setRowSelected = (rowData) => {
        return selectedAddOnGroupUuid === rowData.add_on_group_uuid ? 'row-highlighted' : '';
    }

    const addOnGroupColumns = [
        {
            title: 'Add-On Group Name',
            dataIndex: 'add_on_group_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/merchants/add-ons/groups/view/${row.add_on_group_uuid}`} title="view item details">{text}</Link>,
        },
        {
            title: 'Status',
            width: 110,
            dataIndex: 'add_on_group_status',
            render: text => GetStatusLabels('add-on-groups', parseInt(text))
        }
    ];

    const addOnColumns = [
        {
            title: 'Add-On Name',
            dataIndex: 'add_on_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={{pathname: `/app/merchants/add-ons/view/${row.add_on_uuid}`, state: {groupDetails: selectedGroup}}} title="view variation details"><span className="d-flex align-items-center bestseller-icon-wrapper">{text} {(row.is_bestseller === 1) ? <Checked /> : ''}</span></Link>,
        },
        {
            title: 'Price',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.add_on_price),
        },
        {
            title: 'Status',
            width: 110,
            dataIndex: 'add_on_status',
            render: text => GetStatusLabels('add-ons', parseInt(text))
        }
    ]

    useEffect(() => {
        getAddOnGroups();
        return function cleanup() {
            getAddOnGroups();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='add-ons'>
            { loader }
            <Row gutter={24}>
                <Col sm={12}>
                    <div className="d-flex w-100">
                        <h3 className="mb-4">Add-Ons Group List</h3>
                        <div className="ms-auto buttons">
                            <Button type="primary" className="bm-btn" onClick={() => history.push('/app/merchants/add-ons/groups/add')}>Add Add-On Group</Button>
                        </div>
                    </div>
                    {
                        <div>
                            <Table  rowKey="add_on_group_uuid" dataSource={addOnGroups} columns={addOnGroupColumns} size={'middle'} className="bm-table row-clickable items-table"
                                    onRow={(rowData, rowIndex) => {
                                        return {
                                            onClick: event => {
                                                getAddOns(rowData);
                                                setSelectedAddOnGroupUuid(rowData.add_on_group_uuid);
                                                setAddOns(null);
                                                setSelectedGroup(null);
                                            }
                                        }
                                    }}
                                    rowClassName={setRowSelected}
                                    pagination={false}
                            />
                        </div>
                    }
                </Col>
                <Col sm={12}>
                    {
                        selectedGroup &&
                        <div>
                            <div className="d-flex w-100">
                                <h3 className="mb-4">{selectedGroup.add_on_group_name}</h3>
                                <div className="ms-auto buttons">
                                    <Button type="primary" className="bm-btn" onClick={() => history.push({pathname: '/app/merchants/add-ons/add/' + selectedGroup.add_on_group_uuid, state: {group: selectedGroup}})}>Add Add-On</Button>
                                </div>
                            </div>
                            <Table  rowKey="menu_uuid"
                                    dataSource={addOns}
                                    columns={addOnColumns} size={'middle'}
                                    className="bm-table"
                                    pagination={false}
                            />
                        </div>
                    }
                </Col>
            </Row>
        </PageLayout>
    )
}
