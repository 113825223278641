import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Input, Button, Alert, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMerchantBranches } from '../../../../services/merchants';
import { UpdateMerchantPartner } from '../../../../services/users';
import { MobileNumberFormat } from '../../../../functions/Labels';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';

export default function UsersUpdate({currentUser}) {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [merchantBranches, setMerchantBranches] = useState(null);
    const [success, setSuccess] = useState(null);
    const { Option } = Select;
    const history = useHistory();
    const userDetails = history.location.state.userDetails;
    const merchantPartnerDetails = history.location.state.merchantPartnerDetails;
    
    const getBranches = async() => {
        showLoader();
        const merchantBranchesResult = await GetMerchantBranches(currentUser.other_details.merchant_uuid, null);
        setMerchantBranches(merchantBranchesResult.data.data);
        setDisplay(true);
        hideLoader();
    }
    
    const submitHandler = async (values) => {
        showLoader();
        try {
            const formattedValues = {
                ...values,
                user_uuid: userDetails.user_uuid,
                merchant_uuid: currentUser.other_details.merchant_uuid,
                mobile_number: MobileNumberFormat('save', values.mobile_number),
                user_status: parseInt(values.user_status)
            }
            const result = await UpdateMerchantPartner(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('User information has been updated.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    useEffect(() => {
        getBranches();
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='users'>
            { loader }
            {
                display && 
                <Row>
                    <Col xs={24} md={{span: 12, offset: 6}}>
                        <Card title="Update Users" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="User Updated"
                                    description="User has been updated successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.goBack()}>Back</Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}
                                initialValues={{
                                    'branch_uuid': merchantPartnerDetails.branch_uuid,
                                    'access_type_code': merchantPartnerDetails.access_type_code,
                                    'email_address': userDetails.email_address,
                                    'first_name': userDetails.first_name,
                                    'last_name': userDetails.last_name,
                                    'mobile_number': MobileNumberFormat('view', userDetails.mobile_number),
                                    'user_status': String(userDetails.user_status)
                                }}>
                                {
                                    merchantPartnerDetails.branch_uuid &&
                                    <Form.Item label="Branch"
                                        name="branch_uuid"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select a branch.',
                                            },
                                        ]}>
                                        <Select defaultValue="" className="bm-select">
                                            {
                                                merchantBranches &&
                                                merchantBranches.map(item => {
                                                    return <Option value={item.branch_uuid}>{item.branch_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                }
                                <Form.Item label="Access Type"
                                    name="access_type_code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a branch.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select">
                                        <Option value="C001">Super Admin</Option>
                                        <Option value="C002">Finance</Option>
                                        <Option value="C003">Operations</Option>
                                        <Option value="C004">Staff</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Email"
                                    name="email_address"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your email address.',
                                        },
                                    ]}>
                                    <Input placeholder="email address" disabled={true} />
                                </Form.Item>
                                <Form.Item label="First Name"
                                    name="first_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your first name.',
                                        },
                                    ]}>
                                    <Input placeholder="first name" />
                                </Form.Item>
                                <Form.Item label="Last Name"
                                    name="last_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your last name.',
                                        },
                                    ]}>
                                    <Input placeholder="last name" />
                                </Form.Item>
                                <Form.Item label="Mobile Number"
                                    name="mobile_number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a valid mobile number.',
                                        },
                                    ]}
                                    >
                                    <Input placeholder="09" />
                                </Form.Item>
                                <Form.Item label="Status"
                                    name="user_status"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select status.',
                                        },
                                    ]}>
                                    <Select>
                                        <Option value="1">Active</Option>
                                        <Option value="0">Inactive</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
