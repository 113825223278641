import React from 'react';
import PageLayout from '../../../../components/layout/PageLayout';

export default function MerchantDashboard({currentUser}) {
    return (
        <PageLayout activeLink='dashboard'>
            <h4>Dashboard</h4>
        </PageLayout>
    )
}
