import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Table, Row, Col, Button, Modal, List, Alert, Checkbox } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetMenuItemCategories, GetMenuItemCategoriesActive, AddMenuItemCategories, UpdateMenuItemCategories } from '../../../../services/menuitemcategories';
import { GetMerchantItems } from '../../../../services/items';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined, DeleteOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function MenusConfigure({currentUser}) {
    const history = useHistory();
    const [loader, showLoader, hideLoader] = usePageLoader();
    const menuDetails = history.location.state.menuDetails;
    const menuCategories = history.location.state.menuCategories;
    const [categorySelected, setCategorySelected] = useState(null);
    const [categoryItemsAll, setCategoryItemsAll] = useState(null);
    const [categoryItemsActive, setCategoryItemsActive] = useState(null);
    const [categoryItemsActiveList, setCategoryItemsActiveList] = useState(null);
    const [items, setItems] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleCategorySelect = async(menu_category_uuid) => {
        showLoader();
        setCategorySelected(menu_category_uuid);
        const categoryItemsActiveResult = await GetMenuItemCategoriesActive(menu_category_uuid);
        const categoryItemsAllResult = await GetMenuItemCategories(menu_category_uuid);
        setCategoryItemsAll(categoryItemsAllResult.data.data);
        const categoryItemsAllResultList = [];
        categoryItemsActiveResult.data.data.forEach(function(item, index) {
            categoryItemsActiveResult.data.data[index].index = index;
            categoryItemsAllResultList.push(item.item_uuid);
        });
        setCategoryItemsActive(categoryItemsActiveResult.data.data);
        setCategoryItemsActiveList(categoryItemsAllResultList);
        hideLoader();
    }

    const getAllItems = async() => {
        showLoader();
        const itemsResult = await GetMerchantItems(currentUser.other_details.merchant_uuid);
        setItems(itemsResult.data.data);
        hideLoader();
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setSelectedItems([]);
        setIsModalVisible(false);
        getAllItems();
        handleCategorySelect(categorySelected);
    };

    const handleAddItems = async() => {
        if (selectedItems.length < 1) {
            Debounce(NotificationManager.error('Please select at least one item to add or just click Cancel to close the window.', 'Sorry', 2000), 2000);
        } else {
            showLoader();
            Promise.all(selectedItems.map(async(item) => {
                const index = CheckItemIsSelected(categoryItemsAll, item);
                const formattedValues = {
                    menu_category_uuid: categorySelected,
                    item_uuid: item,
                    display_order: 1,
                    menu_item_category_status: 1
                }
                if (typeof index !== 'undefined') {
                    formattedValues['menu_item_category_uuid'] = categoryItemsAll[index].menu_item_category_uuid;
                    return await UpdateMenuItemCategories(formattedValues);
                } else {
                    return await AddMenuItemCategories(formattedValues);
                }
            })).then((values) => {
                if (values.length > 0) {
                    Debounce(NotificationManager.success('Items has been added to menu category', 'Success', 2000), 2000);
                } else {
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                }
                handleCancel();
                hideLoader();
            });
        }
    }

    const CheckItemIsSelected = (object, value) => {
        return Object.keys(object).find(key => object[key].item_uuid === value);
    }

    const handleSelectItemChange = (element) => {
        if (element.target.checked === true) {
            setCategoryItemsActiveList([
                ...categoryItemsActiveList,
                element.target.value
            ]);
            selectedItems.push(element.target.value);
        } else {
            var index = categoryItemsActiveList.indexOf(element.target.value);
            categoryItemsActiveList.splice(index, 1);
            var filtered = selectedItems.filter(function(value, index) {
                return value !== element.target.value;
            });
            setSelectedItems(filtered);
        }
    }

    const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
    const SortableItem = sortableElement(props => <tr {...props} />);
    const SortableContainer = sortableContainer(props => <tbody {...props} />);

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={handleSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({className, style, ...restProps}) => {
        const dataSource = categoryItemsActive;
        const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const handleSortEnd = ({oldIndex, newIndex}) => {
        const dataSource = categoryItemsActive;
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            setCategoryItemsActive(newData);
            updateOrder(newData);
        }
    }

    const updateOrder = async(values) => {
        showLoader();
        Promise.all(values.map(async (item, key) => {
            const formattedValues = {
                menu_item_category_uuid: item.menu_item_category_uuid,
                menu_category_uuid: item.menu_category_uuid,
                item_uuid: item.item_uuid,
                display_order: key + 1
            }
            return await UpdateMenuItemCategories(formattedValues);
        })).then(result => {
            if (values.length === result.length) {
                Debounce(NotificationManager.success('Items has been updated successfully.', 'Success', 2000), 2000);
            } else {
                Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
            }
            hideLoader();
        });
    }

    const handleDelete = async(uuid, index) => {
        const values = {
            menu_item_category_uuid: uuid,
            menu_item_category_status: 0
        }
        showLoader();
        const deleteResult = await UpdateMenuItemCategories(values);
        if (deleteResult.data.status = 'success') {
            Debounce(NotificationManager.success('Items has been removed successfully.', 'Success', 2000), 2000);
            handleCategorySelect(categorySelected);
        }
        hideLoader();
    }

    const columns = [
        {
            title: 'Sort',
            dataIndex: 'sort',
            width: 60,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: 'Item Name',
            dataIndex: 'item_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/merchants/items/view/${row.item_uuid}`}>{text}</Link>,
        },
        {
            title: 'Description',
            dataIndex: 'item_description'
        },
        {
            title: 'Status',
            dataIndex: 'item_status',
            width: 110,
            render: text => GetStatusLabels('items', parseInt(text))
        },
        {
            title: 'Options',
            width: 110,
            render: (text, row) => <Button className="bm-btn bm-btn btn-icon-danger" type={'table-options-icon'} onClick={() => handleDelete(row.menu_item_category_uuid, row.index)}><DeleteOutlined /></Button>,
        }
    ];

    useEffect(() => {
        getAllItems();
        return function cleanup() {
            getAllItems();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='menus'>
            { loader }
            <h3 className="mb-4">Configure {menuDetails.menu_name}</h3>
            <Row gutter={24} className="bm-configure">
                <Col className="fixed">
                    <h3 className="header">Menu Categories</h3>
                    <ul className="categories">
                        {
                            menuCategories &&
                            menuCategories.map(item => {
                                return <li onClick={() => handleCategorySelect(item.key)} className={categorySelected === item.key ? 'selected' : ''}>{item.category_name}</li>
                            })
                        }
                    </ul>
                </Col>
                <Col className="stretch">
                    <div className="d-flex">
                        <h3 className="mb-4">Items</h3>
                        {
                            categoryItemsActive &&
                            <div className="ms-auto buttons">
                                <Button type="primary" className="bm-btn" onClick={() => showModal()}>Add Items</Button>
                            </div>
                        }
                        
                    </div>
                    {
                        categoryItemsActive &&
                        <Table rowKey="index"
                            dataSource={categoryItemsActive}
                            columns={columns}
                            size={'middle'}
                            className="bm-table"
                            components={{
                                body: {
                                    wrapper: DraggableContainer,
                                    row: DraggableBodyRow
                                },
                            }}
                            pagination={false}
                        />
                    }
                </Col>
            </Row>
            <Modal className="bm-modal" title="Items List" visible={isModalVisible} onCancel={handleCancel} okText='Add Items' onOk={handleAddItems}>
                <Alert
                    className="bm-alert mt-1 mb-4"
                    message="Note"
                    description="Select the items you want to add to the menu and click Add Items button."
                    type="default"
                />
                <List
                    dataSource={items}
                    renderItem={item => (
                        <List.Item>
                            {
                                <Checkbox
                                    checked={categoryItemsActiveList.includes(item.item_uuid) ? true : false}
                                    value={item.item_uuid}
                                    onChange={(element) => handleSelectItemChange(element)}
                                    disabled={(CheckItemIsSelected(categoryItemsActive, item.item_uuid) >= 0) ? true : false}>
                                        {item.item_name}
                                    </Checkbox>
                            }
                        </List.Item>
                    )}
                />
            </Modal>
        </PageLayout>
    )
}
