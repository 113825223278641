import React, { useState } from 'react';
import { Row, Col, Card, Form, Input, InputNumber, Switch, Button, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { AddVariation } from '../../../../services/itemsvariations';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';

export default function VariationsAdd({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const { TextArea } = Input;
    const history = useHistory();
    const itemDetails = history.location.state.item;
    
    const submitHandler = async (values) => {
        showLoader();
        try {
            // textarea undefined when user skips field
            if (values.variation_description === undefined) {
                values.variation_description = "";
            }
            (values.is_bestseller === true) ? values.is_bestseller = 1: values.is_bestseller = 0;
            const formattedValues = {
                ...values,
                item_uuid: itemDetails.item_uuid,
                variation_price: values.variation_price * 100,
                item_photo_file_uuid: "",
                variation_status: 1
            }
            const result = await AddVariation(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Variation has been saved.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                case 'error':
                    Debounce(NotificationManager.error('Variation already exists. Please use a different variation name.', 'Variation Exists', 2000), 2000);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    return (
        <PageLayout activeLink='items'>
            { loader }
            {
                <Row>
                    <Col xs={24} md={{span: 12, offset: 6}}>
                        <Card title={`Add Variation to ${itemDetails.item_name}`} className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="New Variation Added"
                                    description="Variation has been added successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.push('/app/merchants/items/list')}>
                                            Back to List
                                        </Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}>
                                <Form.Item label="Variation Name"
                                    name="variation_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the variation name.',
                                        },
                                    ]}>
                                    <Input placeholder="variation name" />
                                </Form.Item>
                                <Form.Item label="Variation Description"
                                    name="variation_description">
                                    <TextArea rows={4} placeholder="variation description" />
                                </Form.Item>
                                <Form.Item label="Price"
                                    name="variation_price"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the variation price.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0.00" precision={2} step={.01} />
                                </Form.Item>
                                <Form.Item label="Best Seller"
                                    name="is_bestseller">
                                    <Switch />
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
