import React, { useEffect, useState } from 'react';
import PageLayout from '../../../../components/layout/PageLayout';
import { Form, Button, DatePicker, Image } from 'antd';
import { GetUserActiveLocations } from '../../../../services/users';
import moment from 'moment-timezone';
import usePageLoader from '../../../../hooks/usePageLoader';
import { ConfigKeys } from '../../../../data/ConfigKeys';
import GoogleMapReact from 'google-map-react';
import MarkerImage from '../../../../assets/img/marker-secondary.png';

export default function ActiveUsersMap({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [dateStart, setDateStart] = useState(moment().subtract(6, 'days').tz('Asia/Manila').format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState(moment().tz('Asia/Manila').format('YYYY-MM-DD'));
    const [usersCount, setUsersCount] = useState(0);
    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY/MM/DD';
    const [activeUsers, setActiveUsers] = useState(null);
    const [center, setCenter] = useState(null);
    const [zoom, setZoom] = useState(8);

    const getPageData = async() => {
        showLoader();
        setActiveUsers(null);
        Promise.all([
            GetUserActiveLocations(dateStart, dateEnd),
        ]).then(result => {
            setActiveUsers(result[0].data.data);
            setUsersCount(result[0].data.data.length);
            hideLoader();
        });
    }
    
    const mapOptions = (maps) => {
        return {
            mapTypeControl: true
        }
    }

    const Marker = () => {
        return (
            <Image src={MarkerImage} width={40} height={40} preview={false} className="users-map-marker" />
        )
    }

    const changeRange = (date) => {
        setDateStart(date[0].format('YYYY-MM-DD'));
        setDateEnd(date[1].format('YYYY-MM-DD'))
    }

    const handleSubmit = () => {
        getPageData();
    }

    useEffect(() => {
        getPageData();
        return function cleanup() {
            getPageData();
        }
    }, []);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(async({coords: {latitude, longitude}}) => {
            setCenter({
                lat: latitude,
                lng: longitude
            });
        });
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='dashboard'>
            { loader }
            <h4 className="mb-4">Trends</h4>
            <div>
                <div className="d-flex w-100">
                    <h3 className="mb-4">Active Users - {usersCount}</h3>
                    <div className="ms-auto buttons">
                        <Form layout={"inline"} className="bm-form" onFinish={handleSubmit}>
                            <Form.Item name="orders_range">
                                <RangePicker
                                    defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                                    value={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                                    onChange={(date) => changeRange(date)}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                    Change
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div className="map-container-active-users">
                    {
                        activeUsers &&
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: ConfigKeys.Google.key }}
                            defaultCenter={center}
                            defaultZoom={zoom}
                            options={mapOptions}>
                            {
                                activeUsers.map(item => {
                                    return <Marker lat={item.last_seen_lat} lng={item.last_seen_lng} key={item.user_uuid} />
                                })
                            }
                        </GoogleMapReact>
                    }
                </div>
            </div>
        </PageLayout>
    )
    
}
