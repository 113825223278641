import React from 'react';

export default function Bag() {
    return (
        <svg id="Layer_1" enableBackground="new 0 0 513 513" height="512" viewBox="0 0 513 513" width="512" xmlns="http://www.w3.org/2000/svg">
            <path d="M458.671,138.062c-3.728-3.899-8.901-6.103-14.298-6.103H67.631c-5.384,0-10.544,2.197-14.278,6.083
                        c-3.728,3.88-5.72,9.118-5.503,14.502c0.092,2.138,8.63,214.842,12.529,302.68C61.376,487.06,87.458,512,119.754,512h271.835
                        c32.297,0,58.379-24.947,59.362-56.538l13.196-302.852C464.385,147.22,462.399,141.961,458.671,138.062z M411.39,453.984
                        c-0.317,10.161-9.198,18.428-19.8,18.428H119.754c-10.603,0-19.484-8.267-19.814-18.685
                        c-3.187-71.772-9.455-226.388-11.705-282.181h335.459L411.39,453.984z"/>
            <path d="M255.672,0c-63.664,0-115.464,51.8-115.464,115.464v147.134c0,10.933,8.861,19.794,19.794,19.794
                        c10.933,0,19.794-8.861,19.794-19.794V115.464c0-41.838,34.039-75.876,75.876-75.876s75.876,34.039,75.876,75.876v147.134
                        c0,10.933,8.861,19.794,19.794,19.794s19.794-8.861,19.794-19.794V115.464C371.136,51.8,319.335,0,255.672,0z"/>
        </svg>
    )
}
