import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Card, Button } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetCategoryDetails } from '../../../../services/categories';
import { GetCategoryCodeLabel, GetStatusLabels } from '../../../../functions/Labels';
import usePageLoader from '../../../../hooks/usePageLoader';

export default function CategoriesView() {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [categoryDetails, setCategoryDetails] = useState(null);
    const { uuid }  = useParams();
    const history = useHistory();
    
    const getCategoryDetails = async() => {
        showLoader();
        const categoryDetailsResult = await GetCategoryDetails(uuid);
        setCategoryDetails(categoryDetailsResult.data.data);
        setDisplay(true);
        hideLoader();
    }

    useEffect(() => {
        getCategoryDetails();
        return function cleanup() {
            getCategoryDetails();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='categories'>
            { loader }
            {
                display && 
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <Card title="Stats" className="bm-card mb-4 mb-md-0">
                            Stats soon
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card title="Category Details" className="bm-card mb-4 mb-md-0"
                            actions={[
                                <div className="text-end"><Button type="outline-primary" className="bm-btn" onClick={() => history.push({pathname: '/app/merchants/categories/update/' + uuid, state: {categoryDetails}})}>Update</Button></div>
                            ]}>
                            <div className="bm-static-container">
                                <div className="title">Category Name</div>
                                <div className="display">{categoryDetails.category_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Description</div>
                                <div className="display">{categoryDetails.category_description}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Category Type</div>
                                <div className="display">{GetCategoryCodeLabel(categoryDetails.category_type_code)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Status</div>
                                <div className="display">{GetStatusLabels('categories', parseInt(categoryDetails.category_status))}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
