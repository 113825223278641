import React, { useState } from 'react';
import { Row, Col, Card, Form, Input, Button, Alert, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../components/layout/PageLayout';
import { UpdateUserDetails } from '../../../services/users';
import usePageLoader from '../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../functions/Utilities';

export default function ProfileUpdate({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const history = useHistory();
    
    const submitHandler = async (values) => {
        showLoader();
        try {
            const formattedValues = {
                ...values,
                user_uuid: currentUser.user_uuid
            }
            const result = await UpdateUserDetails(formattedValues);
            switch(result.data.status) {
                case 'success':
                    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
                    currentUser = {
                        ...currentUser,
                        first_name: formattedValues.first_name,
                        last_name: formattedValues.last_name,
                    }
                    localStorage.setItem('currentUser', JSON.stringify(currentUser));
                    Debounce(NotificationManager.success('Profile has been updated.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    return (
        <PageLayout activeLink=''>
            { loader }
            {
                <Row>
                    <Col xs={24} md={{span: 12, offset: 6}}>
                        <Card title="Update Profile Details" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Profile Updated"
                                    description="Profile has been updated successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.goBack()}>Back</Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}
                                initialValues={{
                                    'first_name': currentUser.first_name,
                                    'last_name': currentUser.last_name,
                                    'mobile_number': currentUser.mobile_number,
                                    'email_address': currentUser.email_address,
                                }}>
                                <Form.Item label="First Name"
                                    name="first_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please your first name.',
                                        },
                                    ]}>
                                    <Input placeholder="first name" />
                                </Form.Item>
                                <Form.Item label="Last Name"
                                    name="last_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please your last name.',
                                        },
                                    ]}>
                                    <Input placeholder="last name" />
                                </Form.Item>
                                <Form.Item label="Mobile Number"
                                    name="mobile_number">
                                    <Input placeholder="mobile number" disabled={true} />
                                </Form.Item>
                                <Form.Item label="Email Address"
                                    name="email_address">
                                    <Input placeholder="email address" disabled={true} />
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
