import axios from 'axios';

export const GetAddOnGroups = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/add-on-groups/merchant/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const AddGroups = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/add-on-groups', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetAddOnGroupDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/add-on-groups/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateAddOnGroup = async(values) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/add-on-groups/' + values.add_on_group_uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const ImportAddOnGroups = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/add-on-groups/import', values);
        return result;
    } catch (error) {
        throw error;
    }
}