import React from 'react';

export default function Checked() {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" enableBackground="new 0 0 512 512">
            <g>
                <g>
                    <path d="M479.057,206.509c-5.988-4.845-15.953-12.927-17.201-16.757c-1.269-3.978,2.01-16.778,3.978-24.395
                        c4.718-18.386,10.6-41.258-2.581-59.263c-13.118-17.878-36.709-19.444-55.666-20.714c-7.85-0.55-21.01-1.418-24.332-3.83
                        c-3.364-2.433-8.421-14.895-11.446-22.364c-7.469-18.513-15.974-39.502-36.73-46.188c-20.65-6.749-39.523,5.205-56.132,15.72
                        c-7.321,4.612-18.344,11.595-22.956,11.595c-4.422,0-15.318-7.088-22.554-11.785c-15.974-10.389-35.947-23.253-56.999-16.101
                        c-20.714,7.046-29.134,27.907-36.603,46.272c-3.004,7.405-7.998,19.761-11.425,22.279c-3.343,2.475-16.736,3.364-24.734,3.893
                        c-19.994,1.354-42.654,2.878-55.391,20.904c-12.504,17.709-6.898,39.544-1.947,58.819c1.947,7.659,5.247,20.481,4.062,24.31
                        c-1.269,3.66-11.129,11.764-17.011,16.609C18.513,217.787,0,233.041,0,255.892c0,22.702,18.154,37.788,32.731,49.89
                        c6.326,5.247,15.868,13.181,17.159,17.117c1.269,3.893-2.031,16.545-4.02,24.099c-4.824,18.555-10.854,41.66,2.835,59.813
                        c13.351,17.646,36.836,19.211,55.73,20.481c7.85,0.55,20.989,1.396,24.332,3.83s8.379,14.874,11.404,22.343
                        c7.49,18.513,15.974,39.502,36.751,46.209c4.168,1.375,8.294,1.968,12.314,1.968c15.911,0,30.594-9.309,43.839-17.646
                        c7.299-4.612,18.302-11.595,22.914-11.595c4.507,0,15.339,6.898,22.491,11.468c15.868,10.092,35.672,22.745,56.682,15.551
                        c20.692-7.046,29.113-27.886,36.582-46.293c3.004-7.384,7.998-19.761,11.425-22.279c3.364-2.475,16.672-3.364,24.628-3.893
                        c19.846-1.354,42.358-2.856,55.286-20.608c13.012-17.836,7.426-39.904,2.497-59.348c-1.925-7.596-5.141-20.269-3.978-24.078
                        c1.269-3.66,11.129-11.764,16.99-16.609C493.487,294.04,512,278.764,512,255.913C512,233.253,493.72,218.421,479.057,206.509z
                        M451.7,273.686c-12.377,10.177-25.178,20.735-30.214,35.863c-5.141,15.403-0.952,31.906,3.089,47.859
                        c1.968,7.786,5.268,20.798,4.824,23.485c-3.216,2.412-16.482,3.322-24.416,3.83c-15.72,1.058-33.535,2.243-46.801,11.975
                        c-13.139,9.627-19.508,25.347-25.665,40.539c-3.004,7.49-8.082,20.036-10.177,21.941c-3.851,0-14.62-6.919-21.073-11.023
                        c-13.308-8.463-28.394-18.09-45.257-18.09c-16.884,0-32.097,9.648-45.553,18.154c-6.517,4.105-17.434,11.044-19.761,11.552
                        c-3.279-2.349-8.273-14.747-11.277-22.152c-6.22-15.382-12.652-31.271-25.918-40.814c-13.181-9.5-29.981-10.621-46.251-11.7
                        c-7.638-0.508-21.877-1.46-24.459-3.216c-1.312-3.745,2.01-16.545,4.02-24.162c3.914-15.086,8.379-32.181,3.343-47.796
                        c-5.057-15.593-18.492-26.786-30.362-36.646c-5.797-4.824-16.609-13.816-17.519-17.074c0.952-3.978,12.06-13.139,18.026-18.026
                        c12.377-10.198,25.178-20.756,30.214-35.884c5.184-15.509,0.91-32.139-3.195-48.219c-1.989-7.765-5.332-20.756-5.078-23.231
                        c3.237-2.327,16.397-3.216,24.268-3.745c16.524-1.1,33.641-2.264,46.907-11.975c13.139-9.627,19.508-25.326,25.665-40.517
                        c3.004-7.511,8.104-20.037,10.05-21.92c3.914,0.063,14.789,7.13,21.285,11.341c13.414,8.717,28.648,18.598,45.595,18.598
                        c16.863,0,32.118-9.648,45.532-18.132c6.517-4.126,17.434-11.044,19.762-11.552c3.258,2.349,8.273,14.747,11.277,22.152
                        c6.22,15.361,12.652,31.25,25.918,40.792c13.181,9.521,30.002,10.642,46.294,11.721c8.04,0.55,21.518,1.46,24.035,2.983
                        c1.291,3.724-1.989,16.588-3.978,24.247c-3.872,15.149-8.273,32.308-3.216,47.965c5.099,15.763,18.746,26.828,30.785,36.582
                        c5.501,4.485,15.636,12.674,17.201,16.609C468.119,260.124,457.476,268.904,451.7,273.686z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M350.608,176.19c-9.013-7.532-22.322-6.305-29.79,2.645l-94.343,112.793l-40.137-40.115
                        c-8.273-8.273-21.645-8.273-29.917,0c-8.273,8.273-8.273,21.645,0,29.917l56.492,56.492c3.956,3.999,9.352,6.199,14.959,6.199
                        c0.296,0,0.614,0,0.91-0.042c5.966-0.254,11.489-3.004,15.297-7.553L353.252,205.98C360.742,197.01,359.579,183.68,350.608,176.19
                        z"/>
                </g>
            </g>
        </svg>
    )
}