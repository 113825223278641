import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMerchantBranches } from '../../../../services/merchants';
import { GetServiceAreas } from '../../../../services/serviceareas';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function BranchesList({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [merchantBranches, setMerchantBranches] = useState(null);
    const [serviceAreas, setServiceAreas] = useState(null);
    const [serviceAreaDetails, setServiceAeraDetais] = useState(null);
    const history = useHistory();
    
    const getMerchantBranches = async() => {
        showLoader();
        const serviceAreasResult = await GetServiceAreas();
        const branchesResult = await GetMerchantBranches(currentUser.other_details.merchant_uuid, null);
        setServiceAreas(serviceAreasResult.data.data);
        setMerchantBranches(branchesResult.data.data);
        hideLoader();
    }

    const getServiceAreaName = (service_area_uuid) => {
        const key = Object.keys(serviceAreas).find(key => serviceAreas[key].service_area_uuid === service_area_uuid);
        setServiceAeraDetais(serviceAreas[key]);
        return serviceAreas[key].service_area_name;
    }

    const columns = [
        {
            title: 'Branch Name',
            dataIndex: 'branch_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={{pathname: `/app/merchants/branches/view/${row.branch_uuid}`, state: {serviceAreaDetails, serviceAreas}}}>{text}</Link>,
        },
        {
            title: 'Service Area',
            dataIndex: 'service_area_uuid',
            render: (text, row) => getServiceAreaName(text)
        },
        {
            title: 'Branch Code',
            dataIndex: 'branch_code',
        },
        {
            title: 'Contact Person',
            dataIndex: 'contact_person',
        },
        {
            title: 'Status',
            width: 110,
            dataIndex: 'branch_status',
            render: text => GetStatusLabels('branches', parseInt(text))
        }
    ];

    useEffect(() => {
        getMerchantBranches();
        return function cleanup() {
            getMerchantBranches();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='branches'>
            { loader }
            <div className="d-flex w-100">
                <h3 className="mb-4">Branches List</h3>
                <div className="ms-auto buttons">
                    <Button type="primary" className="bm-btn" onClick={() => history.push({pathname:'/app/merchants/branches/add', state: {serviceAreas}})}>Add Branch</Button>
                </div>
            </div>
            {
                merchantBranches &&
                <Table rowKey="merchant_uuid" dataSource={merchantBranches} columns={columns} size={'middle'} className="bm-table" />
            }
        </PageLayout>
    )
}
