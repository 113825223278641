import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetVouchers } from '../../../../services/vouchers';
import { GetServiceAreas } from '../../../../services/serviceareas';
import usePageLoader from '../../../../hooks/usePageLoader';
import { ConvertToDecimal } from '../../../../functions/Math';
import { FilterObject } from '../../../../functions/Utilities';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function VouchersList() {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [vouchers, setVouchers] = useState(null);
    const [serviceAreas, setServiceAreas] = useState(null);
    const history = useHistory();
    
    const getVouchers = async() => {
        showLoader();
        const vouchersResult = await GetVouchers();
        setVouchers(vouchersResult.data.data);
        const serviceAreasResult = await GetServiceAreas();
        setServiceAreas(serviceAreasResult.data.data);
        hideLoader();
    }

    const columns = [
        {
            title: 'Service Area',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/service-areas/view/${row.service_area_uuid}`}>{FilterObject(serviceAreas, row.service_area_uuid, 'service_area_uuid')[0].service_area_name}</Link>,
        },
        {
            title: 'Voucher Code',
            dataIndex: 'voucher_code',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/vouchers/view/${row.voucher_uuid}`}>{row.voucher_code}</Link>,
        },
        {
            title: 'Voucher Name',
            dataIndex: 'voucher_name',
            render: (text, row) => <span>{text}</span>
        },
        {
            title: 'Discount Type',
            dataIndex: 'discount_type',
            render: (text, row) => <span>{text}</span>
        },
        {
            title: 'Value',
            align: 'right',
            render: (text, row) => ShowValue(row.discount_type_value, row.discount_type),
        },
        {
            title: 'Status',
            render: (text, row) => GetStatusLabels('vouchers', row.voucher_status),
        }
    ];

    const ShowValue = (value, type) => {
        let discountType = type.split('-');
        switch (discountType[1]) {
            case 'percentage':
                return value / 100 + '%';
            case 'fixed':
                return 'P' + ConvertToDecimal(value);
            default:
                return false;
        }
    }

    useEffect(() => {
        getVouchers();
        return function cleanup() {
            getVouchers();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='vouchers'>
            { loader }
            <div className="d-flex w-100">
                <h3 className="mb-4">Vouchers List</h3>
                <div className="ms-auto buttons">
                    <Button type="primary" className="bm-btn" onClick={() => history.push('/app/admin/vouchers/add')}>Add Voucher</Button>
                </div>
            </div>
            {
                vouchers && serviceAreas &&
                <Table rowKey="voucher_uuid" dataSource={vouchers} columns={columns} size={'middle'} className="bm-table" />
            }
        </PageLayout>
    )
}
