import axios from 'axios';

export const UploadFiles = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/files', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetFile = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/files/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}