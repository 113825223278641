import axios from 'axios';

export const GetServiceAreas = async() => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/service-areas');
        return result;
    } catch (error) {
        throw error;
    }
}

export const AddServiceAreas = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/service-areas', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetServiceAreaDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/service-areas/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateServiceAreas = async(values) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/service-areas/' + values.service_area_uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}