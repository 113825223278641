import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Input, InputNumber, Button, Alert, Select, DatePicker } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetServiceAreas } from '../../../../services/serviceareas';
import { GetMerchants } from '../../../../services/merchants';
import { GetMerchantBranches } from '../../../../services/merchants';
import { GetMerchantItems } from '../../../../services/items';
import { AddVoucher } from '../../../../services/vouchers';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';
import moment from 'moment';

export default function VouchersAdd() {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [serviceAreas, setServiceAreas] = useState(null);
    const [merchants, setMerchants] = useState(null);
    const [merchantBranches, setMerchantBranches] = useState(null);
    const [items, setItems] = useState(null);
    const [success, setSuccess] = useState(null);
    const history = useHistory();
    const { TextArea } = Input;
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    
    const getPageData = async() => {
        showLoader();
        const serviceAreaResult = await GetServiceAreas();
        setServiceAreas(serviceAreaResult.data.data);
        hideLoader();
    }

    const updateMerchants = async(uuid) => {
        showLoader();
        const merchantsResult = await GetMerchants(uuid);
        setMerchants(merchantsResult.data.data);
        setMerchantBranches(null);
        setItems(null);
        hideLoader();
    }

    const updateBranches = async(uuid) => {
        showLoader();
        const branchesResult = await GetMerchantBranches(uuid, null);
        setMerchantBranches(branchesResult.data.data);
        const itemsResult = await GetMerchantItems(uuid);
        setItems(itemsResult.data.data);
        hideLoader();
    }

    const submitHandler = async (values) => {
        showLoader();
        try {
            const formattedValues = {
                ...values,
                description: (typeof values.description === 'undefined') ? '' : values.description,
                merchant_uuid: (typeof values.merchant_uuid === 'undefined') ? null : values.merchant_uuid,
                branch_uuid: (typeof values.branch_uuid === 'undefined') ? null : values.branch_uuid,
                item_uuid: (typeof values.item_uuid === 'undefined') ? null : values.item_uuid,
                uses_max: values.uses_max * 100,
                uses_max_user: values.uses_max_user * 100,
                min_order_amount: values.min_order_amount * 100,
                discount_type_value: values.discount_type_value * 100,
                share_merchant_value: values.share_merchant_value * 100,
                share_platform_value: values.share_platform_value * 100,
                voucher_start: values.voucher_validity[0].format('YYYY-MM-DD'),
                voucher_end: values.voucher_validity[1].format('YYYY-MM-DD'),
                voucher_status: 1
            }
            const result = await AddVoucher(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Service area has been saved.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                case 'error':
                    Debounce(NotificationManager.error('Service area already exists. Please use a different service area code.', 'Service Area Exists', 2000), 2000);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    useEffect(() => {
        getPageData();
        return function cleanup() {
            getPageData();
        }
        // eslint-disable-next-line
    }, [])

    return (
        <PageLayout activeLink='vouchers'>
            { loader }
            {
                <Row>
                    <Col xs={24} md={{span: 12, offset: 6}}>
                        <Card title="Add Voucher" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="New Voucher Added"
                                    description="Voucher has been added successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.push('/app/admin/vouchers/list')}>
                                            Back to List
                                        </Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}>
                                <Form.Item label="Service Area"
                                    name="service_area_uuid"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select service area.',
                                        },
                                    ]}>
                                    <Select
                                        defaultValue=""
                                        className="bm-select"
                                        onChange={(uuid) => updateMerchants(uuid)}>
                                        {
                                            serviceAreas && serviceAreas.map(item => {
                                                return <Option value={item.service_area_uuid}>{item.service_area_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Voucher Code"
                                    name="voucher_code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the voucher code.',
                                        },
                                    ]}>
                                    <Input placeholder="voucher code" />
                                </Form.Item>
                                <Form.Item label="Voucher Name"
                                    name="voucher_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter voucher name.',
                                        },
                                    ]}>
                                    <Input placeholder="voucher name" />
                                </Form.Item>
                                <Form.Item label="Description"
                                    name="description">
                                    <TextArea rows={4} placeholder="description" />
                                </Form.Item>
                                <Form.Item label="No of Uses (max)"
                                    name="uses_max"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter number of voucher uses.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="max number of uses" precision={2} step={1} />
                                </Form.Item>
                                <Form.Item label="No of Uses (customer)"
                                    name="uses_max_user"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter number of voucher uses per customer.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="max number of uses per customer" precision={2} step={1} />
                                </Form.Item>
                                <Form.Item label="Merchant"
                                    name="merchant_uuid">
                                    <Select
                                        defaultValue=""
                                        className="bm-select"
                                        onChange={(value) => updateBranches(value)}
                                        allowClear={true}>
                                        {
                                            merchants && merchants.map(item => {
                                                return <Option value={item.merchant_uuid}>{item.merchant_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                {
                                    merchantBranches &&
                                    <Form.Item label="Branch"
                                        name="branch_uuid">
                                        <Select defaultValue=""
                                            className="bm-select"
                                            allowClear={true}>
                                            {
                                                merchantBranches && merchantBranches.map(item => {
                                                    return <Option value={item.branch_uuid}>{item.branch_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                }
                                {
                                    items &&
                                    <Form.Item label="Item"
                                        name="item_uuid">
                                        <Select defaultValue="" className="bm-select" allowClear={true} showSearch={true}
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                items && items.map(item => {
                                                    return <Option value={item.item_uuid}>{item.item_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                }
                                <Form.Item label="Order Amount (min)"
                                    name="min_order_amount"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the min order amount.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0.00" precision={2} step={.01} />
                                </Form.Item>
                                <Form.Item label="Discount Type"
                                    name="discount_type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select discount type.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select">
                                        <Option value='order-percentage'>Order (Percentage)</Option>
                                        <Option value='order-fixed'>Order (Fixed Amount)</Option>
                                        <Option value='delivery-percentage'>Delivery (Percentage)</Option>
                                        <Option value='delivery-fixed'>Delivery (Fixed Amount)</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Discount Type Value"
                                    name="discount_type_value"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter value of discount type.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0.00" precision={2} step={.01} />
                                </Form.Item>
                                <Form.Item label="Merchant's Share"
                                    name="share_merchant_value"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter share value of merchant.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0.00" precision={2} step={.01} />
                                </Form.Item>
                                <Form.Item label="Platform's Share"
                                    name="share_platform_value"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter share value of platform.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0.00" precision={2} step={.01} />
                                </Form.Item>
                                <Form.Item label="Validity"
                                    name="voucher_validity"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select voucher validity.',
                                        },
                                    ]}>
                                    <RangePicker
                                        format={"YYYY-MM-DD"}
                                        disabledDate={current => {
                                            return current && current < moment().subtract(1, "day");
                                        }}
                                        />
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
