import axios from 'axios';

export const GetFeatures = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/features/service-area/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetFeaturesActive = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/features/service-area/' + uuid + '/active');
        return result;
    } catch (error) {
        throw error;
    }
}

export const AddFeatures = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/features', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetFeaturesDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/features/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateFeatures = async (values) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/features/' + values.feature_uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}