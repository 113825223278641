import axios from 'axios';

export const GetMenuItemCategories = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/menus-items-categories/menu-category/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetMenuItemCategoriesActive = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/menus-items-categories/menu-category/' + uuid + '/active');
        return result;
    } catch (error) {
        throw error;
    }
}

export const AddMenuItemCategories = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/menus-items-categories', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateMenuItemCategories = async(values) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/menus-items-categories/' + values.menu_item_category_uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}