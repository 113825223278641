import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Modal, Alert, List, Checkbox } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import Checked from '../../../../components/icons/Checked';
import { GetMerchantItems } from '../../../../services/items';
import { GetAddOnGroups } from '../../../../services/addongroups';
import { GetItemsVariations, UpdateVariation } from '../../../../services/itemsvariations';
import { GetVariationAddOnsAll, GetVariationAddOnsActive, AddVariationAddOns, UpdateVariationAddOns } from '../../../../services/variationaddons';
import { GetAddOns } from '../../../../services/addons';
import { ConvertToDecimal } from '../../../../functions/Math';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined, CloseCircleOutlined, CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function ItemsList({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [items, setItems] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemUuid, setSelectedItemUuid] = useState(null);
    const [variations, setVariations] = useState(null);
    const [selectedVariation, setSelectedVariation] = useState(null);
    const [selectedVariationUuid, setSelectedVariationUuid] = useState(null);
    const [variationAddOnGroupsAll, setVariationAddOnGroupsAll] = useState(null);
    const [variationAddOnGroupsActive, setVariationAddOnGroupsActive] = useState(null);
    const [variationAddOnGroupsActiveList, setVariationAddOnGroupsActiveList] = useState(null);
    const [selectedAddOnGroup, setSelectedAddOnGroup] = useState(null);
    const [selectedAddOnGroupUuid, setSelectedAddOnGroupUuid] = useState(null);
    const [selectedAddOns, setSelectedAddOns] = useState(null);
    const [addOnGroups, setAddOnGroups] = useState(null);
    const [selectedModalItems, setSelectedModalItems] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const history = useHistory();
    
    const getItems = async() => {
        showLoader();
        setItems(null);
        setAddOnGroups(null);
        const itemsResult = await GetMerchantItems(currentUser.other_details.merchant_uuid);
        setItems(itemsResult.data.data);
        const addOnGroupsResult = await GetAddOnGroups(currentUser.other_details.merchant_uuid);
        setAddOnGroups(addOnGroupsResult.data.data);
        hideLoader();
    }

    const getVariations = async(item) => {
        showLoader();
        const variationsResult = await GetItemsVariations(item.item_uuid);
        setVariations(variationsResult.data.data);
        setSelectedItem(item);
        hideLoader();
    }

    const getVariationAddOnGroups = async(item) => {
        showLoader();
        const variationAddOnGroupsResult = await GetVariationAddOnsActive(item.variation_uuid);
        const variationAddOnGroupResultList = [];
        variationAddOnGroupsResult.data.data.forEach(function(item, index) {
            variationAddOnGroupsResult.data.data[index].index = index;
            variationAddOnGroupResultList.push(item.add_on_group_uuid);
        });
        setVariationAddOnGroupsActive(variationAddOnGroupsResult.data.data);
        setVariationAddOnGroupsActiveList(variationAddOnGroupResultList);
        const variationsAddOnsAllResult = await GetVariationAddOnsAll(item.variation_uuid);
        setVariationAddOnGroupsAll(variationsAddOnsAllResult.data.data);
        setSelectedVariation(item);
        setSelectedAddOnGroup(null);
        hideLoader();
    }

    const getAddOns = async(item) => {
        showLoader();
        const addOnsResult = await GetAddOns(item.add_on_group_uuid);
        setSelectedAddOns(addOnsResult.data.data);
        setSelectedAddOnGroup(item);
        hideLoader();
    }

    const setRowItemSelected = (rowData) => {
        return selectedItemUuid === rowData.item_uuid ? 'row-highlighted' : '';
    }

    const setRowVariationSelected = (rowData) => {
        return selectedVariationUuid === rowData.variation_uuid ? 'row-highlighted' : '';
    }

    const setRowAddOnGroupSelected = (rowData) => {
        return selectedAddOnGroupUuid === rowData.add_on_group_uuid ? 'row-highlighted' : '';
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        getVariationAddOnGroups(selectedVariation);
        setSelectedModalItems([]);
    };

    const handleAddItems = async() => {
        if (selectedModalItems.length < 1) {
            Debounce(NotificationManager.error('Please select at least one add-on group to add or just click Cancel to close the window.', 'Sorry', 2000), 2000);
        } else {
            showLoader();
            Promise.all(selectedModalItems.map(async(item) => {
                const index = CheckItemIsSelected(variationAddOnGroupsAll, item);
                const formattedValues = {
                    variation_uuid: selectedVariationUuid,
                    add_on_group_uuid: item,
                    display_order: 1,
                    variation_add_on_status: 1
                }
                if (typeof index !== 'undefined') {
                    formattedValues['variation_add_on_uuid'] = variationAddOnGroupsAll[index].variation_add_on_uuid;
                    return await UpdateVariationAddOns(formattedValues);
                } else {
                    return await AddVariationAddOns(formattedValues);
                }
            })).then((values) => {
                if (values.length > 0) {
                    Debounce(NotificationManager.success('Add-on group has been added to the variation.', 'Success', 2000), 2000);
                } else {
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                }
                handleCancel();
                hideLoader();
            });
        }
    }

    const CheckItemIsSelected = (object, value) => {
        return Object.keys(object).find(key => object[key].add_on_group_uuid === value);
    }

    const handleSelectItemChange = (element) => {
        if (element.target.checked === true) {
            setVariationAddOnGroupsActiveList([
                ...variationAddOnGroupsActiveList,
                element.target.value
            ]);
            selectedModalItems.push(element.target.value);
        } else {
            var index = variationAddOnGroupsActiveList.indexOf(element.target.value);
            variationAddOnGroupsActiveList.splice(index, 1);
            var filtered = selectedModalItems.filter(function(value, index) {
                return value !== element.target.value;
            });
            setSelectedModalItems(filtered);
        }
    }

    const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
    const SortableItem = sortableElement(props => <tr {...props} />);
    const SortableContainer = sortableContainer(props => <tbody {...props} />);

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={handleSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({className, style, ...restProps}) => {
        const dataSource = variationAddOnGroupsActive;
        const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const handleSortEnd = ({oldIndex, newIndex}) => {
        const dataSource = variationAddOnGroupsActive;
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            setVariationAddOnGroupsActive(newData);
            updateOrder(newData);
        }
    }

    const updateOrder = async(values) => {
        showLoader();
        Promise.all(values.map(async (item, key) => {
            const formattedValues = {
                variation_add_on_uuid: item.variation_add_on_uuid,
                variation_uuid: selectedVariationUuid,
                add_on_group_uuid: item.add_on_group_uuid,
                display_order: key + 1
            }
            return await UpdateVariationAddOns(formattedValues);
        })).then(result => {
            if (values.length === result.length) {
                Debounce(NotificationManager.success('Add-On Groups has been updated successfully.', 'Success', 2000), 2000);
            } else {
                Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
            }
            hideLoader();
        });
    }

    const handleVariationDelete = async(uuid, status, index) => {
        const values = {
            variation_uuid: uuid,
            variation_status: status === 1 ? 0 : 1
        }
        showLoader();
        const deleteResult = await UpdateVariation(values);
        if (deleteResult.data.status = 'success') {
            Debounce(NotificationManager.success('Variation has been updated successfully.', 'Success', 2000), 2000);
            getVariations(selectedItem);
        }
        hideLoader();
    }

    const handleAddOnGroupsDelete = async(uuid, index) => {
        const values = {
            variation_add_on_uuid: uuid,
            variation_add_on_status: 0
        }
        showLoader();
        const deleteResult = await UpdateVariationAddOns(values);
        if (deleteResult.data.status = 'success') {
            Debounce(NotificationManager.success('Variation add-ons has been removed successfully.', 'Success', 2000), 2000);
            getVariationAddOnGroups(selectedVariation);
        }
        hideLoader();
    }

    const itemColumns = [
        {
            title: 'Item Name',
            dataIndex: 'item_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/merchants/items/view/${row.item_uuid}`} title="view item details">{text}</Link>,
        },
        {
            title: 'Status',
            width: 110,
            dataIndex: 'item_status',
            render: text => GetStatusLabels('items', parseInt(text))
        }
    ];

    const variationColumns = [
        {
            title: 'Variation Name',
            dataIndex: 'variation_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={{ pathname: `/app/merchants/items/variations/view/${row.variation_uuid}`, state: {itemDetails: selectedItem}} } title="view variation details"><span className="d-inline-flex align-items-center bestseller-icon-wrapper">{text} {(row.is_bestseller === 1) ? <Checked /> : ''}</span></Link>,
        },
        {
            title: 'Price',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.variation_price),
        },
        {
            title: 'Status',
            width: 110,
            dataIndex: 'variation_status',
            render: text => GetStatusLabels('variations', parseInt(text))
        },
        {
            title: 'Options',
            width: 90,
            render: (text, row) => <Button className={row.variation_status === 1 ? 'bm-btn btn-icon-danger' : 'bm-btn btn-icon-success'} title={row.variation_status === 1 ? 'remove' : 'activate'} type={'table-options-icon'} onClick={() => handleVariationDelete(row.variation_uuid, row.variation_status, row.index)}>{ row.variation_status === 1 ? <CloseCircleOutlined /> : <CheckCircleOutlined />}</Button>,
        }
    ]

    const addOnGroupsColumns = [
        {
            title: 'Sort',
            dataIndex: 'sort',
            width: 60,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: 'Add-On Group Name',
            dataIndex: 'add_on_group_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/merchants/add-ons/groups/view/${row.add_on_group_uuid}`} title="view add-on group details">{text}</Link>,
        },
        {
            title: 'Required',
            dataIndex: 'is_required',
            render: text => GetStatusLabels('bool', parseInt(text))
        },
        {            
            title: 'Multiple',
            dataIndex: 'is_multiple',
            render: text => GetStatusLabels('bool', parseInt(text))
        },
        {
            title: 'Status',
            width: 110,
            dataIndex: 'add_on_group_status',
            render: text => GetStatusLabels('add-on-groups', parseInt(text))
        },
        {
            title: 'Options',
            width: 90,
            render: (text, row) => <Button className="bm-btn bm-btn btn-icon-danger" type={'table-options-icon'} onClick={() => handleAddOnGroupsDelete(row.variation_add_on_uuid, row.index)}><DeleteOutlined /></Button>,
        }
    ]

    const addOnColumns = [
        {
            title: 'Add-On Name',
            dataIndex: 'add_on_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={{ pathname: `/app/merchants/add-ons/view/${row.add_on_uuid}`, state: {groupDetails: selectedAddOnGroup}}} title="view add-on details"><span className="d-inline-flex align-items-center bestseller-icon-wrapper">{text} {(row.is_bestseller === 1) ? <Checked /> : ''}</span></Link>,
        },
        {
            title: 'Add-On Price',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.add_on_price),
        },
        {
            title: 'Status',
            dataIndex: 'add_on_status',
            render: text => GetStatusLabels('add-ons', parseInt(text))
        }
    ]

    useEffect(() => {
        getItems();
        return function cleanup() {
            getItems();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='items'>
            { loader }
            <div className="scroll-x">
                <Row gutter={24} style={{ width: '2300px'}}>
                    <Col sm={6}>
                        <div className="d-flex w-100">
                            <h3 className="mb-4">Items List</h3>
                            <div className="ms-auto buttons">
                                <Button type="primary" className="bm-btn" onClick={() => history.push('/app/merchants/items/add')}>Add Item</Button>
                            </div>
                        </div>
                        {
                            <div>
                                <Table  rowKey="menu_uuid" dataSource={items} columns={itemColumns} size={'middle'} className="bm-table row-clickable items-table mb-5"
                                        onRow={(rowData, rowIndex) => {
                                            return {
                                                onClick: event => {
                                                    getVariations(rowData);
                                                    setSelectedItemUuid(rowData.item_uuid);
                                                    setVariations(null);
                                                    setSelectedItem(null);
                                                    setVariationAddOnGroupsActive(null);
                                                    setSelectedVariationUuid(null);
                                                    setSelectedVariation(null);
                                                    setSelectedAddOnGroupUuid(null);
                                                    setSelectedAddOns(null);
                                                }
                                            }
                                        }}
                                        rowClassName={setRowItemSelected}
                                        pagination={true}
                                />
                            </div>
                        }
                    </Col>
                    <Col sm={6}>
                        {
                            selectedItem &&
                            <div>
                                <div className="d-flex w-100">
                                    <h3 className="mb-4">{selectedItem.item_name}</h3>
                                    <div className="ms-auto buttons">
                                        <Button type="primary" className="bm-btn" onClick={() => history.push({pathname: '/app/merchants/items/variations/add/' + selectedItem.item_uuid, state: {item: selectedItem}})}>Add Variation</Button>
                                    </div>
                                </div>
                                <Table  rowKey="menu_uuid" dataSource={variations} columns={variationColumns} size={'middle'} className="bm-table row-clickable"
                                        onRow={(rowData, rowIndex) => {
                                            return {
                                                onClick: event => {
                                                    getVariationAddOnGroups(rowData);
                                                    setSelectedVariationUuid(rowData.variation_uuid);
                                                    setVariationAddOnGroupsActive(null);
                                                    setSelectedVariation(null);
                                                    setSelectedAddOnGroup(null);
                                                    setSelectedAddOnGroupUuid(null);
                                                    setSelectedAddOns(null);
                                                }
                                            }
                                        }}
                                        rowClassName={setRowVariationSelected}
                                        pagination={false}
                                />
                            </div>
                        }
                    </Col>
                    <Col sm={6}>
                        {
                            selectedVariation &&
                            <div>
                                <div className="d-flex w-100">
                                    <h3 className="mb-4">{selectedVariation.variation_name}</h3>
                                    <div className="ms-auto buttons">
                                        <Button type="primary" className="bm-btn" onClick={showModal}>Add Add-On Groups</Button>
                                    </div>
                                </div>
                                <Table  rowKey="index" dataSource={variationAddOnGroupsActive} columns={addOnGroupsColumns} size={'middle'} className="bm-table row-clickable"
                                        onRow={(rowData, rowIndex) => {
                                            return {
                                                onClick: event => {
                                                    getAddOns(rowData);
                                                    setSelectedAddOnGroupUuid(rowData.add_on_group_uuid);
                                                    setSelectedAddOnGroup(null);
                                                    setSelectedAddOns(null);
                                                }
                                            }
                                        }}
                                        rowClassName={setRowAddOnGroupSelected}
                                        components={{
                                            body: {
                                                wrapper: DraggableContainer,
                                                row: DraggableBodyRow
                                            },
                                        }}
                                        pagination={false}
                                />
                            </div>
                        }
                    </Col>
                    <Col sm={6}>
                        {
                            selectedAddOnGroup &&
                            <div>
                                <h3 className="mb-4">{selectedAddOnGroup.add_on_group_name}</h3>
                                <Table  rowKey="menu_uuid" dataSource={selectedAddOns} columns={addOnColumns} size={'middle'} className="bm-table" />
                            </div>
                        }
                    </Col>
                </Row>
            </div>
            <Modal className="bm-modal" title="Add-On Groups List" visible={isModalVisible} onCancel={handleCancel} okText='Add Add-On Groups' onOk={handleAddItems}>
                <Alert
                    className="bm-alert mt-1 mb-4"
                    message="Note"
                    description="Select the add-on groups you want to add to the item variation and click Add Add-Ons button."
                    type="default"
                />
                <List
                    dataSource={addOnGroups}
                    renderItem={item => (
                        <List.Item>
                            {
                                <Checkbox
                                    checked={variationAddOnGroupsActiveList.includes(item.add_on_group_uuid) ? true : false}
                                    disabled={(CheckItemIsSelected(variationAddOnGroupsActive, item.add_on_group_uuid) >= 0) ? true : false}
                                    value={item.add_on_group_uuid}
                                    onChange={(element) => handleSelectItemChange(element)}>
                                        {item.add_on_group_name}
                                </Checkbox>
                            }
                        </List.Item>
                    )}
                />
            </Modal>
        </PageLayout>
    )
}
