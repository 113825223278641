import axios from 'axios';

export const GetVouchers = async() => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/vouchers');
        return result;
    } catch (error) {
        throw error;
    }
}

export const AddVoucher = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/vouchers', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetVoucherDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/vouchers/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateVoucher = async(values, uuid) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/vouchers/' + uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}