import { CategoryTypes } from '../data/CategoryTypes';
import { Regions } from '../data/Regions';
import { FoodCategoryTypes } from '../data/FoodCategoryTypes';
import { Tag } from 'antd';

export const GetCategoryCodeLabel = (category_type_code) => {
    const result = CategoryTypes.find(item => item.code === category_type_code);
    return result.categoryType;
}

export const GetRegionLabel = (region_name) => {
    const result = Regions.find(item => item.code === region_name);
    return result.regionName;
}


export const MobileNumberFormat = (type, number) => {
    let mobileNumberString = '';
    switch (type) {
        case 'save':
            let numberFormatted = number.split(' ');
            numberFormatted = numberFormatted.join('');
            mobileNumberString = '63' + numberFormatted.substring(1);
            break;
        case 'view':
            mobileNumberString =  '0' + number.substring(2);
            break;
        default:
            mobileNumberString = number;
            break;
    }
    return mobileNumberString;
}

export const GetFoodCategoryLabels = (values) => {
    let string = 'No entries';
    if (values !== '') {
        let labelArray = [];
        let valuesArray = values.split(',');
        valuesArray.forEach(item => {
            const result = FoodCategoryTypes.find(itemCategory => itemCategory.code === item);
            labelArray.push(result.categoryName);
        });
        string = labelArray.join(', ');
    }
    return string;
}

export const GetRequestTypeLabel = (code) => {
    let label = '';
    switch (code) {
        case 'J001':
            label = 'Wallet Prefund Request';
            break;
        default:
            break; 
    }
    return label;
}

export const GetStatusLabels = (module, status) => {
    let label = '';
    switch(module) {
        case 'branches':
        case 'categories':
        case 'menus':
        case 'items':
        case 'variations':
        case 'add-on-groups':
        case 'add-ons':
        case 'service-areas':
        case 'merchants':
        case 'vouchers':
        case 'users':
        case 'features':
        case 'spotlights':
            switch(status) {
                case 0:
                    label = <Tag className="danger">INACTIVE</Tag>;
                    break;
                case 1:
                    label = <Tag className="success">ACTIVE</Tag>;
                    break;
                case 2:
                    label = <Tag className="warning">SUSPENDED</Tag>;
                    break;
                default:
                    break;
            }
            break;
        case 'orders':
            switch(status) {
                case 1:
                    label = <Tag className="warning">ACTIVE</Tag>;
                    break;
                case 2:
                    label = <Tag className="success">FULFILLED</Tag>;
                    break;
                case 3:
                    label = <Tag className="danger">CANCELLED</Tag>;
                    break;
                default:
                    break;
            }
            break;
        case 'requests':
            switch(status) {
                case 0:
                    label = <Tag className="danger">INACTIVE</Tag>;
                    break;
                case 1:
                    label = <Tag className="success">ACTIVE</Tag>;
                    break;
                case 2:
                    label = <Tag className="primary">APPROVED</Tag>;
                    break;
                case 3:
                    label = <Tag className="warning">DISAPPROVED</Tag>;
                    break;
                default:
                    break;
            }
            break;
        case 'wallets':
            switch(status) {
                case 0:
                    label = <Tag className="danger">REVERTED</Tag>;
                    break;
                case 1:
                    label = <Tag className="success">APPROVED</Tag>;
                    break;
                case 2:
                    label = <Tag className="primary">DISAPPROVED</Tag>;
                    break;
                default:
                    break;
            }
            break;
        case 'bool':
            switch(status) {
                case 0:
                    label = <Tag className="warning">NO</Tag>;
                    break;
                case 1:
                    label = <Tag className="primary">YES</Tag>;
                    break;
                default:
                    break;
            }
            break;
        case 'user-type':
            switch(status) {
                case 'A001':
                    label = 'Administrator';
                    break;
                case 'A002':
                    label = 'Customer';
                    break;
                case 'A003':
                    label = 'Delivery Partner';
                    break;
                case 'A004':
                    label = 'Merchant Partner';
                    break;
                default:
                    break;
            }
            break;
        case 'tracking':
            switch(status) {
                case 'G001':
                    label = 'Please Prepare - Delivery Partner on the Way';
                    break;
                case 'G002':
                    label = 'Delivery Partner Arrived - Waiting for Items';
                    break;
                case 'G003':
                    label = 'Order Picked Up by Delivery Partner - On the Way to Customer';
                    break;
                case 'G004':
                    label = 'Order Delivered & Fulfilled';
                    break;
                default:
                    break;
            }
            break;
        case 'tracking-admin':
            switch(status) {
                case 'G001':
                    label = 'Order Received & Assigned';
                    break;
                case 'G002':
                    label = 'Order Prepared';
                    break;
                case 'G003':
                    label = 'Order Picked Up by Delivery Partner';
                    break;
                case 'G004':
                    label = 'Order Delivered & Fulfilled';
                    break;
                default:
                    break;
            }
            break;
        case 'access-types':
            switch(status) {
                case 'C001':
                    label = <Tag className="grey-light">SUPER ADMIN</Tag>;
                    break;
                case 'C002':
                    label = <Tag className="grey-light">FINANCE</Tag>;
                    break;
                case 'C003':
                    label = <Tag className="grey-light">OPERATIONS</Tag>;
                    break;
                case 'C004':
                    label = <Tag className="grey-light">STAFF</Tag>;
                    break;
                default:
                    break;
            }
            break;
        case 'wallets-transactions':
            switch(status) {
                case 'I001':
                    label = 'Payment';
                    break;
                case 'I002':
                    label = 'Transfer';
                    break;
                case 'I003':
                    label = 'Fee';
                    break;
                case 'I004':
                    label = 'Refund';
                    break;
                case 'I005':
                    label = 'Rollback';
                    break;
                case 'I006':
                    label = 'Adjustment';
                    break;
                case 'I007':
                    label = 'Rebate';
                    break;
                case 'I008':
                    label = 'Prefund';
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }
    return label;
}