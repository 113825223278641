import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Card, Button } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetVariationDetails } from '../../../../services/itemsvariations';
import { ConvertToDecimal } from '../../../../functions/Math';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function VariationsView() {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [variationDetails, setVariationDetails] = useState(null);
    const history = useHistory();
    const itemDetails = history.location.state.itemDetails;
    const { uuid }  = useParams();
    
    const getVariationDetails = async() => {
        showLoader();
        const variationDetailsResult = await GetVariationDetails(uuid);
        setVariationDetails(variationDetailsResult.data.data);
        setDisplay(true);
        hideLoader();
    }

    useEffect(() => {
        getVariationDetails();
        return function cleanup() {
            getVariationDetails();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='items'>
            { loader }
            {
                display && 
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <Card title="Stats" className="bm-card mb-4">
                            Stats soon
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card   title="Variation Details"
                                className="bm-card mb-4 mb-md-0"
                                actions={[
                                    <div className="text-end"><Button type="outline-primary" className="bm-btn" onClick={() => history.push({pathname: '/app/merchants/items/variations/update/' + uuid, state: {variationDetails, itemDetails}})}>Update</Button></div>
                                ]}>
                            <div className="bm-static-container">
                                <div className="title">Item Name</div>
                                <div className="display">{itemDetails.item_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Variation Name</div>
                                <div className="display">{variationDetails.variation_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Description</div>
                                <div className="display">{variationDetails.variation_description}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Price</div>
                                <div className="display">{'P' + ConvertToDecimal(variationDetails.variation_price)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Bestseller</div>
                                <div className="display">{GetStatusLabels('bool', variationDetails.is_bestseller)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Status</div>
                                <div className="display">{GetStatusLabels('variations', variationDetails.variation_status)}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
