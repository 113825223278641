import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card, Form, Button, Alert, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetServiceAreas } from '../../../../services/serviceareas';
import { UpdateDeliveryPartner } from '../../../../services/users';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';

export default function DeliveryPartnersUpdate({ currentUser }) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const [serviceAreas, setServiceAreas] = useState(null);
    const { Option } = Select;
    const history = useHistory();
    const { uuid }  = useParams();
    const userDetails = history.location.state.userDetails;

    const getServiceAreas = async() => {
        showLoader();
        const serviceAreaResult = await GetServiceAreas();
        setServiceAreas(serviceAreaResult.data.data);
        hideLoader();
    }
    
    const submitHandler = async (values) => {
        showLoader();
        try {
            const userValues = {
                user_uuid: uuid,
                user_status: parseInt(values.user_status),
                service_area_uuid: values.service_area_uuid
            }
            const result = await UpdateDeliveryPartner(userValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Delivery partner has been updated.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    useEffect(() => {
        getServiceAreas();
        return function cleanup() {
            getServiceAreas();
        }
        // eslint-disable-next-line
    }, [])

    return (
        <PageLayout activeLink='delivery-partners'>
            { loader }
            {
                <Row gutter={24}>
                    <Col xs={12}>
                        <Card title="Delivery Partner Details" className="bm-card mb-4 mb-md-0">
                            <div className="bm-static-container">
                                <div className="title">First Name</div>
                                <div className="display">{userDetails.first_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Last Name</div>
                                <div className="display">{userDetails.last_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Mobile Number</div>
                                <div className="display">{userDetails.mobile_number}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Email</div>
                                <div className="display"><a href={`mailto:${userDetails.email_address}`} className="text-link-primary-x3">{userDetails.email_address}</a></div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">License Number</div>
                                <div className="display">{userDetails.other_details.license_number}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">License Type</div>
                                <div className="display">{userDetails.other_details.license_type}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">License Restrictions</div>
                                <div className="display">{userDetails.other_details.license_restrictions}</div>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={12}>
                        <Card title="Update Delivery Partner" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Category Updated"
                                    description="Category has been updated successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.goBack()}>Back</Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}
                                initialValues={{
                                    'service_area_uuid': userDetails.other_details.service_area_uuid,
                                    'user_status': String(userDetails.user_status)
                                }}>
                                <Form.Item label="Service Area"
                                    name="service_area_uuid"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select service area.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select">
                                        {
                                            serviceAreas && serviceAreas.map(item => {
                                                return <Option value={item.service_area_uuid}>{item.service_area_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Status"
                                    name="user_status"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select status.',
                                        },
                                    ]}>
                                    <Select>
                                        <Option value="1">Active</Option>
                                        <Option value="0">Inactive</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
