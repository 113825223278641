import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Button } from 'antd';
import PageLayout from '../../../components/layout/PageLayout';
import { GetStatusLabels } from '../../../functions/Labels';

export default function ProfileView({currentUser}) {
    const history = useHistory();

    return (
        <PageLayout activeLink=''>
            <Row gutter={24}>
                <Col xs={24} md={16}>
                    <Card title="Stats" className="bm-card mb-4 mb-md-0">
                        Stats soon
                    </Card>
                </Col>
                <Col xs={24} md={8}>
                    <Card title="Profile Details" className="bm-card mb-4 mb-md-0"
                        actions={[
                            <div className="text-end"><Button type="outline-primary" className="bm-btn" onClick={() => history.push({pathname: '/app/profile/update'})}>Update</Button></div>
                        ]}>
                        <div className="bm-static-container">
                            <div className="title">First Name</div>
                            <div className="display">{currentUser.first_name}</div>
                        </div>
                        <div className="bm-static-container">
                            <div className="title">Last Name</div>
                            <div className="display">{currentUser.last_name}</div>
                        </div>
                        <div className="bm-static-container">
                            <div className="title">Mobile Number</div>
                            <div className="display">{currentUser.mobile_number}</div>
                        </div>
                        <div className="bm-static-container">
                            <div className="title">Email Address</div>
                            <div className="display">{currentUser.email_address}</div>
                        </div>
                        <div className="bm-static-container">
                            <div className="title">Status</div>
                            <div className="display">{GetStatusLabels('users', parseInt(currentUser.user_status))}</div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </PageLayout>
    )
}
