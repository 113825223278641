import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetActiveRequests } from '../../../../services/requests';
import usePageLoader from '../../../../hooks/usePageLoader';
import { ConvertToDecimal } from '../../../../functions/Math';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function RequestsList({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [requests, setRequests] = useState(null);
    
    const getRequests = async() => {
        showLoader();
        const result = await GetActiveRequests(currentUser.other_details.service_area_uuid);
        setRequests(result.data.data);
        hideLoader();
    }

    const columns = [
        {
            title: 'Requester\'s Name',
            dataIndex: 'user_uuid',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/delivery-partners/view/${row.user_uuid}`}>{row.first_name} {row.last_name}</Link>,
        },
        {
            title: 'Request Type',
            dataIndex: 'request_type',
            render: (text, row) => <span>{text}</span>
        },
        {
            title: 'Request Code',
            dataIndex: 'request_code',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/requests/view/${row.request_uuid}`}>{text}</Link>
        },
        {
            title: 'Amount',
            dataIndex: 'request_amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.request_amount),
        },
        {
            title: 'Status',
            render: (text, row) => GetStatusLabels('requests', row.request_status)
        }
    ];

    useEffect(() => {
        getRequests();
        return function cleanup() {
            getRequests();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='requests'>
            { loader }
            <h3 className="mb-4">Requests List</h3>
            {
                requests &&
                <Table rowKey="merchant_uuid" dataSource={requests} columns={columns} size={'middle'} className="bm-table" />
            }
        </PageLayout>
    )
}
