import React, { useEffect, useState } from 'react';
import { Table, Input } from 'antd';
import { Link } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetDeliveryPartners } from '../../../../services/deliverypartners';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';
import { ConvertToDecimal } from '../../../../functions/Math';

export default function DeliveryPartnersList({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [deliveryPartners, setDeliveryPartners] = useState(null);
    const [filterDeliveryPartners, setFilterDeliveryPartners] = useState(null);
    const { Search } = Input;

    const getDeliveryPartners = async() => {
        showLoader();
        const result = await GetDeliveryPartners(currentUser.other_details.service_area_uuid);
        setDeliveryPartners(result.data.data);
        hideLoader();
    }

    const filterTable = (value) => {
        const tempData = deliveryPartners.filter(o =>
            Object.keys(o).some(k =>
              String(o[k])
                .toLowerCase()
                .includes(value.toLowerCase())
            )
        );
        setFilterDeliveryPartners(tempData);
    }

    const columns = [
        {
            title: 'Delivery Partner Name',
            dataIndex: 'first_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/delivery-partners/view/${row.user_uuid}`}>{row.first_name} {row.last_name}</Link>,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_number'
        },
        {
            title: 'Email',
            dataIndex: 'email_address',
            render: text => <a className="text-link-primary-x3" href={`mailto:${text}`}>{text}</a>,
        },
        {
            title: 'License Number',
            dataIndex: 'license_number'
        },
        {
            title: 'Availability',
            render: (text, row) => GetStatusLabels('bool', row.is_available)
        },
        {
            title: 'On Duty',
            render: (text, row) => GetStatusLabels('bool', row.is_on_shift)
        },
        {
            title: 'Wallet Balance',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.wallet_balance),
        },
        {
            title: 'Status',
            render: (text, row) => GetStatusLabels('users', row.user_status)
        }
    ];

    useEffect(() => {
        getDeliveryPartners();
        return function cleanup() {
            getDeliveryPartners();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='delivery-partners'>
            { loader }
            <h3 className="mb-4">Delivery Partners List</h3>
            {
                deliveryPartners &&
                <div>
                    <div className="d-flex justify-content-end mb-3">
                        <Search className="bm-table-search" placeholder="search table" onSearch={filterTable} style={{ width: 200 }} />
                    </div>
                    <Table rowKey="merchant_uuid" dataSource={filterDeliveryPartners === null ? deliveryPartners : filterDeliveryPartners} columns={columns} size={'middle'} className="bm-table" />
                </div>
            }
        </PageLayout>
    )
}
