import axios from 'axios';

export const GetUserDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/users/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateUserDetails = async(values) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/users/' + values.user_uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const AddMerchantPartner = async(values) => {
    try {
        const userResult = await axios.post(process.env.REACT_APP_API_BASE_URL + '/users', values);
        if (userResult.data.status === 'success') {
            const merchantParterValues = {
                user_uuid: userResult.data.uuid,
                merchant_uuid: values.merchant_uuid,
                branch_uuid: values.branch_uuid,
                access_type_code: values.access_type_code
            }
            const merchantPartnerResult = await axios.post(process.env.REACT_APP_API_BASE_URL + '/merchant-partners', merchantParterValues);
            return merchantPartnerResult;
        } else {
            return userResult;
        }
    } catch (error) {
        throw error;
    }    
}

export const UpdateMerchantPartner = async(values) => {
    try {
        const userResult = await axios.put(process.env.REACT_APP_API_BASE_URL + '/users/' + values.user_uuid, values);
        if (userResult.data.status === 'success') {
            const merchantParterValues = {
                merchant_uuid: values.merchant_uuid,
                branch_uuid: values.branch_uuid,
                access_type_code: values.access_type_code
            }
            const merchantPartnerResult = await axios.put(process.env.REACT_APP_API_BASE_URL + '/merchant-partners/' + values.user_uuid, merchantParterValues);
            return merchantPartnerResult;
        } else {
            return userResult;
        }
    } catch (error) {
        throw error;
    }
}

export const UpdateDeliveryPartner = async(values) => {
    try {
        const userResult = await axios.put(process.env.REACT_APP_API_BASE_URL + '/users/' + values.user_uuid, values);
        if (userResult.data.status === 'success') {
            const deliveryPartnerValues = {
                service_area_uuid: values.service_area_uuid
            }
            const deliveryPartnerResult = await axios.put(process.env.REACT_APP_API_BASE_URL + '/delivery-partners/' + values.user_uuid, deliveryPartnerValues);
            return deliveryPartnerResult;
        } else {
            return userResult;
        }
    } catch (error) {
        throw error;
    }
}

export const AddPortalPersonnel = async(values) => {
    try {
        const userResult = await axios.post(process.env.REACT_APP_API_BASE_URL + '/users', values);
        if (userResult.data.status === 'success') {
            const portalPersonnelValues = {
                user_uuid: userResult.data.uuid,
                service_area_uuid: values.service_area_uuid
            }
            const portalPersonnelResult = await axios.post(process.env.REACT_APP_API_BASE_URL + '/portal-personnels', portalPersonnelValues);
            return portalPersonnelResult;
        } else {
            return userResult;
        }
    } catch (error) {
        throw error;
    }    
}

export const GetUsersRegistrationStats = async(dateFrom, dateTo) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/users/count/' + dateFrom + '/' + dateTo);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetUsersRegistrationDailyStats = async(dateFrom, dateTo) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/users/daily/' + dateFrom + '/' + dateTo);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetUserActiveStats = async(dateFrom, dateTo) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/users/active/' + dateFrom + '/' + dateTo);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetUserActiveLocations = async(dateFrom, dateTo) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/users/active/locations/' + dateFrom + '/' + dateTo);
        return result;
    } catch (error) {
        throw error;
    }
}