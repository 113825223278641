import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card, Button, Image } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetFeaturesDetails } from '../../../../services/features';
import usePageLoader from '../../../../hooks/usePageLoader';
import { useHistory } from 'react-router-dom';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function FeaturesView() {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [featureDetails, setFeatureDetails] = useState(null);
    const { uuid }  = useParams();
    const history = useHistory();
    
    const getFeaturesDetails = async() => {
        showLoader();
        const resultFeaturesDetails = await GetFeaturesDetails(uuid);
        setFeatureDetails(resultFeaturesDetails.data.data);
        setDisplay(true);
        hideLoader();
    }

    useEffect(() => {
        getFeaturesDetails();
        return function cleanup() {
            getFeaturesDetails();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='service-areas'>
            { loader }
            {
                display &&
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <Card title="Features Stats" className="bm-card mb-4 mb-md-0">
                            Stats
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card title="Features Details" className="bm-card mb-4 mb-md-0"
                            actions={[
                                <div className="text-end"><Button type="outline-primary" className="bm-btn" onClick={() => history.push({pathname: '/app/admin/service-areas/features/update/' + uuid, state: {featureDetails}})}>Update</Button></div>
                            ]}>
                            <div className="bm-static-container">
                                <div className="title">Feature Title</div>
                                <div className="display">{featureDetails.feature_title}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Image</div>
                                <div className="display"><Image className="bm-image-rounded" width={100} src={featureDetails.file_url} />,</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Status</div>
                                <div className="display">{GetStatusLabels('features', featureDetails.feature_status)}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
