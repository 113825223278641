import React, { useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import usePageLoader from '../../hooks/usePageLoader';

export default function Logout() {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const { Logout } = useAuth();
    const history = useHistory();

    const logoutHandler = async () => {
        showLoader();
        await Logout();
        hideLoader();
        history.push('/login');
    };

    useEffect(() => {
        logoutHandler();
        // eslint-disable-next-line
    }, []);

    return (
        <>{loader}</>
    )
}
