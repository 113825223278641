export const CategoryTypes = [
    {
        "id": 1,
        "code": "E001",
        "categoryType": "Starters"
    },
    {
        "id": 2,
        "code": "E002",
        "categoryType": "Small Mains"
    },
    {
        "id": 3,
        "code": "E003",
        "categoryType": "Big Mains"
    },
    {
        "id": 4,
        "code": "E004",
        "categoryType": "Side Dishes & Add Ons"
    },
    {
        "id": 5,
        "code": "E005",
        "categoryType": "Plates, Bundles & Boxes"
    },
    {
        "id": 6,
        "code": "E006",
        "categoryType": "Desserts"
    },
    {
        "id": 7,
        "code": "E007",
        "categoryType": "Pre-packaged Food"
    },
    {
        "id": 8,
        "code": "E008",
        "categoryType": "Drinks (Non-Alcoholic)"
    },
    {
        "id": 9,
        "code": "E009",
        "categoryType": "Drinks (Alcoholic)"
    },
    {
        "id": 10,
        "code": "E010",
        "categoryType": "Kid's Menu"
    },
    {
        "id": 11,
        "code": "E011",
        "categoryType": "Non-Food"
    },
    {
        "id": 12,
        "code": "E012",
        "categoryType": "Others"
    }
]
