import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMerchantBranches } from '../../../../services/merchants';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function BranchCategoriesList({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [merchantBranches, setMerchantBranches] = useState(null);
    
    const getMerchantBranches = async() => {
        showLoader();
         const result = await GetMerchantBranches(currentUser.other_details.merchant_uuid, null);
        setMerchantBranches(result.data.data);
        hideLoader();
    }

    const columns = [
        {
            title: 'Branch Name',
            dataIndex: 'branch_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/merchants/branch-categories/view/${row.branch_uuid}`}>{text}</Link>,
        },
        {
            title: 'Branch Code',
            dataIndex: 'branch_code',
        },
        {
            title: 'Contact Person',
            dataIndex: 'contact_person',
        },
        {
            title: 'Status',
            width: 110,
            dataIndex: 'branch_status',
            render: text => GetStatusLabels('branches', parseInt(text))
        }
    ];

    useEffect(() => {
        getMerchantBranches();
        return function cleanup() {
            getMerchantBranches();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='branch-categories'>
            { loader }
            <h3 className="mb-4">Select Branch</h3>
            {
                merchantBranches &&
                <Table rowKey="merchant_uuid" dataSource={merchantBranches} columns={columns} size={'middle'} className="bm-table" pagination={false} />
            }
        </PageLayout>
    )
}
