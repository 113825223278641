export const Regions = [
    {
        "id": 1,
        "code": "NCR",
        "regionName": "National Capital Region"
    },
    {
        "id": 2,
        "code": "CAR",
        "regionName": "Cordillera Administrative Region"
    },
    {
        "id": 3,
        "code": "RI",
        "regionName": "Ilocos Region"
    },
    {
        "id": 4,
        "code": "RII",
        "regionName": "Cagayan Valley"
    },
    {
        "id": 5,
        "code": "RIII",
        "regionName": "Central Luzon"
    },
    {
        "id": 6,
        "code": "RIVA",
        "regionName": "Calabarzon"
    },
    {
        "id": 7,
        "code": "RIVB",
        "regionName": "Mimaropa"
    },
    {
        "id": 8,
        "code": "RV",
        "regionName": "Bicol Region"
    },
    {
        "id": 9,
        "code": "RVI",
        "regionName": "Western Visayas"
    },
    {
        "id": 10,
        "code": "RVII",
        "regionName": "Central Visayas"
    },
    {
        "id": 11,
        "code": "RVIII",
        "regionName": "Eastern Visayas"
    },
    {
        "id": 12,
        "code": "RIX",
        "regionName": "Zamboanga Peninsula"
    },
    {
        "id": 13,
        "code": "RX",
        "regionName": "Northern Mindanao"
    },
    {
        "id": 14,
        "code": "RXI",
        "regionName": "Davao Region"
    },
    {
        "id": 15,
        "code": "RXII",
        "regionName": "Soccsksargen"
    },
    {
        "id": 16,
        "code": "RXIII",
        "regionName": "Caraga"
    },
    {
        "id": 17,
        "code": "BARMM",
        "regionName": "Bangsamoro"
    }
]
