import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Row, Col, Card, Button, Table, Image } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import Checked from '../../../../components/icons/Checked';
import { GetItemDetails } from '../../../../services/items';
import { GetItemsVariations } from '../../../../services/itemsvariations';
import { ConvertToDecimal } from '../../../../functions/Math';
import usePageLoader from '../../../../hooks/usePageLoader';
import DefaultItemPhoto from '../../../../assets/img/default-item-photo.png';
import { GetFile } from '../../../../services/files';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function ItemsView() {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [itemDetails, setItemDetails] = useState(null);
    const [itemVariations, setItemVariations] = useState(null);
    const [itemPhotoDetails, setItemPhotoDetails] = useState(null);
    const [itemPhotoUrl, setItemPhotoUrl] = useState(DefaultItemPhoto);
    const history = useHistory();
    const { uuid }  = useParams();
    
    const getItemDetails = async() => {
        showLoader();
        const itemDetailsResult = await GetItemDetails(uuid);
        const variationsResult = await GetItemsVariations(uuid);
        setItemDetails(itemDetailsResult.data.data);
        setItemVariations(variationsResult.data.data)
        if (itemDetailsResult.data.data.item_photo_file_uuid !== '') {
            const itemPhotoDetailsResult = await GetFile(itemDetailsResult.data.data.item_photo_file_uuid);
            setItemPhotoDetails(itemPhotoDetailsResult.data.data);
            setItemPhotoUrl(itemPhotoDetailsResult.data.data.file_url);
        }
        setDisplay(true);
        hideLoader();
    }

    const variationColumns = [
        {
            title: 'Variation Name',
            dataIndex: 'variation_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={{pathname: `/app/merchants/items/variations/view/${row.variation_uuid}`, state: {itemDetails}}} title="view variation details"><span className="d-flex align-items-center bestseller-icon-wrapper">{text} {(row.is_bestseller === 1) ? <Checked /> : ''}</span></Link>,
        },
        {
            title: 'Price',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.variation_price),
        },
        {
            title: 'Status',
            width: 110,
            dataIndex: 'variation_status',
            render: text => GetStatusLabels('variations', parseInt(text))
        }
    ]

    useEffect(() => {
        getItemDetails();
        return function cleanup() {
            getItemDetails();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='items'>
            { loader }
            {
                display && 
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <Card title="Stats" className="bm-card mb-4">
                            Stats soon
                        </Card>
                        <div>
                            <div className="d-flex w-100">
                                <h3 className="mb-4">Item Variations</h3>
                                <div className="ms-auto buttons">
                                    <Button type="primary" className="bm-btn" onClick={() => history.push({pathname: '/app/merchants/items/variations/add/' + uuid, state: {item: itemDetails}})}>Add Variation</Button>
                                </div>
                            </div>
                            <Table rowKey="menu_uuid" dataSource={itemVariations} columns={variationColumns} size={'middle'} className="bm-table" />
                        </div>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card   title="Item Details"
                                className="bm-card mb-4 mb-md-0"
                                actions={[
                                    <div className="text-end"><Button type="outline-primary" className="bm-btn" onClick={() => history.push({pathname: '/app/merchants/items/update/' + uuid, state: {itemDetails, itemPhotoDetails}})}>Update</Button></div>
                                ]}>
                            <div className="bm-static-container">
                                <div className="title mb-2">Item Photo</div>
                                <div className="display">
                                    <Image width={150} src={itemPhotoUrl} className="bm-image-rounded" />
                                </div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Item Name</div>
                                <div className="display">{itemDetails.item_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Description</div>
                                <div className="display">{itemDetails.item_description}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Status</div>
                                <div className="display">{GetStatusLabels('items', itemDetails.item_status)}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
