import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Input, InputNumber, Button, Alert, Select, DatePicker } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetServiceAreas } from '../../../../services/serviceareas';
import { GetCustomers, GetCustomersNoTxns } from '../../../../services/customers';
import { SendSms } from '../../../../services/sms';
import { GetMerchantPartners } from '../../../../services/merchantpartners';
import { GetDeliveryPartners } from '../../../../services/deliverypartners';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';

export default function SmsSend() {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [serviceAreas, setServiceAreas] = useState(null);
    const [serviceArea, setServiceArea] = useState(null);
    const [audience, setAudience] = useState(null);
    const [success, setSuccess] = useState(null);
    const history = useHistory();
    const { TextArea } = Input;
    const { Option } = Select;
    const [form] = Form.useForm();
    
    const getPageData = async() => {
        showLoader();
        const serviceAreaResult = await GetServiceAreas();
        setServiceAreas(serviceAreaResult.data.data);
        hideLoader();
    }

    const updateAudience = async(group) => {
        showLoader();
        setAudience(null);
        if (serviceArea === null || serviceArea === '') {
            Debounce(NotificationManager.warning('Please select a service area.', 'Sorry', 2000), 2000);
            form.resetFields(["group"]);
        } else {
            let audienceResult;
            switch(group) {
                case 'customers-active':
                    audienceResult = await GetCustomers(null, 1000);
                    break;
                case 'customers-no-txns':
                    audienceResult = await GetCustomersNoTxns();
                    break;
                case 'customers-with-txns':
                    audienceResult = await GetCustomers(serviceArea, 1000);
                    break;
                case 'merchants-active':
                    audienceResult = await GetMerchantPartners(serviceArea);
                    break;
                case 'delivery-active':
                    audienceResult = await GetDeliveryPartners(serviceArea);
                    break;
            }
            setAudience(audienceResult.data.data);
        }
        hideLoader();
    }

    const toLower = (value) => {
        return value.toLowerCase();
    }

    const submitHandler = async(values) => {
        showLoader();
        let targetNumbers;
        let numbers = [];
        if (values.audience === 'all') {  
            audience.map(item => {
                numbers.push(item.mobile_number);
            });
            // split to 300 items per chunk
            const chunkCount = 300;
            targetNumbers = numbers.reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index/chunkCount);
                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = [] // start a new chunk
                }
                resultArray[chunkIndex].push(item);
                return resultArray;
            }, []);
        } else {
            let numbers = [];
            let selected = values.audience;
            selected = selected.split('|');
            numbers.push(selected[0]);
            targetNumbers = [numbers];
        }
        targetNumbers.forEach(function(item, index) {
            (async() => {
                try {
                    let mobileNumbers = item.join(';');
                    const sendResult = await SendSms(mobileNumbers, values.message);
                    if (sendResult === '' || sendResult === 2000 || sendResult === true) {
                        form.resetFields();
                        Debounce(NotificationManager.success('Message sent.', 'Success', 2000), 2000);
                        setSuccess(true);
                    }
                } catch (e) {
                    Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
                    console.log(e.message)
                    hideLoader();
                }
            })();
        });
        hideLoader();
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    useEffect(() => {
        getPageData();
        return function cleanup() {
            getPageData();
        }
        // eslint-disable-next-line
    }, [])

    return (
        <PageLayout activeLink='sms'>
            { loader }
            {
                <Row>
                    <Col xs={24} md={{span: 12, offset: 6}}>
                        <Card title="Send Short Message" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Message Sent"
                                    description="Message has been sent successfully."
                                    type="success"
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler} form={form}>
                                <Form.Item label="Service Area"
                                    name="service_area"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a service area.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select" onChange={(value) => setServiceArea(value)}>
                                        <Option value="">select one</Option>
                                        {
                                            serviceAreas && serviceAreas.map(item => {
                                                return <Option value={item.service_area_uuid}>{item.service_area_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Group"
                                    name="group"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select audience group.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select" onChange={(value) => updateAudience(value)}>
                                        <Option value="">select one</Option>
                                        <Option value="customers-active">Customers - All Active</Option>
                                        <Option value="customers-no-txns">Customers - No Transactions</Option>
                                        <Option value="customers-with-txns">Customers - With Transactions</Option>
                                        <Option value="merchants-active">Merchant Partners - All Active</Option>
                                        <Option value="delivery-active">Delivery Partners - All Active</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Audience"
                                    name="audience"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select audience.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select" allowClear={true} showSearch
                                        filterOption={(input, option) =>
                                            toLower(option.value).includes(toLower(input))
                                        }>
                                        <Option value=''>select one</Option>
                                        {
                                            audience && audience.length > 0 &&
                                            <Option value="all"><strong>All Contacts</strong></Option>
                                        }
                                        {
                                            audience && audience.map(item => {
                                                return <Option value={item.mobile_number + '|' + item.first_name + item.last_name}>{item.first_name} {item.last_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Message"
                                    name="message"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter message.',
                                        },
                                    ]}>
                                    <TextArea rows={4} placeholder="message" maxLength={360} />
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
