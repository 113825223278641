import axios from 'axios';

export const GetSpotlights = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/spotlights/service-area/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetSpotlightsActive = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/spotlights/service-area/' + uuid + '/active');
        return result;
    } catch (error) {
        throw error;
    }
}

export const AddSpotlights = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/spotlights', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetSpotlightsDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/spotlights/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateSpotlights = async (values) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/spotlights/' + values.spotlight_uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}