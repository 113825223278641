import React, { useState } from 'react';
import { Row, Col, Card, Form, Input, Button, Alert, Select, InputNumber } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { UpdateRequest } from '../../../../services/requests';
import { AddWalletTransaction } from '../../../../services/wallets';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';

export default function AddTransactions() {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const { Option } = Select;
    const history = useHistory();
    const { uuid }  = useParams();
    const userDetails = history.location.state.userDetails;
    const orders = history.location.state.orders;
    
    const submitHandler = async (values) => {
        showLoader();
        try {
            const formattedValues = {
                ...values,
                transaction_amount: values.transaction_amount * 100,
                user_uuid: uuid,
                wallet_transaction_status: 1
            }
            const walletResult = await AddWalletTransaction(formattedValues);
            switch(walletResult.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Wallet transaction added successfully.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    return (
        <PageLayout activeLink='delivery-partners'>
            { loader }
            {
                <Row>
                    <Col xs={24} md={{span: 12, offset: 6}}>
                        <Card title="Add Wallet Transaction" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Transaction Added"
                                    description="Wallet transaction has been added successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.push('/app/admin/delivery-partners/view/' + uuid)}>
                                            Back
                                        </Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}>
                                <Form.Item label="Owner of Wallet"
                                    name="wallet_owner">
                                    <label>{userDetails.first_name} {userDetails.last_name}</label>
                                </Form.Item>
                                <Form.Item label="Tracking No"
                                    name="reference_uuid"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select order tracking number.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select">
                                        <Option value="">select one</Option>
                                        {
                                            orders &&
                                            orders.map(item => {
                                                return <Option value={item.order_uuid}>{item.order_tracking_no} ({item.branch_name})</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Transaction Type"
                                    name="transfer_type_code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select transaction type.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select">
                                        <Option value="">select one</Option>
                                        <Option value="I001">Payment</Option>
                                        <Option value="I002">Transfer</Option>
                                        <Option value="I003">Fee</Option>
                                        <Option value="I004">Refund</Option>
                                        <Option value="I005">Rollback</Option>
                                        <Option value="I006">Adjustment</Option>
                                        <Option value="I007">Rebate</Option>
                                        <Option value="I008">Prefund</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Movement"
                                    name="movement_type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select movement type.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select">
                                        <Option value="">select one</Option>
                                        <Option value="credit">Credit</Option>
                                        <Option value="debit">Debit</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Amount"
                                    name="transaction_amount"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter amount.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0.00" precision={2} step={.01} />
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
