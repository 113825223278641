import React, { useEffect, useState } from 'react';
import PageLayout from '../../../../components/layout/PageLayout';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Statistic, Divider, Button } from 'antd';
import { GetOrdersStats } from '../../../../services/orders';
import { GetUsersRegistrationStats, GetUserActiveStats } from '../../../../services/users';
import moment from 'moment';

export default function AdminDashboard({currentUser}) {
    const [ordersStats, setOrdersStats] = useState({today: 0, yesterday: 0, pastWeek: 0, pastMonth: 0});
    const [registrationsStats, setRegistrationsStats] = useState({today: 0, pastWeek: 0, pastMonth: 0, total: 0});
    const [activeStats, setActiveStats] = useState({today: 0, yesterday: 0, pastWeek: 0, pastMonth: 0});
    const history = useHistory();

    const getPageData = async() => {
        const dateStart = '2021-01-01';
        const dateToday = moment().format('YYYY-MM-DD');
        const dateYesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');
        const dateWeek = moment().subtract(7, 'days').format('YYYY-MM-DD');
        const dateMonth = moment().subtract(30, 'days').format('YYYY-MM-DD');
        const serviceArea = (currentUser.other_details.service_area_uuid) ? currentUser.other_details.service_area_uuid : 'all';

        Promise.all([
            GetOrdersStats(dateToday, dateToday, serviceArea),
            GetOrdersStats(dateYesterday, dateYesterday, serviceArea),
            GetOrdersStats(dateWeek, dateToday, serviceArea),
            GetOrdersStats(dateMonth, dateToday, serviceArea)
        ]).then(result => {
            setOrdersStats(state => ({
                today: result[0].data.data,
                yesterday: result[1].data.data,
                pastWeek: result[2].data.data,
                pastMonth: result[3].data.data,
            }));
        });

        Promise.all([
            GetUsersRegistrationStats(dateToday, dateToday),
            GetUsersRegistrationStats(dateWeek, dateToday),
            GetUsersRegistrationStats(dateMonth, dateToday),
            GetUsersRegistrationStats(dateStart, dateToday)
        ]).then(result => {
            setRegistrationsStats(state => ({
                today: result[0].data.data,
                pastWeek: result[1].data.data,
                pastMonth: result[2].data.data,
                total: result[3].data.data,
            }));
        });

        Promise.all([
            GetUserActiveStats(dateToday, dateToday),
            GetUserActiveStats(dateYesterday, dateYesterday),
            GetUserActiveStats(dateWeek, dateToday),
            GetUserActiveStats(dateMonth, dateToday)
        ]).then(result => {
            setActiveStats(state => ({
                today: result[0].data.data,
                yesterday: result[1].data.data,
                pastWeek: result[2].data.data,
                pastMonth: result[3].data.data,
            }));
        });
    }

    useEffect(() => {
        getPageData();
        return function cleanup() {
            getPageData();
        }
    }, []);

    return (
        <PageLayout activeLink='dashboard'>
            <h4 className="mb-4">Dashboard</h4>
            {
                currentUser.other_details.service_area_uuid &&
                <div>
                    <div className="d-flex w-100">
                        <h3 className="mb-4">Orders</h3>
                        <div className="ms-auto buttons">
                            <Button type="primary" className="bm-btn" onClick={() => history.push('/app/admin/trends-orders')}>Order Trends</Button>
                        </div>
                    </div>
                    <Row gutter={24}>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Today" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={ordersStats.today} />
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Yesterday" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={ordersStats.yesterday} />
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Past 7 Days" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={ordersStats.pastWeek} />
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Past 30 Days" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={ordersStats.pastMonth} />
                            </Card>
                        </Col>
                    </Row>
                </div>
            }

            {
                !currentUser.other_details.service_area_uuid &&
                <div>
                    <div className="d-flex w-100">
                        <h3 className="mb-4">Orders</h3>
                        <div className="ms-auto buttons">
                            <Button type="primary" className="bm-btn" onClick={() => history.push('/app/admin/trends-orders')}>Order Trends</Button>
                        </div>
                    </div>
                    <Row gutter={24}>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Today" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={ordersStats.today} />
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Yesterday" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={ordersStats.yesterday} />
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Past 7 Days" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={ordersStats.pastWeek} />
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Past 30 Days" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={ordersStats.pastMonth} />
                            </Card>
                        </Col>
                    </Row>

                    <Divider />

                    <div className="d-flex w-100">
                        <h3 className="mb-4">Registrations</h3>
                        <div className="ms-auto buttons">
                            <Button type="primary" className="bm-btn" onClick={() => history.push('/app/admin/trends-registrations')}>Registration Trends</Button>
                        </div>
                    </div>
                    <Row gutter={24}>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Today" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={registrationsStats.today} />
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Past 7 Days" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={registrationsStats.pastWeek} />
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Past 30 Days" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={registrationsStats.pastMonth} />
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Total" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={registrationsStats.total} />
                            </Card>
                        </Col>
                    </Row>

                    <Divider />

                    <div className="d-flex w-100">
                        <h3 className="mb-4">Active Users</h3>
                        <div className="ms-auto buttons">
                            <Button type="primary" className="bm-btn" onClick={() => history.push('/app/admin/active-users-map')}>Map</Button>
                        </div>
                    </div>
                    <Row gutter={24}>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Today" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={activeStats.today} />
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Yesterday" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={activeStats.yesterday} />
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Past 7 Days" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={activeStats.pastWeek} />
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Card size="small" title="Past 30 Days" className="bm-card mb-4 mb-md-0">
                                <Statistic title="" value={activeStats.pastMonth} />
                            </Card>
                        </Col>
                    </Row>
                </div>
            }
        </PageLayout>
    )
    
}
