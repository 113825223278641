import React, { useEffect, useState } from 'react';
import { Table, Input } from 'antd';
import { Link } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMerchantPartners } from '../../../../services/merchantpartners';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function MerchantPartnersList({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [merchantPartners, setMerchantPartners] = useState(null);
    const [filterMerchantPartners, setFilterMerchantPartners] = useState(null);
    const { Search } = Input;

    const getMerchantPartners = async() => {
        showLoader();
        const result = await GetMerchantPartners(currentUser.other_details.service_area_uuid);
        setMerchantPartners(result.data.data);
        hideLoader();
    }

    const filterTable = (value) => {
        const tempData = merchantPartners.filter(o =>
            Object.keys(o).some(k =>
              String(o[k])
                .toLowerCase()
                .includes(value.toLowerCase())
            )
        );
        setFilterMerchantPartners(tempData);
    }

    const columns = [
        {
            title: 'Service Area',
            render: (text, row) => row.service_area_name,
        },
        {
            title: 'Merchant Partner Name',
            dataIndex: 'first_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/merchant-partners/view/${row.user_uuid}`}>{row.first_name} {row.last_name}</Link>,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_number',
        },
        {
            title: 'Email',
            dataIndex: 'user_email_address',
            render: text => <a className="text-link-primary-x3" href={`mailto:${text}`}>{text}</a>,
        },
        {
            title: 'Merchant Name',
            dataIndex: 'merchant_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/merchants/view/${row.merchant_uuid}`}>{text}</Link>,
        },
        {
            title: 'Status',
            render: (text, row) => GetStatusLabels('users', row.user_status),
        }
    ];

    useEffect(() => {
        getMerchantPartners();
        return function cleanup() {
            getMerchantPartners();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='merchant-partners'>
            { loader }
            <h3 className="mb-4">Merchants List</h3>
            {
                merchantPartners &&
                <div>
                    <div className="d-flex justify-content-end mb-3">
                        <Search className="bm-table-search" placeholder="search table" onSearch={filterTable} style={{ width: 200 }} />
                    </div>
                    <Table rowKey="merchant_uuid" dataSource={filterMerchantPartners === null ? merchantPartners : filterMerchantPartners} columns={columns} size={'middle'} className="bm-table" />
                </div>
                
            }
        </PageLayout>
    )
}
