import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Card, Button } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMerchantPartnerDetails } from '../../../../services/merchantpartners';
import { GetUserDetails } from '../../../../services/users';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function UsersView() {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [merchantPartnerDetails, setMerchantPartnerDetails] = useState(null);
    const [userDetails, setSetUserDetails] = useState(null);
    const { uuid }  = useParams();
    const history = useHistory();
    
    const getUserDetails = async() => {
        showLoader();
        const resultMerchantPartnerDetails = await GetMerchantPartnerDetails(uuid);
        const resultUserDetails = await GetUserDetails(uuid);
        setMerchantPartnerDetails(resultMerchantPartnerDetails.data.data);
        setSetUserDetails(resultUserDetails.data.data);
        setDisplay(true);
        hideLoader();
    }

    useEffect(() => {
        getUserDetails();
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='users'>
            { loader }
            {
                display && 
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <Card title="Stats" className="bm-card mb-4 mb-md-0">
                            Stats soon
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card title="User Details" className="bm-card mb-4 mb-md-0"
                            actions={[
                                <div className="text-end"><Button type="outline-primary" className="bm-btn" onClick={() => history.push({pathname: '/app/merchants/users/update/' + uuid, state: {userDetails, merchantPartnerDetails}})}>Update</Button></div>
                            ]}>
                            <div className="bm-static-container">
                                <div className="title">First Name</div>
                                <div className="display">{userDetails.first_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Last Name</div>
                                <div className="display">{userDetails.last_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Email Address</div>
                                <div className="display"><a href={`mailto:${userDetails.email_address}`} className="text-link-primary-x3">{userDetails.email_address}</a></div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Mobile Number</div>
                                <div className="display">{userDetails.mobile_number}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Access Type</div>
                                <div className="display">{GetStatusLabels('access-types', merchantPartnerDetails.access_type_code)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">User Status</div>
                                <div className="display">{GetStatusLabels('users', parseInt(userDetails.user_status))}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
