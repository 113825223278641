import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Transfer, Table, Row, Col, Card, Button, Image } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMenuDetails } from '../../../../services/menus';
import { GetMenuCategoriesActive, AddMenuCategories, UpdateMenuCategories } from '../../../../services/menucategories';
import { GetBranchCategoriesActive } from '../../../../services/branchcategories';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';
import moment from 'moment';
import DefaultBannerPhoto from '../../../../assets/img/default-banner-photo.png';
import { GetFile } from '../../../../services/files';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function MenusView({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [branchCategories, setBranchCategories] = useState(null);
    const [menuCategories, setMenuCategories] = useState(null);
    const [menuCategoriesTable, setMenuCategoriesTable] = useState(null);
    const [menuDetails, setMenuDetails] = useState(null);
    const [menuBannerDetails, setMenuBannerDetails] = useState(null);
    const [menuBannerUrl, setMenuBannerUrl] = useState(DefaultBannerPhoto);
    const { uuid }  = useParams();
    const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
    const SortableItem = sortableElement(props => <tr {...props} />);
    const SortableContainer = sortableContainer(props => <tbody {...props} />);
    const history = useHistory();

    const handleOnChange = (nextTargetKeys, direction, moveKeys) => {
        setMenuCategories(nextTargetKeys);
        submitHandler({direction, moveKeys});
    };

    const submitHandler = async ({direction, moveKeys}) => {
        showLoader();
        try {
            switch (direction) {
                case 'right':
                    Promise.all(moveKeys.map(async (item) => {
                        const formattedValues = {
                            menu_uuid: uuid,
                            branch_category_uuid: item,
                            display_order: menuCategoriesTable.length + 1,
                            menu_category_status: 1
                        }
                        const result = await AddMenuCategories(formattedValues);
                        switch(result.data.status) {
                            case 'success':
                                setMenuCategories(array => [...array, result.data.menu_category_uuid]);
                                getCategories();
                                Debounce(NotificationManager.success('Menu category has been added.', 'Success', 2000), 2000);
                                break;
                            default:
                                Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                                break;
                        }
                    }));
                    break;
                case 'left':                    
                    Promise.all(moveKeys.map(async (item) => {
                        const formattedValues = {
                            menu_uuid: uuid,
                            branch_category_uuid: item,
                            menu_category_status: 0
                        }
                        const result = await UpdateMenuCategories(formattedValues);
                        switch(result.data.status) {
                            case 'success':
                                getCategories();
                                Debounce(NotificationManager.success('Menu category has been removed.', 'Success', 2000), 2000);
                                break;
                            default:
                                Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                                break;
                        }
                    }));
                    break;
                default:
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const columns = [
        {
            title: 'Sort',
            dataIndex: 'sort',
            width: 60,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: 'Category Name',
            dataIndex: 'category_name'
        },
        {
            title: 'Status',
            width: 110,
            dataIndex: 'menu_category_status',
            render: text => GetStatusLabels('categories', parseInt(text))
        }
    ];

    useEffect(() => {
        getCategories();
        return function cleanup() {
            getCategories();
        }
        // eslint-disable-next-line
    }, []);

    const getCategories = async() => {
        showLoader();
        const menuDetailsResult = await GetMenuDetails(uuid);
        const menuCategoriesResult = await GetMenuCategoriesActive(uuid);
        const branchCategoriesResult = await GetBranchCategoriesActive(menuDetailsResult.data.data.branch_uuid);
        const branchCategoriesFormatted = [];
        branchCategoriesResult.data.data.forEach(function(item, index) {
            branchCategoriesFormatted.push({
                'key': item.branch_category_uuid,
                'category_name': item.category_name
            });
        });
        const menuCategoriesFormatted = [];
        const menuCategoriesTableFormatted = [];
        menuCategoriesResult.data.data.forEach(function(item, index) {
            menuCategoriesFormatted.push(item.branch_category_uuid);
            menuCategoriesTableFormatted.push({
                'key': item.menu_category_uuid,
                'menu_uuid': uuid,
                'branch_category_uuid': item.branch_category_uuid,
                'category_name': item.category_name,
                'menu_category_status': item.menu_category_status,
                'index': index
            });
        });
        if (menuDetailsResult.data.data.banner_photo_file_uuid !== '') {
            // get merchant logo
            const menuBannerResult = await GetFile(menuDetailsResult.data.data.banner_photo_file_uuid);
            setMenuBannerDetails(menuBannerResult.data.data);
            setMenuBannerUrl(menuBannerResult.data.data.file_url);
        }
        setBranchCategories(branchCategoriesFormatted);
        setMenuCategories(menuCategoriesFormatted);
        setMenuDetails(menuDetailsResult.data.data);
        setMenuCategoriesTable(menuCategoriesTableFormatted);
        hideLoader();
    }

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={handleSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({className, style, ...restProps}) => {
        const dataSource = menuCategoriesTable;
        const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const handleSortEnd = ({oldIndex, newIndex}) => {
        const dataSource = menuCategoriesTable;
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            setMenuCategoriesTable(newData);
            updateOrder(newData);
        }
    }

    const updateOrder = (values) => {
        showLoader();
        Promise.all(values.map(async (item, key) => {
            const formattedValues = {
                menu_uuid: item.menu_uuid,
                branch_category_uuid: item.branch_category_uuid,
                display_order: key + 1
            }
            return await UpdateMenuCategories(formattedValues);
        })).then(result => {
            if (values.length === result.length) {
                getCategories();
                Debounce(NotificationManager.success('Menu category has been updated.', 'Success', 2000), 2000);
            } else {
                Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
            }
            hideLoader();
        });
    }

    return (
        <PageLayout activeLink='menus'>
            { loader }
            <div className="d-flex w-100">
                <h3 className="mb-4">Menus Details</h3>
                <div className="ms-auto buttons">
                    <Button type="primary" className="bm-btn" onClick={() => history.push({pathname: '/app/merchants/menus/configure', state: {menuDetails, menuCategories: menuCategoriesTable}})}>Configure Menu</Button>
                </div>
            </div>
            {
                menuCategoriesTable &&
                <div>
                    <Transfer
                        className="bm-transfer"
                        oneWay
                        dataSource={branchCategories}
                        titles={['Branch Categories', 'Menu Categories']}
                        targetKeys={menuCategories}
                        onChange={handleOnChange}
                        render={item => item.category_name}
                    />
                    <h3 className="my-4">Category Display Order</h3>
                    <Row gutter={24}>
                        <Col xs={24} md={16}>
                            <Table rowKey="index"
                                dataSource={menuCategoriesTable}
                                columns={columns}
                                size={'middle'}
                                className="bm-table mb-4"
                                components={{
                                    body: {
                                        wrapper: DraggableContainer,
                                        row: DraggableBodyRow
                                    },
                                }}
                                pagination={false}
                            />
                        </Col>
                        <Col xs={24} md={8}>
                            <Card title="Menu Details" className="bm-card mb-4 mb-md-0"
                                actions={[
                                    <div className="text-end"><Button type="outline-primary" className="bm-btn" onClick={() => history.push({pathname: '/app/merchants/menus/update/' + uuid, state: {menuDetails, menuBannerDetails}})}>Update</Button></div>
                                ]}>
                                <div className="bm-static-container">
                                    <div className="title mb-2">Banner Photo</div>
                                    <div className="display">
                                        <Image width={150} src={menuBannerUrl} className="bm-image-rounded" />
                                    </div>
                                </div>
                                <div className="bm-static-container">
                                    <div className="title">Menu Name</div>
                                    <div className="display">{menuDetails.menu_name}</div>
                                </div>
                                <div className="bm-static-container">
                                    <div className="title">Description</div>
                                    <div className="display">{menuDetails.menu_description}</div>
                                </div>
                                <div className="bm-static-container">
                                    <div className="title">Availability From</div>
                                    <div className="display">{moment(menuDetails.availability_time_from, 'HH:mm:ss').format('hh:mm:ss A')}</div>
                                </div>
                                <div className="bm-static-container">
                                    <div className="title">Availability To</div>
                                    <div className="display">{moment(menuDetails.availability_time_to, 'HH:mm:ss').format('hh:mm:ss A')}</div>
                                </div>
                                <div className="bm-static-container">
                                    <div className="title">Status</div>
                                    <div className="display">{GetStatusLabels('menus', parseInt(menuDetails.menu_status))}</div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            }
        </PageLayout>
    )
}
