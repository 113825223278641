import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Row, Col, Card, Button, Table, Divider } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetUserDetails } from '../../../../services/users';
import { GetUserOrders } from '../../../../services/orders';
import { GetUserWallets } from '../../../../services/wallets';
import usePageLoader from '../../../../hooks/usePageLoader';
import { DisplayDateTime } from '../../../../functions/Utilities';
import { useHistory } from 'react-router-dom';
import { GetStatusLabels } from '../../../../functions/Labels';
import { ConvertToDecimal } from '../../../../functions/Math';

export default function DeliveryPartnersView() {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [userDetails, setUserDetails] = useState(null);
    const [orders, setOrders] = useState(null);
    const [wallets, setWallets] = useState(null);
    const { uuid }  = useParams();
    const history = useHistory();
    
    const getDeliveryPartnerDetails = async() => {
        showLoader();
        Promise.all([
            GetUserDetails(uuid),
            GetUserOrders(uuid, 50),
            GetUserWallets(uuid, 50)
        ]).then(result => {
            setUserDetails(result[0].data.data);
            setUserDetails(currentState => ({...currentState, other_details: result[0].data.data.other_details[0]}));
            setOrders(result[1].data.data);
            setWallets(result[2].data.data);
            setDisplay(true);
            hideLoader();
        });
    }

    const ordersColumns = [
        {
            title: 'Tracking No',
            dataIndex: 'order_tracking_no',
            render: (text, row) => <Link className="text-link-primary-x3" to={{ pathname: `/app/admin/orders/view/${row.order_uuid}`, state: {orderDetails: row}}}>{text}</Link>,
        },
        {
            title: 'Merchant Due',
            dataIndex: 'order_merchant_due_amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_merchant_due_amount),
        },
        {
            title: 'Delivery Fee',
            dataIndex: 'order_delivery_due_amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_delivery_due_amount),
        },
        {
            title: 'Platform',
            dataIndex: 'order_admin_due_amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_admin_due_amount),
        },
        {
            title: 'Order Amount',
            dataIndex: 'order_total_amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_total_amount),
        },
        {
            title: 'Voucher',
            dataIndex: 'voucher_uuid',
            render: (text, row) => row.voucher_uuid === null ? '' : <Link className="text-link-primary-x3" to={{ pathname: `/app/admin/vouchers/view/${row.voucher_uuid}`}}>{row.voucher_code}</Link>,
        },
        {
            title: 'Free Delivery',
            render: (text, row) => GetStatusLabels('bool', row.charge_is_free_delivery),
        },
        {
            title: 'Date & Time',
            render: (text, row) => <span>{DisplayDateTime(row.created_at)}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'order_status',
            width: 120,
            render: text => GetStatusLabels('orders', parseInt(text))
        }
    ];

    const walletsColumns = [
        {
            title: 'Transaction Type',
            render: (text, row) => GetStatusLabels('wallets-transactions', row.transfer_type_code),
        },
        {
            title: 'Starting Balance',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.wallet_balance_before),
        },
        {
            title: 'Credit',
            align: 'right',
            render: (text, row) => row.movement_type === 'credit' ? 'P' + ConvertToDecimal(row.transaction_amount) : '-',
        },
        {
            title: 'Debit',
            align: 'right',
            render: (text, row) => row.movement_type === 'debit' ? '-P' + ConvertToDecimal(row.transaction_amount) : '-',
        },
        {
            title: 'Ending Balance',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.wallet_balance_after),
        },
        {
            title: 'Date & Time',
            render: (text, row) => <span>{DisplayDateTime(row.created_at)}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'wallet_transaction_status',
            width: 120,
            render: text => GetStatusLabels('wallets', parseInt(text))
        }
    ];

    useEffect(() => {
        getDeliveryPartnerDetails();
        return function cleanup() {
            getDeliveryPartnerDetails();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='delivery-partners'>
            { loader }
            {
                display &&
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <Card title="Delivery Partner Stats" className="bm-card mb-4 mb-md-0">
                            Stats
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card title="Delivery Partner Details" className="bm-card mb-4 mb-md-0"
                            actions={[
                                <div className="text-end"><Button type="outline-primary" className="bm-btn" onClick={() => history.push({pathname: '/app/admin/delivery-partners/update/' + uuid, state: {userDetails}})}>Update</Button></div>
                            ]}>
                            <div className="bm-static-container">
                                <div className="title">Wallet Balance</div>
                                <div className="display"><h2>P{ConvertToDecimal(userDetails.other_details.wallet_balance)}</h2></div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">First Name</div>
                                <div className="display">{userDetails.first_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Last Name</div>
                                <div className="display">{userDetails.last_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Mobile Number</div>
                                <div className="display">{userDetails.mobile_number}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Email</div>
                                <div className="display"><a href={`mailto:${userDetails.email_address}`} className="text-link-primary-x3">{userDetails.email_address}</a></div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">License Number</div>
                                <div className="display">{userDetails.other_details.license_number}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">License Type</div>
                                <div className="display">{userDetails.other_details.license_type}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">License Restrictions</div>
                                <div className="display">{userDetails.other_details.license_restrictions}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Service Area</div>
                                <div className="display">{userDetails.other_details.service_area_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Status</div>
                                <div className="display">{GetStatusLabels('users', userDetails.user_status)}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
            <h3 className="mb-4 mt-5">Assigned Orders (Latest 50)</h3>
            {
                orders &&
                <Table rowKey="order_uuid" dataSource={orders} columns={ordersColumns} size={'middle'} className="bm-table" />
            }
            <Divider className="my-5" />
            <div className="d-flex w-100">
                <h3 className="mb-4">Wallet Transactions (Latest 50)</h3>
                <div className="ms-auto buttons">
                    <Button type="primary" className="bm-btn" onClick={() => history.push({pathname: '/app/admin/wallets/add/' + uuid, state: {userDetails: userDetails, orders: orders}})}>Add</Button>
                </div>
            </div>
            {
                wallets &&
                <Table rowKey="order_uuid" dataSource={wallets} columns={walletsColumns} size={'middle'} className="bm-table" />
            }
        </PageLayout>
    )
}
