import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Form, Select } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMerchantBranches } from '../../../../services/merchants';
import { GetMenus } from '../../../../services/menus';
import usePageLoader from '../../../../hooks/usePageLoader';
import moment from 'moment';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function MenusList({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [branches, setBranches] = useState(null);
    const [menus, setMenus] = useState(null);
    const history = useHistory();
    const { Option } = Select;
    
    const getBranches = async() => {
        showLoader();
        const branchesResult = await GetMerchantBranches(currentUser.other_details.merchant_uuid, null);
        setBranches(branchesResult.data.data);
        hideLoader();
    }

    const getMenus = async(uuid) => {
        if (uuid === '') {
            setMenus(null);
        } else {
            showLoader();
            const menusResult = await GetMenus(uuid);
            setMenus(menusResult.data.data);
            hideLoader();
        }
    }

    const formLayout = {
        labelCol: { span: 0 },
        wrapperCol: { span: 24 }
    };

    const columns = [
        {
            title: 'Menu Name',
            dataIndex: 'menu_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/merchants/menus/view/${row.menu_uuid}`}>{text}</Link>,
        },
        {
            title: 'Available From',
            dataIndex: 'availability_time_from',
            render: (text, row) => <span>{moment(text, 'HH:mm:ss').format('hh:mm:ss A')}</span>,
        },
        {
            title: 'Available To',
            dataIndex: 'availability_time_to',
            render: (text, row) => <span>{moment(text, 'HH:mm:ss').format('hh:mm:ss A')}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'menu_status',
            render: text => GetStatusLabels('menus', parseInt(text))
        }
    ];

    useEffect(() => {
        getBranches();
        return function cleanup() {
            getBranches();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='menus'>
            { loader }
            <div className="d-flex w-100">
                <h3 className="mb-4">Menus List</h3>
                <div className="ms-auto buttons">
                    <Button type="primary" className="bm-btn" onClick={() => history.push('/app/merchants/menus/add')}>Add Menu</Button>
                </div>
            </div>
            {
                branches &&
                <Row>
                    <Col xs={24} md={{span: 8}}>
                        <Form layout={"vertical"} {...formLayout} className="bm-form">
                            <Form.Item label="Select Branch"
                                name="category_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a branch.',
                                    },
                                ]}>
                                <Select defaultValue="" className="bm-select" onChange={(value) => {
                                        getMenus(value)
                                    }}>
                                    <Option value="">select one</Option>
                                    {
                                        branches &&
                                        branches.map(item => {
                                            return <Option value={item.branch_uuid}>{item.branch_name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            }
            {
                menus &&
                <div>
                    <Table rowKey="menu_uuid" dataSource={menus} columns={columns} size={'middle'} className="bm-table" />
                </div>
            }
        </PageLayout>
    )
}
