import React, { useState } from 'react';
import { Row, Col, Card, Form, Input, InputNumber, Button, Alert, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { AddServiceAreas } from '../../../../services/serviceareas';
import { Regions } from '../../../../data/Regions';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';

export default function ServiceAreasAdd({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const history = useHistory();
    const { Option } = Select;
    
    const submitHandler = async (values) => {
        showLoader();
        try {
            const formattedValues = {
                ...values,
                service_area_radius: values.service_area_radius * 100,
                delivery_min_fee: values.delivery_min_fee * 100,
                delivery_min_radius: values.delivery_min_radius * 100,
                delivery_excess_per_km: values.delivery_excess_per_km * 100,
                booking_fee: values.booking_fee * 100,
                system_notice: '',
                service_area_status: 1
            }
            const result = await AddServiceAreas(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Service area has been saved.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                case 'error':
                    Debounce(NotificationManager.error('Service area already exists. Please use a different service area code.', 'Service Area Exists', 2000), 2000);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    return (
        <PageLayout activeLink='service-areas'>
            { loader }
            {
                <Row>
                    <Col xs={24} md={{span: 12, offset: 6}}>
                        <Card title="Add Service Area" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="New Service Area Added"
                                    description="Service area has been added successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.push('/app/admin/service-areas/list')}>
                                            Back to List
                                        </Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}>
                                <Form.Item label="Service Area Code"
                                    name="service_area_code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the service area code.',
                                        },
                                    ]}>
                                    <Input placeholder="service area code" />
                                </Form.Item>
                                <Form.Item label="Service Area Name"
                                    name="service_area_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter service area name.',
                                        },
                                    ]}>
                                    <Input placeholder="service area name" />
                                </Form.Item>
                                <Form.Item label="Region"
                                    name="region_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select region.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select">
                                        <Option value="">select one</Option>
                                        {
                                            Regions.map(item => {
                                                return <Option value={item.code} key={item.code}>{item.regionName}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Area Latitude"
                                    name="area_lat"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter service area latitude.',
                                        },
                                    ]}>
                                    <Input placeholder="latitude" />
                                </Form.Item>
                                <Form.Item label="Area Longitude"
                                    name="area_lng"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter service area longitude.',
                                        },
                                    ]}>
                                    <Input placeholder="longitude" />
                                </Form.Item>
                                <Form.Item label="Radius"
                                    name="service_area_radius"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter service area radius.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0.00" precision={2} step={.01} />
                                </Form.Item>
                                <Form.Item label="Delivery Fee (Min)"
                                    name="delivery_min_fee"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter minimum delivery fee.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0.00" precision={2} step={.01} />
                                </Form.Item>
                                <Form.Item label="Delivery Radius (Min)"
                                    name="delivery_min_radius"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter minimum delivery radius.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0.00" precision={2} step={.01} />
                                </Form.Item>
                                <Form.Item label="Excess Fee (per Km)"
                                    name="delivery_excess_per_km"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the excess rate per km.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0.00" precision={2} step={.01} />
                                </Form.Item>
                                <Form.Item label="Booking Fee"
                                    name="booking_fee"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the booking fee.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0.00" precision={2} step={1} />
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
