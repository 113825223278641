import React from 'react';
import { useHistory } from 'react-router-dom';

export default function Landing() {
    let currentUser = localStorage.getItem('currentUser');
    const history = useHistory();
    if (currentUser === 'undefined' || currentUser === null) {
        history.push('/login');
    } else {
        currentUser = JSON.parse(currentUser);
        switch(currentUser.user_type_code) {
            case 'A001':
                history.push('/app/admin/dashboard');
                break;
            case 'A004':
                history.push('/app/merchants/dashboard');
                break;
            default:
                break;
        }
    }
    return (
        <div></div>
    )
}
