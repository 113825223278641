import React, { useContext, createContext, useState, useEffect } from 'react';
import axios from 'axios';
const AuthConext = createContext();

export function useAuth() {
    return useContext(AuthConext);
}

export function AuthProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const Login = async (values) => {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/users/login', values);
        if (result.data.status === 'success') {
            localStorage.setItem('currentUser', JSON.stringify(result.data.data));
        }
        return result;
    }

    const Logout = async () => {
        localStorage.removeItem('currentUser');
        return null;
    }

    const RegisterMerchant = async (values) => {
        const userValues = {
            email_address: values.email_address,
            password: values.password,
            first_name: values.first_name,
            last_name: values.last_name,
            mobile_number: values.user_mobile_number,
            user_type_code: 'A004',
            user_status: 1
        }
        const userResult = await axios.post(process.env.REACT_APP_API_BASE_URL + '/users', userValues);
        if (userResult.data.status === 'success') {
            const merchantValues = {
                merchant_name: values.merchant_name,
                merchant_logo_uuid: '',
                company_name: values.company_name,
                contact_person: values.contact_person,
                email_address: values.merchant_email_address,
                mobile_number: values.mobile_number,
                compliance_code: 'D001',
                commission_rate: 0,
                price_rate: 0,
                service_area_uuid: values.service_area_uuid,
                is_free_delivery: values.is_free_delivery,
                min_free_delivery: values.min_free_delivery,
                merchant_status: 2
            }
            const merchantResult = await axios.post(process.env.REACT_APP_API_BASE_URL + '/merchants', merchantValues);
            const merchantParterValues = {
                user_uuid: userResult.data.uuid,
                merchant_uuid: merchantResult.data.uuid,
                branch_uuid: null,
                access_type_code: 'C001'
            }
            const merchantPartnerResult = await axios.post(process.env.REACT_APP_API_BASE_URL + '/merchant-partners', merchantParterValues);
            return merchantPartnerResult;
        } else {
            return userResult;
        }
    }

    const RegisterDelivery = async (values) => {
        const userValues = {
            email_address: values.email_address,
            password: values.password,
            first_name: values.first_name,
            last_name: values.last_name,
            mobile_number: values.mobile_number_formatted,
            user_type_code: 'A003',
            user_status: 1
        }
        const userResult = await axios.post(process.env.REACT_APP_API_BASE_URL + '/users', userValues);
        if (userResult.data.status === 'success') {
            const deliveryValues = {
                user_uuid: userResult.data.uuid,
                service_area_uuid: values.service_area_uuid,
                wallet_balance: 0,
                license_number: values.license_number,
                license_type: values.license_type,
                license_restrictions: values.license_restrictions,
                compliance_code: 'B001',
                is_on_shift: values.is_on_shift,
                is_available: values.is_available
            }
            const merchantResult = await axios.post(process.env.REACT_APP_API_BASE_URL + '/delivery-partners', deliveryValues);
            return merchantResult;
        } else {
            return userResult;
        }
    }

    // function Register(email, password) {
    //     return auth.createUserWithEmailAndPassword(email, password);
    // }

    // function ResetPassword(email) {
    //     return auth.sendPasswordResetEmail(email);
    // }

    useEffect(() => {
        setLoading(false);
    }, []);

    const value = {
        Login,
        Logout,
        RegisterMerchant,
        RegisterDelivery
        // Register,
        // ResetPassword
    }
    
    return (
        <AuthConext.Provider value={value}>
            { !loading && children }
        </AuthConext.Provider>
    )
}
