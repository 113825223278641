import axios from 'axios';

export const GetPortalPersonnels = async() => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/portal-personnels');
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetPortalPersonnelDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/portal-personnels/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}