import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Row, Col, Card, Table } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetUserDetails } from '../../../../services/users';
import { GetPastOrders } from '../../../../services/orders';
import usePageLoader from '../../../../hooks/usePageLoader';
import { ConvertToDecimal } from '../../../../functions/Math';
import { GetStatusLabels } from '../../../../functions/Labels';
import { DisplayDateTime } from '../../../../functions/Utilities';

export default function CustomersView({currentUser}) {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [userDetails, setUserDetails] = useState(null);
    const [orders, setOrders] = useState(null);
    const { uuid }  = useParams();
    
    const getCustomerDetails = async() => {
        showLoader();
        const resultUserDetails = await GetUserDetails(uuid);
        const resultOrders = await GetPastOrders(currentUser.other_details.service_area_uuid, uuid, 10000);
        setUserDetails(resultUserDetails.data.data);
        setOrders(resultOrders.data.data);
        setDisplay(true);
        hideLoader();
    }

    const columns = [
        {
            title: 'Tracking No',
            dataIndex: 'order_tracking_no',
            render: (text, row) => <Link className="text-link-primary-x3" to={{ pathname: `/app/admin/orders/view/${row.order_uuid}`, state: {orderDetails: row}}}>{text}</Link>,
        },
        {
            title: 'Merchant Due',
            dataIndex: 'order_merchant_due_amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_merchant_due_amount),
        },
        {
            title: 'Delivery Fee',
            dataIndex: 'order_delivery_due_amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_delivery_due_amount),
        },
        {
            title: 'Platform',
            dataIndex: 'order_admin_due_amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_admin_due_amount),
        },
        {
            title: 'Order Amount',
            dataIndex: 'order_total_amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_total_amount),
        },
        {
            title: 'Date & Time',
            render: (text, row) => <span>{DisplayDateTime(row.created_at)}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'order_status',
            width: 120,
            render: text => GetStatusLabels('orders', parseInt(text))
        }
    ];

    useEffect(() => {
        getCustomerDetails();
        return function cleanup() {
            getCustomerDetails();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='customers'>
            { loader }
            {
                display &&
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <Card title="Stats" className="bm-card mb-4 mb-md-0">
                            Stats soon
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card title="Customer Details" className="bm-card mb-4 mb-md-0">
                            <div className="bm-static-container">
                                <div className="title">First Name</div>
                                <div className="display">{userDetails.first_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Last Name</div>
                                <div className="display">{userDetails.last_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Email Address</div>
                                <div className="display"><a href={`mailto:${userDetails.email_address}`} className="text-link-primary-x3">{userDetails.email_address}</a></div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Mobile Number</div>
                                <div className="display">{userDetails.mobile_number}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">User Status</div>
                                <div className="display">{GetStatusLabels('users', userDetails.user_status)}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
            <h3 className="mb-4">Customer Orders</h3>
            {
                orders &&
                <Table rowKey="order_uuid" dataSource={orders} columns={columns} size={'middle'} className="bm-table" />
            }
        </PageLayout>
    )
}
