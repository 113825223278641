import axios from 'axios';

export const GetCustomers = async(service_area_uuid, limit) => {
    try {
        let result;
        if (service_area_uuid === null) {
            result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/customers/limit/' + limit);
        } else {
            result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/customers/service-area/' + service_area_uuid + '/limit/' + limit);
        }
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetCustomersDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/customers/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetCustomersOrders = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/orders/past/' + uuid + '/limit/10000');
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetCustomersNoTxns = async() => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/customers/no-transactions');
        return result;
    } catch (error) {
        throw error;
    }
}