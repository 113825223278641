import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Form, Select, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMerchantBranches } from '../../../../services/merchants';
import { GetMonthlyOrders } from '../../../../services/orders';
import { GetOrderTrackings } from '../../../../services/trackings';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';
import { ConvertToDecimal } from '../../../../functions/Math';
import { DisplayDateTime } from '../../../../functions/Utilities';

export default function OrdersPast({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [branches, setBranches] = useState(null);
    const [monthlyOrders, setMonthlyOrders] = useState(null);
    const history = useHistory();
    
    const { Option } = Select;

    const getBranches = async() => {
        showLoader();
        const branchesResult = await GetMerchantBranches(currentUser.other_details.merchant_uuid, null);
        setBranches(branchesResult.data.data);
        hideLoader();
    }
    
    const getOrders = async() => {
        setMonthlyOrders(null);
        if (selectedBranch !== '' || selectedMonth !== '' || selectedYear !== '') {
            showLoader();
            const monthlyOrdersResult = await GetMonthlyOrders(selectedBranch, selectedYear, selectedMonth);
            if (monthlyOrdersResult.data.data.length > 0) {
                let count = 0;
                monthlyOrdersResult.data.data.forEach((item, key) => {
                    Promise.all([
                        GetOrderTrackings(item.order_uuid)
                    ]).then(result => {
                        monthlyOrdersResult.data.data[key].tracking = result[0].data.data;
                    })
                    count++;
                    if (count === monthlyOrdersResult.data.data.length) {
                        setTimeout(() => {
                            setMonthlyOrders(monthlyOrdersResult.data.data);
                            hideLoader();
                        }, 1000);
                    }
                });
            } else {
                setMonthlyOrders(monthlyOrdersResult.data.data);
                hideLoader();
            }
        }
    }

    const formLayout = {
        labelCol: { span: 0 },
        wrapperCol: { span: 24 }
    };

    const renderCellTracking = (text, row, key) => {
        let trackingLatestKey = row.tracking.length - 1;
        return <strong>{GetStatusLabels('tracking', row.tracking[trackingLatestKey].tracking_code)}</strong>;
    }

    const columns = [
        {
            title: 'Tracking No',
            dataIndex: 'order_tracking_no',
            render: (text, row) => <Link className="text-link-primary-x3" to={{ pathname: `/app/merchants/orders/view/${row.order_uuid}`, state: {orderDetails: row} }}>{text}</Link>,
        },
        {
            title: 'Order Date & Time',
            render: (text, row) => <span>{DisplayDateTime(row.created_at)}</span>,
        },
        {
            title: 'Tracking Status',
            render: renderCellTracking
        },
        {
            title: 'Amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_merchant_due_amount),
        },
        {
            title: 'Order Status',
            width: 150,
            dataIndex: 'order_status',
            render: text => GetStatusLabels('orders', parseInt(text)),
        }
    ];

    

    useEffect(() => {
        getBranches();
        return function cleanup() {
            getBranches();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='orders'>
            { loader }
            <div className="d-flex w-100">
                <h3 className="mb-4">Past Orders List</h3>
                <div className="ms-auto buttons">
                    <Button type="primary" className="bm-btn" onClick={() => history.push('/app/merchants/orders/list')}>Current Orders</Button>
                </div>
            </div>
            {
                branches &&
                <div className="mb-5">
                    <Form layout={"vertical"} {...formLayout} className="bm-form" onFinish={getOrders}>
                        <Row gutter={24}>
                            <Col xs={24} md={{span: 6}}>
                                <Form.Item label="Select Branch"
                                    name="branch_uuid"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a branch.',
                                        },
                                    ]}>
                                    <Select defaultValue={null} className="bm-select" onChange={(value) => {
                                            setSelectedBranch(value)
                                        }}>
                                        <Option value={null}>select one</Option>
                                        {
                                            branches &&
                                            branches.map(item => {
                                                return <Option value={item.branch_uuid} key={item.branch_uuid}>{item.branch_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={{span: 6}}>
                                <Form.Item label="Select Year"
                                    name="order_year"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select year.',
                                        },
                                    ]}>
                                    <Select defaultValue='' className="bm-select" onChange={(value) => {
                                            setSelectedYear(value)
                                        }}>
                                        <Option value=''>select one</Option>
                                        <Option value='2021'>2021</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={{span: 6}}>
                                <Form.Item label="Select Month"
                                    name="order_month"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select month.',
                                        },
                                    ]}>
                                    <Select defaultValue='' className="bm-select" onChange={(value) => {
                                            setSelectedMonth(value)
                                        }}>
                                        <Option value=''>select one</Option>
                                        <Option value='01'>Jan</Option>
                                        <Option value='02'>Feb</Option>
                                        <Option value='03'>Mar</Option>
                                        <Option value='04'>Apr</Option>
                                        <Option value='05'>May</Option>
                                        <Option value='06'>Jun</Option>
                                        <Option value='07'>Jul</Option>
                                        <Option value='08'>Aug</Option>
                                        <Option value='09'>Sep</Option>
                                        <Option value='10'>Oct</Option>
                                        <Option value='11'>Nov</Option>
                                        <Option value='12'>Dec</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col xs={24} md={{span: 6}}>
                                <Button type="primary" className="bm-btn w-100" htmlType="submit">Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            }
            {
                monthlyOrders &&
                <div>
                    <Table rowKey="order_uuid" dataSource={monthlyOrders} columns={columns} size={'middle'} className="bm-table" />
                </div>
            }
        </PageLayout>
    )
}
