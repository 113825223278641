export const SendSms = async(targetNumbers, messageBody) => {
    let toNumber = encodeURI(targetNumbers);
    let message = messageBody;
    let username = encodeURI('tarokins');
    let password = encodeURI('adFkRwHPtCJ7TS39');
    let agree = encodeURI('YES');
    let msgType = 1;
    let sendid = encodeURI('BILIMOKO');
    fetch(`https://www.isms.com.my/isms_send.php?un=${username}&pwd=${password}&dstno=${toNumber}&msg=${message}&sendid=${sendid}&type=${msgType}&agreedterm=${agree}`);
    return true;
}