import React, { useEffect, useState } from 'react';
import { Table, Input } from 'antd';
import { Link } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetCustomers } from '../../../../services/customers';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';
import { DisplayDateTime } from '../../../../functions/Utilities';

export default function CustomersList({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [customers, setCustomers] = useState(null);
    const [filterCustomers, setFilterCustomers] = useState(null);
    const { Search } = Input;

    const getCustomers = async() => {
        showLoader();
        const result = await GetCustomers(currentUser.other_details.service_area_uuid, 100);
        setCustomers(result.data.data);
        hideLoader();
    }

    const filterTable = (value) => {
        const tempData = customers.filter(o =>
            Object.keys(o).some(k =>
              String(o[k])
                .toLowerCase()
                .includes(value.toLowerCase())
            )
        );
        setFilterCustomers(tempData);
    }

    const columns = [
        {
            title: 'Customer Name',
            dataIndex: 'first_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/customers/view/${row.user_uuid}`}>{row.first_name} {row.last_name}</Link>,
        },
        {
            title: 'Mobile Number',
            render: (text, row) => <a className="text-link-primary-x3" href={`tel:+${row.mobile_number}`}>{row.mobile_number}</a>
        },
        {
            title: 'Email',
            dataIndex: 'email_address',
            render: text => <a className="text-link-primary-x3" href={`mailto:${text}`}>{text}</a>,
        },
        {
            title: 'Date/Time Added',
            render: (text, row) => DisplayDateTime(row.created_at)
        },
        {
            title: 'Status',
            render: (text, row) => GetStatusLabels('users', row.user_status),
        }
    ];

    useEffect(() => {
        getCustomers();
        return function cleanup() {
            getCustomers();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='customers'>
            { loader }
            <h3 className="mb-4">Customers List</h3>
            {
                customers &&
                <div>
                    <div className="d-flex justify-content-end mb-3">
                        <Search className="bm-table-search" placeholder="search table" onSearch={filterTable} style={{ width: 200 }} />
                    </div>
                    <Table rowKey="merchant_uuid" dataSource={filterCustomers === null ? customers : filterCustomers} columns={columns} size={'middle'} className="bm-table" />
                </div>
            }
        </PageLayout>
    )
}
