import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Row, Col, Card, Alert } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetOrderItems } from '../../../../services/orderitems';
import { GetItemDetails } from '../../../../services/items';
import { GetVariationDetails } from '../../../../services/itemsvariations';
import { GetAddOnDetails } from '../../../../services/addons';
import { GetUserDetails } from '../../../../services/users';
import { GetOrderTrackings } from '../../../../services/trackings';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';
import { ConvertToDecimal } from '../../../../functions/Math';
import { DisplayDateTime } from '../../../../functions/Utilities';

export default function OrdersView() {
    const history = useHistory();
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const orderDetails = history.location.state.orderDetails;
    const [orderItems, setOrderItems] = useState(null);
    const [orderTracking, setOrderTracking] = useState(null);
    const [deliveryPartner, setDeliveryPartner] = useState(null);
    
    const getOrderDetails = async() => {
        showLoader();

        let deliveryPartnerResult = await GetUserDetails(orderDetails.delivery_partner_uuid);
        setDeliveryPartner(deliveryPartnerResult.data.data);

        let trackingResult = await GetOrderTrackings(orderDetails.order_uuid);
        let trackingResultKey = trackingResult.data.data.length - 1;
        setOrderTracking(trackingResult.data.data[trackingResultKey].tracking_code);

        let orderItemsResult = await GetOrderItems(orderDetails.order_uuid);
        let counter = 0;
        orderItemsResult.data.data.forEach((item, key) => {
            Promise.all([
                GetItemDetails(item.item_uuid),
                GetVariationDetails(item.variation_uuid)
            ]).then(result => {
                orderItemsResult.data.data[key].index = key + 1;
                orderItemsResult.data.data[key].itemDetails = result[0].data.data;
                orderItemsResult.data.data[key].variationDetails = result[1].data.data;
                orderItemsResult.data.data[key].addOnsOptional = [];
                orderItemsResult.data.data[key].addOnsRequired = [];

                if (item.add_on_required !== '[]') {
                    let itemAddOnRequired = JSON.parse(item.add_on_required);
                    itemAddOnRequired.forEach(function(itemRequiredItem, itemRequiredIndex) {
                        Promise.all([
                            GetAddOnDetails(itemRequiredItem.add_on_uuid)
                        ]).then(result => {
                            orderItemsResult.data.data[key].addOnsRequired.push(result[0].data.data);
                        });
                    });
                }
                
                if (item.add_on_optional !== '[]') {
                    let itemAddOnOptional = JSON.parse(item.add_on_optional);
                    itemAddOnOptional.forEach(function(itemOptionalItem, itemOptionalIndex) {
                        Promise.all([
                            GetAddOnDetails(itemOptionalItem.add_on_uuid)
                        ]).then(result => {
                            orderItemsResult.data.data[key].addOnsOptional.push(result[0].data.data);
                        });
                    });
                }
                counter++;

                if (counter === orderItemsResult.data.data.length) {
                    setTimeout(() => {
                        setOrderItems(orderItemsResult.data.data);
                        setDisplay(true);
                        hideLoader();
                    }, 1000);
                }
            });
        });
    }

    const renderCellItemName = (text, row, key) => {
        return row.itemDetails.item_name;
    }

    const renderCellVariationName = (text, row, key) => {
        return row.variationDetails.variation_name;
    }

    const renderRequiredAddOns = (text, row, key) => {
        if (row.addOnsRequired.length === 0) {
            return 'none';
        } else {
            let labelsArray = [];
            row.addOnsRequired.forEach((item, key) => {
                labelsArray.push(item.add_on_name);
            });
            return labelsArray.join(', ');
        }
    }

    const renderOptionalAddOns = (text, row, key) => {
        if (row.addOnsOptional.length === 0) {
            return 'none';
        } else {
            let labelsArray = [];
            row.addOnsOptional.forEach((item, key) => {
                labelsArray.push(item.add_on_name);
            });
            return labelsArray.join(', ');
        }
    }

    const columns = [
        {
            title: 'Count',
            width: 50,
            dataIndex: 'index',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity'
        },
        {
            title: 'Item Name',
            render: renderCellItemName
        },
        {
            title: 'Variation',
            render: renderCellVariationName
        },
        {
            title: 'Required Add-Ons',
            render: renderRequiredAddOns
        },
        {
            title: 'Optional Add-Ons',
            render: renderOptionalAddOns
        }
    ];

    useEffect(() => {
        getOrderDetails();
        return function cleanup() {
            getOrderDetails();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='orders'>
            { loader }
            {
                display && 
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <h3 className="mb-4">Order Items</h3>
                        {
                            orderItems &&
                            <Table rowKey="index" dataSource={orderItems} columns={columns} size={'middle'} className="bm-table" pagination={false} />
                        }
                    </Col>
                    <Col xs={24} md={8}>
                        <Card title="Order Details" className="bm-card mb-4 mb-md-0">
                            {
                                orderTracking &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message={(orderTracking === 'G003') ? 'Thank You' : 'Attention'}
                                    description={GetStatusLabels('tracking', orderTracking)}
                                    type={(orderTracking === 'G003') ? 'success' : 'danger'}
                                />
                            }
                            <div className="bm-static-container">
                                <div className="title">Order Tracking No</div>
                                <div className="display"><h3>{orderDetails.order_tracking_no}</h3></div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Delivery Partner</div>
                                <div className="display"><h3>{deliveryPartner.first_name} {deliveryPartner.last_name}</h3></div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Amount</div>
                                <div className="display">P{ConvertToDecimal(orderDetails.order_merchant_due_amount)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Order Date & Time</div>
                                <div className="display">{DisplayDateTime(orderDetails.created_at)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Status</div>
                                <div className="display">{GetStatusLabels('orders', parseInt(orderDetails.order_status))}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
