
import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Row, Col, Card, Form, Input, Button, Alert, Select, Table } from 'antd';
import { v4 as UUIDv4 } from 'uuid';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMerchants } from '../../../../services/merchants';
import { GetCategories, ImportCategories } from '../../../../services/categories';
import { GetMerchantItems, ImportItems } from '../../../../services/items';
import { GetItemsVariations, ImportVariations } from '../../../../services/itemsvariations';
import { GetAddOnGroups, ImportAddOnGroups } from '../../../../services/addongroups';
import { GetAddOns, ImportAddOns } from '../../../../services/addons';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';
import { NotificationManager } from 'react-notifications';
import { ConvertToDecimal } from '../../../../functions/Math';
import { Debounce } from '../../../../functions/Utilities';

export default function ImportsAdd({currentUser}) {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [merchants, setMerchants] = useState(null);
    const [importData, setImportData] = useState(null);
    const [dataSetType, setDataSetType] = useState(null);
    const [success, setSuccess] = useState(null);
    const { Option } = Select;
    const history = useHistory();
    const [form] = Form.useForm();
    
    const loadPageData = async() => {
        showLoader();
        const merchants = await GetMerchants(null);
        setMerchants(merchants.data.data);
        setDisplay(true);
        hideLoader();
    }

    const columnsCategories = [
        {
            title: 'Category Name',
            render: (text, row) => row.category_name,
        },
        {
            title: 'Status',
            dataIndex: 'category_status',
            width: 120,
            render: text => GetStatusLabels('categories', parseInt(text))
        },
        {
            title: 'Options',
            width: 150,
            render: (text, row) => <Button type="danger" className="bm-btn bm-btn-sm" onClick={(event) => removeItem('category', row.category_uuid)}>Remove</Button>
        }
    ];

    const columnsItems = [
        {
            title: 'Item Name',
            render: (text, row) => row.item_name,
        },
        {
            title: 'Status',
            dataIndex: 'item_status',
            width: 120,
            render: text => GetStatusLabels('items', parseInt(text))
        },
        {
            title: 'Options',
            width: 150,
            render: (text, row) => <Button type="danger" className="bm-btn bm-btn-sm" onClick={(event) => removeItem('item', row.item_uuid)}>Remove</Button>
        }
    ];

    const columnsVariations = [
        {
            title: 'Variation Name',
            render: (text, row) => row.variation_name,
        },
        {
            title: 'Price',
            width: 120,
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.variation_price),
        },
        {
            title: 'Status',
            dataIndex: 'variation_status',
            width: 120,
            render: text => GetStatusLabels('variations', parseInt(text))
        },
        {
            title: 'Options',
            width: 150,
            render: (text, row) => <Button type="danger" className="bm-btn bm-btn-sm" onClick={(event) => removeItem('variation', row.variation_uuid)}>Remove</Button>
        }
    ];

    const columnsAddOnGroups = [
        {
            title: 'Add On Group Name',
            render: (text, row) => row.add_on_group_name,
        },
        {
            title: 'Status',
            dataIndex: 'add_on_group_status',
            width: 120,
            render: text => GetStatusLabels('add-on-groups', parseInt(text))
        },
        {
            title: 'Options',
            width: 150,
            render: (text, row) => <Button type="danger" className="bm-btn bm-btn-sm" onClick={(event) => removeItem('add-on-group', row.add_on_group_uuid)}>Remove</Button>
        }
    ];

    const columnsAddOns = [
        {
            title: 'Add On Name',
            render: (text, row) => row.add_on_name,
        },
        {
            title: 'Price',
            width: 120,
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.add_on_price),
        },
        {
            title: 'Status',
            dataIndex: 'add_on_status',
            width: 120,
            render: text => GetStatusLabels('add-ons', parseInt(text))
        },
        {
            title: 'Options',
            width: 150,
            render: (text, row) => <Button type="danger" className="bm-btn bm-btn-sm" onClick={(event) => removeItem('add-on', row.add_on_uuid)}>Remove</Button>
        }
    ];

    const ItemsExpand = (record) => {
        const variationsData = importData.variations.filter(function(obj) { return obj.item_uuid === record.item_uuid});
        return <Table rowKey="variation_uuid" columns={columnsVariations} dataSource={variationsData} size={'middle'} className="bm-table" pagination={false} />
    }

    const AddOnGroupsExpand = (record) => {
        const addOnsData = importData.addOns.filter(function(obj) { return obj.add_on_group_uuid === record.add_on_group_uuid});
        return <Table rowKey="add_on_uuid" columns={columnsAddOns} dataSource={addOnsData} size={'middle'} className="bm-table" pagination={false} />
    }

    const loadDataHandler = async(values) => {
        showLoader();
        setDataSetType(values.data_set_type);
        switch(values.data_set_type) {
            case 'categories':
                let importDataResult = await GetCategories(values.source_merchant_uuid);
                let tempImportDataResult = importDataResult.data.data.map(({category_id, category_uuid, created_at, updated_at, merchant_uuid, category_status, ...keepAttrs}) => keepAttrs);
                tempImportDataResult = tempImportDataResult.map(item => ({ ...item, merchant_uuid: values.target_merchant_uuid, category_uuid: UUIDv4(), category_status: 1 }));
                setImportData({
                    categories: tempImportDataResult
                });
                break;
            case 'items':
                const baseItemsResult = await GetMerchantItems(values.source_merchant_uuid);
                const baseVariationsResult = await getItemVariations(baseItemsResult.data.data);
                const baseAddOnGroupsResult = await GetAddOnGroups(values.source_merchant_uuid);
                const baseAddOnsResult = await getAddOnItems(baseAddOnGroupsResult.data.data);
                const tempItemsData = [];
                const tempVariationsData = [];
                const tempAddOnGroupsData = [];
                const tempAddOnsData = [];

                baseItemsResult.data.data.map((item, index) => {
                    const itemUuid = UUIDv4();
                    let tempItem = {
                        item_name: item.item_name,
                        item_photo_file_uuid: item.item_photo_file_uuid,
                        item_status: 1,
                        item_uuid: itemUuid,
                        merchant_uuid: values.target_merchant_uuid
                    }
                    tempItemsData.push(tempItem);
                    const resultVariation = baseVariationsResult.filter(function(obj) { return obj.item_uuid === item.item_uuid});
                    resultVariation[0].variations.map(variationItem => {
                        let tempVariation = {
                            is_bestseller: variationItem.is_bestseller,
                            item_uuid: itemUuid,
                            variation_description: variationItem.variation_description,
                            variation_name: variationItem.variation_name,
                            variation_price: variationItem.variation_price,
                            variation_status: 1,
                            variation_uuid: UUIDv4()
                        }
                        tempVariationsData.push(tempVariation);
                    })
                });

                baseAddOnGroupsResult.data.data.map((item, index) => {
                    const groupUuid = UUIDv4();
                    let tempGroup = {
                        add_on_group_uuid: groupUuid,
                        merchant_uuid: values.target_merchant_uuid,
                        add_on_group_name: item.add_on_group_name,
                        is_required: item.is_required,
                        is_multiple: item.is_multiple,
                        add_on_group_status: 1
                    }
                    tempAddOnGroupsData.push(tempGroup);
                    const resultAddOns = baseAddOnsResult.filter(function(obj) { return obj.add_on_group_uuid === item.add_on_group_uuid});
                    resultAddOns[0].add_ons.map(addOnsItem => {
                        let tempAddOn = {
                            add_on_uuid: UUIDv4(),
                            add_on_group_uuid: groupUuid,
                            add_on_name: addOnsItem.add_on_name,
                            add_on_description: addOnsItem.add_on_description,
                            add_on_price: addOnsItem.add_on_price,
                            is_bestseller: addOnsItem.is_bestseller,
                            add_on_status: 1
                        }
                        tempAddOnsData.push(tempAddOn);
                    })
                });
                setImportData({
                    items: tempItemsData,
                    variations: tempVariationsData,
                    addOnGroups: tempAddOnGroupsData,
                    addOns: tempAddOnsData
                });
                break;
        }
        hideLoader();
    };

    const getItemVariations = (items) => {
        const promisesVariations = items.map(async(item) => {
            const resultVariation = await GetItemsVariations(item.item_uuid);
            return {
                item_uuid: item.item_uuid,
                variations: resultVariation.data.data
            }
        });
        return Promise.all(promisesVariations);
    }

    const getAddOnItems  = (groups) => {
        const promisesAddOns = groups.map(async(item) => {
            const resultAddOns = await GetAddOns(item.add_on_group_uuid);
            return {
                add_on_group_uuid: item.add_on_group_uuid,
                add_ons: resultAddOns.data.data
            }
        });
        return Promise.all(promisesAddOns);
    }

    const removeItem = (module, uuid) => {
        let filterData;
        switch(dataSetType) {
            case 'categories':
                filterData = importData.categories.filter(function(item) {
                    return item.category_uuid !== uuid
                });
                setImportData({
                    categories: filterData
                });
                break;
            case 'items':
                switch(module) {
                    case 'item':
                        filterData = importData.items.filter(function(item) {
                            return item.item_uuid !== uuid
                        });
                        setImportData({
                            ...importData,
                            items: filterData
                        });
                        break;
                    case 'variation':
                        filterData = importData.variations.filter(function(item) {
                            return item.variation_uuid !== uuid
                        });
                        setImportData({
                            ...importData,
                            variations: filterData
                        });
                        break;
                    case 'add-on-group':
                        filterData = importData.addOnGroups.filter(function(item) {
                            return item.add_on_group_uuid !== uuid
                        });
                        setImportData({
                            ...importData,
                            addOnGroups: filterData
                        });
                        break;
                    case 'add-on':
                        filterData = importData.addOns.filter(function(item) {
                            return item.add_on_uuid !== uuid
                        });
                        setImportData({
                            ...importData,
                            addOns: filterData
                        });
                        break;
                }
                break;
        }
    }

    const handleImportData = async() => {
        showLoader();
        switch(dataSetType) {
            case 'categories':
                const postData = {
                    data: importData.categories
                }
                const importResult = await ImportCategories(postData);
                if (importResult.data.status === 'success') {
                    Debounce(NotificationManager.success('Data imported.', 'Success', 2000), 2000);
                    setSuccess(true);
                    setImportData(null);
                    form.resetFields();
                } else {
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                }
                break;
            case 'items':
                const itemsData = {
                    data: importData.items
                }
                const variationsData = {
                    data: importData.variations
                }
                const addOnGroupsData = {
                    data: importData.addOnGroups
                }
                const addOnsData = {
                    data: importData.addOns
                }
                Promise.all([
                    ImportItems(itemsData),
                    ImportVariations(variationsData),
                    ImportAddOnGroups(addOnGroupsData),
                    ImportAddOns(addOnsData)
                ]).then(result => {
                    Debounce(NotificationManager.success('Data imported.', 'Success', 2000), 2000);
                    setSuccess(true);
                    setImportData(null);
                    form.resetFields();
                }).catch(e => {
                    Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
                    console.log(e.message)
                    hideLoader();
                });
                break;
        }
        hideLoader();
    }

    useEffect(() => {
        loadPageData();
        return function cleanup() {
            loadPageData();
        }
        // eslint-disable-next-line
    }, []);

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    return (
        <PageLayout activeLink='imports'>
            { loader }
            {
                display &&
                <Row gutter={24}>
                    <Col xs={24} md={{span: 12, offset: 6}}>
                        <Card title="Import Data" className="bm-card mb-5">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Data Imported"
                                    description="Data has been imported successfully."
                                    type="success"
                                />
                            }
                            {
                                !success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Notice"
                                    description="This function is for newly added Merchants which does NOT have any categories and/or items added in their account. Please verify the merchant account before proceeding."
                                    type="warning"
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={loadDataHandler} form={form}>
                                <Form.Item label="Source Merchant"
                                    name="source_merchant_uuid"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a source merchant.',
                                        },
                                    ]}>
                                    <Select
                                        showSearch={true}
                                        defaultValue=""
                                        className="bm-select"
                                        allowClear={true}
                                        onChange={(event) => setImportData(null)}
                                        filterOption={(input, option) => 
                                            option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                        <Option value="">select one</Option>
                                        {
                                            merchants &&
                                            merchants.map(item => {
                                                return <Option value={item.merchant_uuid}>{item.merchant_name} ({item.company_name})</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Target Merchant"
                                    name="target_merchant_uuid"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a target merchant.'
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('source_merchant_uuid') !== value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Please select a different target merchant.'));
                                            },
                                        }),
                                    ]}>
                                    <Select
                                        showSearch={true}
                                        defaultValue=""
                                        className="bm-select"
                                        allowClear={true}
                                        onChange={(event) =>
                                        setImportData(null)}
                                        filterOption={(input, option) => 
                                            option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                        <Option value="">select one</Option>
                                        {
                                            merchants &&
                                            merchants.map(item => {
                                                return <Option value={item.merchant_uuid}>{item.merchant_name} ({item.company_name})</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Date Set"
                                    name="data_set_type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select a data set to import.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select" allowClear={true} onChange={(event) => setImportData(null)}>
                                        <Option value="">select one</Option>
                                        <Option value="categories">Categories</Option>
                                        <Option value="items">Items, Variations, & Add-Ons</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Load Data
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
            {
                importData &&
                <div>
                    <h3 className="mb-4"></h3>
                    <div className="d-flex w-100">
                        <h3 className="mb-4">Data to Import</h3>
                        <div className="ms-auto buttons">
                            <Button type="primary" className="bm-btn" onClick={() => handleImportData()}>Import Data</Button>
                        </div>
                    </div>
                    {
                        dataSetType === 'categories' &&
                        <Table rowKey="category_uuid" dataSource={importData.categories} columns={columnsCategories} size={'middle'} className="bm-table" pagination={false} />
                    }
                    {
                        dataSetType === 'items' &&
                        <div>
                            <Table rowKey="item_uuid" dataSource={importData.items} columns={columnsItems} expandable={{expandedRowRender: ItemsExpand}} size={'middle'} className="bm-table mb-4" pagination={false} />
                            <Table rowKey="add_on_group_uuid" dataSource={importData.addOnGroups} columns={columnsAddOnGroups} expandable={{expandedRowRender: AddOnGroupsExpand}} size={'middle'} className="bm-table" pagination={false} />
                        </div>
                    }
                </div>
            }
        </PageLayout>
    )
}
