import axios from 'axios';

export const GetCategories = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/categories/merchant/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const AddCategories = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/categories', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetCategoryDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/categories/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateCategories = async(values) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/categories/' + values.category_uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const ImportCategories = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/categories/import', values);
        return result;
    } catch (error) {
        throw error;
    }
}