import axios from 'axios';

export const GetMerchantItems = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/items/merchant/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const AddItems = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/items', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetItemDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/items/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateItems = async(values) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/items/' + values.item_uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const ImportItems = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/items/import', values);
        return result;
    } catch (error) {
        throw error;
    }
}