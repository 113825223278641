import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Input, Button, Alert, Select, TimePicker, Upload } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMerchantBranches } from '../../../../services/merchants';
import { UpdateMenus } from '../../../../services/menus';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';
import moment from 'moment';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';
import { UploadFiles } from '../../../../services/files';

export default function MenusUpdate({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const [branches, setBranches] = useState(null);
    const { Option } = Select;
    const { TextArea } = Input;
    const history = useHistory();
    const menuDetails = history.location.state.menuDetails;
    const menuBannerDetails = history.location.state.menuBannerDetails;
    const [fileListBanner, setFileListBanner] = useState([]);
    const [newBannerPhoto, setNewBannerPhoto] = useState(null);
    const [newBannerNotice, setNewBannerNotice] = useState(null);

    const getBranches = async() => {
        showLoader();
        const branchesResult = await GetMerchantBranches(currentUser.other_details.merchant_uuid, null);
        setBranches(branchesResult.data.data);
        hideLoader();
    }

    const submitHandler = async (values) => {
        showLoader();
        try {
            if (values.menu_description === undefined) {
                values.menu_description = "";
            }
            const formattedValues = {
                ...values,
                menu_uuid: menuDetails.menu_uuid,
                availability_time_from: values.availability[0].format('HH:mm:ss'),
                availability_time_to: values.availability[1].format('HH:mm:ss'),
                menu_status: parseInt(values.menu_status)
            }
            if (newBannerPhoto !== null) {
                formattedValues.banner_photo_file_uuid = newBannerPhoto
            }
            const result = await UpdateMenus(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Menu has been updated.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                case 'error':
                    Debounce(NotificationManager.error('Menu already exists. Please use different menu name.', 'Menu Exists', 2000), 2000);
                    break;
                case 'overlap':
                    Debounce(NotificationManager.error('There is already an active menu by that timeslot. Please check again.', 'Overlapping Menu', 2000), 2000);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    const uploadFile = async(file) => {
        try {
            const formattedValues = {
                file_url: file.secure_url,
                file_type: file.resource_type,
                file_name: file.asset_id,
                file_description: '',
                file_status: 1
            }
            const result = await UploadFiles(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Banner photo is now uploaded.', 'Success', 2000), 2000);
                    const bannerItem = {
                        uid: file.asset_id,
                        name: file.asset_id,
                        url: file.secure_url
                    }
                    setFileListBanner([bannerItem]);
                    setNewBannerPhoto(result.data.file_uuid);
                    setNewBannerNotice(true);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleOnChangeBanner = async({file, fileList: newFileList }) => {
        setFileListBanner(newFileList);
        showLoader();
        if (file.status !== 'uploading') {
            await getBase64(file.originFileObj, result => {
                let formData = new FormData();
                formData.append('file', result);
                formData.append('upload_preset', 'nspmba8t');
                axios.post('https://api.cloudinary.com/v1_1/falcomtech/image/upload', formData)
                    .then(response => {
                        if (response.status === 200) {
                            uploadFile(response.data);
                            hideLoader();
                        }
                    })
            });
        }
    }

    useEffect(() => {
        getBranches();
        return function cleanup() {
            getBranches();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (menuBannerDetails !== null) {
            const bannerItem = {
                uid: menuBannerDetails.file_uuid,
                name: menuBannerDetails.file_name,
                url: menuBannerDetails.file_url
            }
            setFileListBanner([bannerItem]);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='menus'>
            { loader }
            {
                <Row>
                    <Col xs={24} md={{span: 12, offset: 6}}>
                        <Card title="Update Menu" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Menu Updated"
                                    description="Menu has been updated successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.goBack()}>Back</Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}
                                initialValues={{
                                    'branch_uuid': menuDetails.branch_uuid,
                                    'menu_name': menuDetails.menu_name,
                                    'menu_description': menuDetails.menu_description,
                                    'availability': [moment(menuDetails.availability_time_from, 'hh:mm:ss a'), moment(menuDetails.availability_time_to, 'hh:mm:ss a')],
                                    'menu_status': String(menuDetails.menu_status)
                                }}>
                                <Form.Item label="Branch"
                                    name="branch_uuid"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please please select a branch.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select">
                                        <Option value="">select one</Option>
                                        {
                                            branches &&
                                            branches.map(item => {
                                                return <Option value={item.branch_uuid} key={item.branch_uuid}>{item.branch_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Menu Name"
                                    name="menu_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the category name.',
                                        },
                                    ]}>
                                    <Input placeholder="category name" />
                                </Form.Item>
                                <Form.Item label="Description"
                                    name="menu_description">
                                    <TextArea rows={4} placeholder="description" />
                                </Form.Item>
                                <Form.Item label="Availability"
                                    name="availability"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select time availability of menu.',
                                        },
                                    ]}>
                                    <TimePicker.RangePicker use12Hours placeholder={['time from', 'time to']} suffixIcon={<></>} />
                                </Form.Item>
                                <Form.Item label="Banner Photo"
                                    name="banner_photo">
                                    <ImgCrop modalTitle="Crop Image" aspect={2.33} quality={.8}>
                                        <Upload name="image"
                                            listType="picture-card"
                                            accept="image/png, image/jpeg"
                                            fileList={fileListBanner}
                                            onChange={handleOnChangeBanner}
                                            beforeUpload={false}
                                            onRemove={false}>
                                            {fileListBanner.length < 2 && 'Replace'}
                                        </Upload>
                                    </ImgCrop>
                                    <Input className="d-none" />
                                    {
                                        newBannerNotice &&
                                        <Alert
                                            className="bm-alert mt-2"
                                            message="Notice"
                                            description="Menu banner photo has not been saved to the database yet. You need to submit the form in order to save the image."
                                            type="default"
                                        />
                                    }
                                </Form.Item>
                                <Form.Item label="Status"
                                    name="menu_status"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select status.',
                                        },
                                    ]}>
                                    <Select>
                                        <Option value="1">Active</Option>
                                        <Option value="0">Inactive</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
