import React, { useEffect, useState } from 'react';
import PageLayout from '../../../../components/layout/PageLayout';
import { Form, Row, Col, Card, Statistic, Divider, Button, DatePicker } from 'antd';
import { GetUsersRegistrationDailyStats } from '../../../../services/users';
import moment from 'moment-timezone';
import { FilterObject } from '../../../../functions/Utilities';
import { Line, Area } from '@ant-design/charts';
import usePageLoader from '../../../../hooks/usePageLoader';

export default function TrendsRegistrations({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [dateStart, setDateStart] = useState(moment().subtract(6, 'days').tz('Asia/Manila').format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState(moment().tz('Asia/Manila').format('YYYY-MM-DD'));
    const [dailyRegistrations, setDailyRegistrations] = useState(null);
    const [totalRegistrations, setTotalRegistrations] = useState(null);
    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY/MM/DD';

    const getPageData = async() => {
        showLoader();
        setDailyRegistrations(null);
        setTotalRegistrations(null);
        Promise.all([
            GetUsersRegistrationDailyStats(dateStart, dateEnd),
        ]).then(result => {
            const loopStart = moment(dateStart);
            const loopEnd = moment(dateEnd);
            const usersDailyFormatted = [];
            const usersTotalFormatted = [];
            let count = 0;
            while (loopStart.isSameOrBefore(loopEnd, 'day')) {
                let filterResult = FilterObject(result[0].data.data, loopStart.format('YYYY-MM-DD'), 'date');
                usersDailyFormatted.push({
                    'date': loopStart.format('YYYY-MM-DD'),
                    'registration_count': filterResult.length > 0 ? filterResult[0].registration_count : 0
                });
                if (filterResult.length > 0) {
                    count += filterResult[0].registration_count;
                }
                usersTotalFormatted.push({
                    'date': loopStart.format('YYYY-MM-DD'),
                    'registration_total': count
                });
                loopStart.add(1, 'days');
            }
            setDailyRegistrations(usersDailyFormatted);
            setTotalRegistrations(usersTotalFormatted);
            hideLoader();
        });
    }

    const changeRange = (date) => {
        setDateStart(date[0].format('YYYY-MM-DD'));
        setDateEnd(date[1].format('YYYY-MM-DD'));
    }

    const handleSubmit = () => {
        getPageData();
    }

    const dailyConfig = {
        data: dailyRegistrations,
        xField: 'date',
        yField: 'registration_count',
        meta: {
            date: {
                alias: 'Date'
            },
            order_count: {
                alias: 'Customers'
            }
        },
        lineStyle: {
            stroke: '#0023c4'
        },
        point: {
            size: 6,
            shape: 'circle',
            color: '#ff5cf4'
        },
    };

    const totalConfig = {
        data: totalRegistrations,
        xField: 'date',
        yField: 'registration_total',
        meta: {
            date: {
                alias: 'Date'
            },
            registration_total: {
                alias: 'Customers'
            }
        },
        lineStyle: {
            stroke: '#0023c4'
        },
        point: {
            size: 6,
            shape: 'circle',
            color: '#ff5cf4'
        },
    };

    useEffect(() => {
        getPageData();
        return function cleanup() {
            getPageData();
        }
    }, []);

    return (
        <PageLayout activeLink='dashboard'>
            { loader }
            <h4 className="mb-4">Trends</h4>
            <div>
                <div className="d-flex w-100">
                    <h3 className="mb-4">Registrations </h3>
                    <div className="ms-auto buttons">
                        <Form layout={"inline"} className="bm-form" onFinish={handleSubmit}>
                            <Form.Item name="orders_range">
                                <RangePicker
                                    defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                                    value={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                                    onChange={(date) => changeRange(date)}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                    Change
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div>
                    {
                        dailyRegistrations &&
                        <Line {...dailyConfig} />
                    }
                    <Divider className="my-5" />
                    {
                        totalRegistrations &&
                        <Area {...totalConfig} />
                    }
                    
                </div>
            </div>
        </PageLayout>
    )
    
}
