import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetServiceAreas } from '../../../../services/serviceareas';
import usePageLoader from '../../../../hooks/usePageLoader';
import { ConvertToDecimal } from '../../../../functions/Math';
import { GetRegionLabel, GetStatusLabels } from '../../../../functions/Labels';

export default function ServiceAreasList() {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [serviceAreas, setServiceAreas] = useState(null);
    const history = useHistory();
    
    const getMerchants = async() => {
        showLoader();
        const result = await GetServiceAreas();
        setServiceAreas(result.data.data);
        hideLoader();
    }

    const columns = [
        {
            title: 'Service Area Name',
            dataIndex: 'service_area_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/service-areas/view/${row.service_area_uuid}`}>{text}</Link>,
        },
        {
            title: 'Region',
            dataIndex: 'region_name',
            render: (text, row) => GetRegionLabel(text)
        },
        {
            title: 'Code',
            dataIndex: 'service_area_code',
        },
        {
            title: 'Radius',
            render: (text, row) => ConvertToDecimal(row.service_area_radius) + ' Km',
        },
        {
            title: 'Delivery Fee (Min)',
            dataIndex: 'delivery_min_fee',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.delivery_min_fee),
        },
        {
            title: 'Delivery Radius (Min)',
            dataIndex: 'delivery_min_radius',
            align: 'right',
            render: (text, row) => ConvertToDecimal(row.delivery_min_radius) + ' Km',
        },
        {
            title: 'Excess Fee (per Km)',
            dataIndex: 'delivery_excess_per_km',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.delivery_excess_per_km),
        },
        {
            title: 'Status',
            render: (text, row) => GetStatusLabels('service-areas', row.service_area_status)
        }
    ];

    useEffect(() => {
        getMerchants();
        return function cleanup() {
            getMerchants();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='service-areas'>
            { loader }
            <div className="d-flex w-100">
                <h3 className="mb-4">Service Areas List</h3>
                <div className="ms-auto buttons">
                    <Button type="primary" className="bm-btn" onClick={() => history.push('/app/admin/service-areas/add')}>Add Service Area</Button>
                </div>
            </div>
            {
                serviceAreas &&
                <Table rowKey="service_area_uuid" dataSource={serviceAreas} columns={columns} size={'middle'} className="bm-table" />
            }
        </PageLayout>
    )
}
