import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Card, Button } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetAddOnDetails } from '../../../../services/addons';
import { ConvertToDecimal } from '../../../../functions/Math';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function AddOnsView() {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [addOnDetails, setAddOnDetails] = useState(null);
    const history = useHistory();
    const addOnGroupDetails = history.location.state.groupDetails;
    const { uuid }  = useParams();
    
    const getAddOnDetails = async() => {
        showLoader();
        const addOnDetailsResult = await GetAddOnDetails(uuid);
        setAddOnDetails(addOnDetailsResult.data.data);
        setDisplay(true);
        hideLoader();
    }

    useEffect(() => {
        getAddOnDetails();
        return function cleanup() {
            getAddOnDetails();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='add-ons'>
            { loader }
            {
                display && 
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <Card title="Stats" className="bm-card mb-4">
                            Stats soon
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card   title="Add-On Details"
                                className="bm-card mb-4 mb-md-0"
                                actions={[
                                    <div className="text-end"><Button type="outline-primary" className="bm-btn" onClick={() => history.push({pathname: '/app/merchants/add-ons/update/' + uuid, state: {addOnDetails, addOnGroupDetails}})}>Update</Button></div>
                                ]}>
                            <div className="bm-static-container">
                                <div className="title">Add-On Group Name</div>
                                <div className="display">{addOnGroupDetails.add_on_group_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Add-On Name</div>
                                <div className="display">{addOnDetails.add_on_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Description</div>
                                <div className="display">{addOnDetails.add_on_description}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Price</div>
                                <div className="display">{'P' + ConvertToDecimal(addOnDetails.add_on_price)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Bestseller</div>
                                <div className="display">{GetStatusLabels('bool', parseInt(addOnDetails.is_bestseller))}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Status</div>
                                <div className="display">{GetStatusLabels('add-ons', parseInt(addOnDetails.add_on_status))}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
