import axios from 'axios';

export const GetMerchants = async(service_area_uuid) => {
    try {
        let result;
        if (service_area_uuid === null) {
            result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/merchants');
        } else {
            result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/merchants/service-area/' + service_area_uuid);
        }
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetMerchantDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/merchants/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetMerchantBranches = async(uuid, service_area_uuid) => {
    try {
        let result;
        if (service_area_uuid === null) {
            result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/merchants/branches/' + uuid);
        } else {
            result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/merchants/service-area/' + service_area_uuid + '/branches/' + uuid);
        }
        return result;
    } catch (error) {
        throw error;
    }
}

export const MerchantBranchesAdd = async(values) =>{
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/merchant-branches', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetMerchantUsers = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/merchants/users/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateMerchant = async(values) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/merchants/' + values.merchant_uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}