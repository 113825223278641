import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Card, Button } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function AdminView() {
    const history = useHistory();
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const adminDetails = history.location.state.adminDetails;
    const { uuid }  = useParams();

    const getAdminDetails = async() => {
        showLoader();
        setDisplay(true);
        hideLoader();
    }

    useEffect(() => {
        getAdminDetails();
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='administrators'>
            { loader }
            {
                display && 
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <Card title="Stats" className="bm-card mb-4 mb-md-0">
                            Stats soon
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card title="User Details" className="bm-card mb-4 mb-md-0">
                            <div className="bm-static-container">
                                <div className="title">Service Area</div>
                                <div className="display"><h3>{adminDetails.service_area_name}</h3></div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">First Name</div>
                                <div className="display">{adminDetails.first_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Last Name</div>
                                <div className="display">{adminDetails.last_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Email Address</div>
                                <div className="display"><a href={`mailto:${adminDetails.email_address}`} className="text-link-primary-x3">{adminDetails.email_address}</a></div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Mobile Number</div>
                                <div className="display">{adminDetails.mobile_number}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">User Status</div>
                                <div className="display">{GetStatusLabels('users', parseInt(adminDetails.user_status))}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
