import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Table, Row, Col, Card, Button, Image } from 'antd';
import { Link } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetRequestDetails, GetUserRequests } from '../../../../services/requests';
import { ConvertToDecimal } from '../../../../functions/Math';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels, GetRequestTypeLabel } from '../../../../functions/Labels';

export default function RequestsView() {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [requestDetails, setRequestDetails] = useState(null);
    const [userRequests, setUserRequests] = useState(null);
    const { uuid }  = useParams();
    const history = useHistory();
    
    const getRequestDetails = async() => {
        showLoader();
        // get request details
        const requestDetailsResult = await GetRequestDetails(uuid);
        setRequestDetails(requestDetailsResult.data.data);
        
        // get latest requests list
        const userRequestsResult = await GetUserRequests(requestDetailsResult.data.data.user_uuid);
        setUserRequests(userRequestsResult.data.data);

        setDisplay(true);
        hideLoader();
    }

    const columns = [
        {
            title: 'Request Type',
            render: (text, row) => GetRequestTypeLabel(row.request_type)
        },
        {
            title: 'Request Code',
            dataIndex: 'request_code',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/requests/view/${row.request_uuid}`}>{text}</Link>
        },
        {
            title: 'Amount',
            dataIndex: 'request_amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.request_amount),
        },
        {
            title: 'Status',
            render: (text, row) => GetStatusLabels('requests', row.request_status),
        }
    ];

    useEffect(() => {
        getRequestDetails();
        return function cleanup() {
            getRequestDetails();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='requests'>
            { loader }
            {
                display && 
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <Card title="Stats" className="bm-card mb-4 mb-md-0">
                            Stats soon
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card title="Merchant Details" className="bm-card mb-4 mb-md-0"
                            actions={[
                                <div className="text-end"><Button type="outline-primary" className="bm-btn" onClick={() => history.push({pathname: '/app/admin/requests/update/' + uuid, state: {requestDetails}})}>Update</Button></div>
                            ]}>
                            <div className="bm-static-container">
                                <Image width={120} height={120} src={requestDetails.file_url} className="bm-image-rounded image-cover" />
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Requester's Name</div>
                                <div className="display"><Link className="text-link-primary-x3" to={`/app/admin/delivery-partners/view/${requestDetails.user_uuid}`}>{requestDetails.first_name} {requestDetails.last_name}</Link></div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Request Type</div>
                                <div className="display">{GetRequestTypeLabel(requestDetails.request_type)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Request Code</div>
                                <div className="display">{requestDetails.request_code}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Amount</div>
                                <div className="display">{'P' + ConvertToDecimal(requestDetails.request_amount)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Status</div>
                                <div className="display">{GetStatusLabels('requests', requestDetails.request_status)}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }

            <h3 className="mb-4">Latest 10 Requests</h3>
            {
                userRequests &&
                <Table rowKey="branch_uuid" dataSource={userRequests} columns={columns} size={'middle'} className="bm-table" />
            }
        </PageLayout>
    )
}
