import axios from 'axios';

export const GetMenus = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/menus/branch/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const AddMenus = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/menus', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetMenuDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/menus/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateMenus = async(values) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/menus/' + values.menu_uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}