import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Input, Select, Button, Alert, Upload } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { UpdateSpotlights } from '../../../../services/serviceareaspotlights';
import { GetServiceAreaBranches } from '../../../../services/branches';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';
import { UploadFiles } from '../../../../services/files';

export default function SpotlightsUpdate({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const history = useHistory();
    const { Option } = Select;
    const spotlightDetails = history.location.state.spotlightDetails;
    const [branches, setBranches] = useState(null);
    const [fileListPhoto, setFileListPhoto] = useState([]);
    const [newItemPhoto, setNewItemPhoto] = useState(null);
    const [newItemNotice, setNewItemNotice] = useState(null);

    const getServiceAreaBranches = async() => {
        showLoader();
        const resultBranches = await GetServiceAreaBranches(spotlightDetails.service_area_uuid);
        setBranches(resultBranches.data.data);
        hideLoader();
    }

    const submitHandler = async (values) => {
        showLoader();
        try {
            const formattedValues = {
                ...values,
                spotlight_uuid: spotlightDetails.spotlight_uuid,
                spotlight_status: parseInt(values.spotlight_status)
            }
            if (newItemPhoto !== null) {
                formattedValues.spotlight_photo_file_uuid = newItemPhoto
            }
            const result = await UpdateSpotlights(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Feature has been updated.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    const uploadFile = async(file) => {
        try {
            const formattedValues = {
                file_url: file.secure_url,
                file_type: file.resource_type,
                file_name: file.asset_id,
                file_description: '',
                file_status: 1
            }
            const result = await UploadFiles(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Banner photo is now uploaded.', 'Success', 2000), 2000);
                    const bannerItem = {
                        uid: file.asset_id,
                        name: file.asset_id,
                        url: file.secure_url
                    }
                    setFileListPhoto([bannerItem]);
                    setNewItemPhoto(result.data.file_uuid);
                    setNewItemNotice(true);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleOnChangePhoto = async({file, fileList: newFileList }) => {
        setFileListPhoto(newFileList);
        showLoader();
        if (file.status !== 'uploading') {
            await getBase64(file.originFileObj, result => {
                let formData = new FormData();
                formData.append('file', result);
                formData.append('upload_preset', 'nspmba8t');
                axios.post('https://api.cloudinary.com/v1_1/falcomtech/image/upload', formData)
                    .then(response => {
                        if (response.status === 200) {
                            uploadFile(response.data);
                            hideLoader();
                        }
                    })
            });
        }
    }

    useEffect(() => {
        const photoItem = {
            uid: spotlightDetails.file_uuid,
            name: spotlightDetails.file_name,
            url: spotlightDetails.file_url
        }
        setFileListPhoto([photoItem]);
        getServiceAreaBranches();
        return function cleanup() {
            getServiceAreaBranches();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='items'>
            { loader }
            {
                <Row>
                    <Col xs={24} md={{span: 12, offset: 6}}>
                        <Card title="Update Spotlight" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Spotlight Updated"
                                    description="Spotlight has been updated successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.goBack()}>Back</Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}
                                initialValues={{
                                    'branch_uuid': spotlightDetails.branch_uuid,
                                    'spotlight_title': spotlightDetails.spotlight_title,
                                    'spotlight_alt_title': spotlightDetails.spotlight_alt_title,
                                    'spotlight_status': String(spotlightDetails.spotlight_status)
                                }}>
                                <Form.Item label="Branch"
                                    name="branch_uuid"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please please select branch.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select">
                                        <option value="">select one</option>
                                        {
                                            branches && branches.map(item => {
                                                return <Option value={item.branch_uuid}>{item.branch_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Spotlight Title"
                                    name="spotlight_title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the spotlight title.',
                                        },
                                    ]}>
                                    <Input placeholder="spotlight title" />
                                </Form.Item>
                                <Form.Item label="Spotlight Alt Title"
                                    name="spotlight_alt_title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the spotlight alt title.',
                                        },
                                    ]}>
                                    <Input placeholder="spotlight alt title" />
                                </Form.Item>
                                <Form.Item label="Item Photo"
                                    name="feature_photo">
                                    <ImgCrop modalTitle="Crop Image" aspect={0.75} quality={.8}>
                                        <Upload name="image"
                                            listType="picture-card"
                                            accept="image/png, image/jpeg"
                                            fileList={fileListPhoto}
                                            beforeUpload={false}
                                            onChange={handleOnChangePhoto}
                                            onRemove={false}>
                                            {fileListPhoto.length < 2 && 'Replace'}
                                        </Upload>
                                    </ImgCrop>
                                    <Input className="d-none" />
                                    {
                                        newItemNotice &&
                                        <Alert
                                            className="bm-alert mt-2"
                                            message="Notice"
                                            description="Feature photo has not been saved to the database yet. You need to submit the form in order to save the image."
                                            type="default"
                                        />
                                    }
                                </Form.Item>
                                <Form.Item label="Status"
                                    name="spotlight_status"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select status.',
                                        },
                                    ]}>
                                    <Select>
                                        <Option value="1">Active</Option>
                                        <Option value="0">Inactive</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
