import axios from 'axios';

export const AddWalletTransaction = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/wallets', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetUserWallets = async(uuid, limits) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/wallets/user/' + uuid + '/limit/' + limits);
        return result;
    } catch (error) {
        throw error;
    }
}

// export const GetActiveRequests = async() => {
//     try {
//         const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/requests/active');
//         return result;
//     } catch (error) {
//         throw error;
//     }
// }


// export const GetRequestDetails = async(uuid) => {
//     try {
//         const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/requests/' + uuid);
//         return result;
//     } catch (error) {
//         throw error;
//     }
// }

// export const GetUserRequests = async(uuid) => {
//     try {
//         const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/requests/user/' + uuid);
//         return result;
//     } catch (error) {
//         throw error;
//     }
// }

// export const UpdateRequest = async(values, uuid) => {
//     try {
//         const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/requests/' + uuid, values);
//         return result;
//     } catch (error) {
//         throw error;
//     }
// }

// export const MerchantBranchesAdd = async(values) =>{
//     try {
//         const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/merchant-branches', values);
//         return result;
//     } catch (error) {
//         throw error;
//     }
// }

// export const GetMerchantUsers = async(uuid) => {
//     try {
//         const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/merchants/users/' + uuid);
//         return result;
//     } catch (error) {
//         throw error;
//     }
// }

// export const UpdateMerchant = async(values) => {
//     try {
//         const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/merchants/' + values.merchant_uuid, values);
//         return result;
//     } catch (error) {
//         throw error;
//     }
// }