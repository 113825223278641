import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Row, Col, Card, Button, Table } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import Checked from '../../../../components/icons/Checked';
import { GetAddOnGroupDetails } from '../../../../services/addongroups';
import { GetAddOns } from '../../../../services/addons';
import { ConvertToDecimal } from '../../../../functions/Math';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function GroupsView() {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [addOnGroupDetails, setAddOnGroupDetails] = useState(null);
    const [addOns, setAddOns] = useState(null);
    const history = useHistory();
    const { uuid }  = useParams();
    
    const getAddOnGroupDetails = async() => {
        showLoader();
        const addOnGroupDetailsResult = await GetAddOnGroupDetails(uuid);
        const addOnsResult = await GetAddOns(uuid);
        setAddOnGroupDetails(addOnGroupDetailsResult.data.data);
        setAddOns(addOnsResult.data.data);
        setDisplay(true);
        hideLoader();
    }

    const addOnColumns = [
        {
            title: 'Add-On Name',
            dataIndex: 'add_on_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={{pathname: `/app/merchants/add-ons/view/${row.add_on_uuid}`, state: {groupDetails: addOnGroupDetails}}} title="view variation details"><span className="d-flex align-items-center bestseller-icon-wrapper">{text} {(row.is_bestseller === 1) ? <Checked /> : ''}</span></Link>,
        },
        {
            title: 'Price',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.add_on_price),
        },
        {
            title: 'Bestseller',
            dataIndex: 'is_bestseller'
        },
        {
            title: 'Status',
            width: 110,
            dataIndex: 'add_on_status',
            render: text => GetStatusLabels('add-ons', parseInt(text))
        }
    ]

    useEffect(() => {
        getAddOnGroupDetails();
        return function cleanup() {
            getAddOnGroupDetails();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='add-ons'>
            { loader }
            {
                display && 
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <Card title="Stats" className="bm-card mb-4">
                            Stats soon
                        </Card>
                        <div>
                            <div className="d-flex w-100">
                                <h3 className="mb-4">Add-Ons</h3>
                                <div className="ms-auto buttons">
                                    <Button type="primary" className="bm-btn" onClick={() => history.push({pathname: '/app/merchants/add-ons/add/' + uuid, state: {group: addOnGroupDetails}})}>Add Add-Ons</Button>
                                </div>
                            </div>
                            <Table rowKey="menu_uuid" dataSource={addOns} columns={addOnColumns} size={'middle'} className="bm-table" />
                        </div>
                    </Col>
                    <Col xs={24} md={8}>
                            <Card   title="Add-On Group Details"
                                className="bm-card mb-4 mb-md-0"
                                actions={[
                                    <div className="text-end"><Button type="outline-primary" className="bm-btn" onClick={() => history.push({pathname: '/app/merchants/add-ons/groups/update/' + uuid, state: {addOnGroupDetails}})}>Update</Button></div>
                                ]}>
                            <div className="bm-static-container">
                                <div className="title">Group Name</div>
                                <div className="display">{addOnGroupDetails.add_on_group_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Required</div>
                                <div className="display">{GetStatusLabels('bool', addOnGroupDetails.is_required)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Multiple</div>
                                <div className="display">{GetStatusLabels('bool', addOnGroupDetails.is_multiple)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Status</div>
                                <div className="display">{GetStatusLabels('add-on-groups', parseInt(addOnGroupDetails.add_on_group_status))}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
