import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMerchantUsers } from '../../../../services/merchants';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function UsersList({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [merchantUsers, setMerchantUsers] = useState(null);
    const history = useHistory();
    
    const getMerchantUsers = async() => {
        showLoader();
        const result = await GetMerchantUsers(currentUser.other_details.merchant_uuid);
        setMerchantUsers(result.data.data);
        hideLoader();
    }

    const columns = [
        {
            title: 'Merchant Partner Name',
            dataIndex: 'first_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/merchants/users/view/${row.user_uuid}`}>{row.first_name} {row.last_name}</Link>,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'user_mobile_number',
        },
        {
            title: 'Email',
            dataIndex: 'user_email_address',
            render: text => <a className="text-link-primary-x3" href={`mailto:${text}`}>{text}</a>,
        },
        {
            title: 'Access Type',
            dataIndex: 'access_type_code',
            render: text => GetStatusLabels('access-types', text),
        },
        {
            title: 'Status',
            width: 110,
            dataIndex: 'user_status',
            render: text => GetStatusLabels('users', parseInt(text)),
        }
    ];

    useEffect(() => {
        getMerchantUsers();
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='users'>
            { loader }
            <div className="d-flex w-100">
                <h3 className="mb-4">Users List</h3>
                <div className="ms-auto buttons">
                    <Button type="primary" className="bm-btn" onClick={() => history.push('/app/merchants/users/add')}>Add Users</Button>
                </div>
            </div>
            {
                merchantUsers &&
                <Table rowKey="user_uuid" dataSource={merchantUsers} columns={columns} size={'middle'} className="bm-table" />
            }
        </PageLayout>
    )
}
