import React, { useEffect, useState } from 'react';
import { Table, Input } from 'antd';
import { Link } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMerchants } from '../../../../services/merchants';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';
import { ConvertToDecimal } from '../../../../functions/Math';

export default function MerchantsList({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [merchants, setMerchants] = useState(null);
    const [filterMerchants, setFilterMerchants] = useState(null);
    const { Search } = Input;
    
    const getMerchants = async() => {
        showLoader();
        const result = await GetMerchants(currentUser.other_details.service_area_uuid);
        setMerchants(result.data.data);
        hideLoader();
    }

    const filterTable = (value) => {
        const tempData = merchants.filter(o =>
            Object.keys(o).some(k =>
              String(o[k])
                .toLowerCase()
                .includes(value.toLowerCase())
            )
        );
        setFilterMerchants(tempData);
    }

    const columns = [
        {
            title: 'Service Area',
            render: (text, row) => row.service_area_name,
        },
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/merchants/view/${row.merchant_uuid}`}>{text}</Link>,
        },
        {
            title: 'Merchant Name',
            dataIndex: 'merchant_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/merchants/view/${row.merchant_uuid}`}>{text}</Link>,
        },
        {
            title: 'Commission Rate',
            dataIndex: 'commission_rate',
            render: text => text + '%'
        },
        {
            title: 'Price Rate',
            dataIndex: 'price_rate',
            render: text => text + '%'
        },
        {
            title: 'Free Delivery',
            render: (text, row) => GetStatusLabels('bool', row.is_free_delivery)
        },
        {
            title: 'Min for Free Delivery',
            render: (text, row) => 'P' + ConvertToDecimal(row.min_free_delivery)
        },
        {
            title: 'Status',
            render: (text, row) => GetStatusLabels('merchants', row.merchant_status)
        }
    ];

    useEffect(() => {
        getMerchants();
        return function cleanup() {
            getMerchants();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='merchants'>
            { loader }
            <h3 className="mb-4">Merchants List</h3>
            {
                merchants &&
                <div>
                    <div className="d-flex justify-content-end mb-3">
                        <Search className="bm-table-search" placeholder="search table" onSearch={filterTable} style={{ width: 200 }} />
                    </div>
                    <Table rowKey="merchant_uuid" dataSource={filterMerchants === null ? merchants : filterMerchants} columns={columns} size={'middle'} className="bm-table" />
                </div>
            }
        </PageLayout>
    )
}
